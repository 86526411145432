import React from "react";
import AccomType from "./AccomType";
import WithDisabled from "./../../../hoc/WithDisabled";
import ExpandLess from "material-ui/svg-icons/navigation/expand-less";
import ExpandMore from "material-ui/svg-icons/navigation/expand-more";

const styles = {
  box: {
    width: "18",
    height: "18",
    cursor: "pointer",
  },
};
const AccomTypeWithDisabled = WithDisabled(AccomType);
export default class Property extends React.Component {
  state = {
    open: true,
  };

  toggleOpen = (e) => {
    e.preventDefault();

    this.setState(({ open }) => ({
      open: !open,
    }));
  };

  render() {
    const { accomTypes, name } = this.props;
    return (
      <div>
        <div className="camp-bar">
          <div className="camp-name">{name}</div>
          {this.state.open ? (
            <ExpandLess style={styles.box} onClick={this.toggleOpen} />
          ) : (
            <ExpandMore style={styles.box} onClick={this.toggleOpen} />
          )}
        </div>

        <div>
          {Object.values(accomTypes).map((accomType) => (
            <AccomTypeWithDisabled
              isDisabled={!this.state.open}
              key={accomType.id}
              propertyName={this.props.name}
              propertyId={this.props.id}
              propertyPolicy={this.props.policy}
              {...accomType}
            />
          ))}
        </div>
      </div>
    );
  }
}
