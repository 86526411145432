import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import IconButton from "material-ui/IconButton";
import ContentClear from "material-ui/svg-icons/content/clear";

const modalStyle = {
  content: {
    outline: "none",
    backgroundColor: "white",
    width: "40rem",
    height: "20rem",
    border: "0.1rem solid #888888",
    boxShadow: "0.5rem 1rem 1rem #888888",
    margin: "auto",
    textAlign: "center",
  },
};

Modal.setAppElement("#app");
class WarningModal extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      holdValue: null,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal = () => {
    this.setState({ showModal: false, holdValue: false });
  };

  render() {
    const { warningTog } = this.props;

    if (this.state.holdValue == false) {
      this.state.holdValue = null;
    } else if (this.state.holdValue == null && warningTog == true) {
      this.state.holdValue = true;
      this.state.showModal = true;
    } else if (this.state.holdValue == null && warningTog == false) {
      this.state.holdValue = null;
      this.state.showModal = false;
    }

    return (
      <div>
        <Modal
          isOpen={this.state.showModal}
          style={modalStyle}
          ariaHideApp={true}
          onRequestClose={this.handleCloseModal}
        >
          <IconButton styles={{ width: 2 }} onClick={this.handleCloseModal}>
            <ContentClear />
          </IconButton>

          <p>
            You do not have access to make this booking. Bookings across
            different camps are not permitted. Booking for separate Chalets must
            have corresponding arrival and departure dates. Booking for same
            Chalets booking must have consecutive nights.
          </p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ booking }) => {
  const stagedLineItems = booking.lineItems.filter(
    (lineItem) => lineItem.confirmed !== true
  );
  const stagedLineItemsWithWarnings = stagedLineItems.map((lineItem) => {
    const { rateGroups } = lineItem;
    const totalGuests = rateGroups
      .map(({ number }) => number)
      .reduce((a, b) => parseInt(a) + parseInt(b));

    const isOverCapacity = totalGuests > lineItem.max_capacity;
    const noSelection = totalGuests == 0;

    return { ...lineItem, isOverCapacity, noSelection };
  });

  const badGuestSelection = stagedLineItemsWithWarnings.some(
    ({ isOverCapacity, noSelection }) => {
      return isOverCapacity || noSelection;
    }
  );
  return {
    lodge: booking.lodge,
    stagedLineItemsWithWarnings,
    badGuestSelection,
  };
};

export default connect(mapStateToProps)(WarningModal);
