const validateChangePasswords = (payload) => {
  let isFormValid = true;
  let message = "";

  if (!payload || payload.pass1 !== payload.pass2) {
    isFormValid = false;
    message = "Passwords must be the same";
  }
  if (
    !payload ||
    typeof payload.pass1 !== "string" ||
    payload.pass1.trim().length < 6
  ) {
    isFormValid = false;
    message = "Password must have at least 6 characters.";
  }

  return {
    success: isFormValid,
    message,
  };
};

export default validateChangePasswords;
