import React from "react";
import { connect } from "react-redux";
import { CardTitle } from "material-ui/Card";
import { removeAgentLinked } from "./../../../actions/agentManagement";
import IconButton from "material-ui/IconButton";
import Back from "material-ui/svg-icons/hardware/keyboard-backspace";
import EmailInput from "./EmailInput";

const EmailTemplate = ({ isVisible, goBack }) => {
  return isVisible ? (
    <div className="booking-card">
      <CardTitle title={"4. Send Activation Email"}> </CardTitle>
      <IconButton tooltip="Go back a step" onClick={() => goBack()}>
        <Back />
      </IconButton>
      <EmailInput />
    </div>
  ) : (
    <div />
  );
};

const mapStateToProps = ({ agentManagement }) => {
  const { updatedUser, agents, consultants } = agentManagement;
  return {
    isVisible:
      agents &&
      agents.filter((agent) => agent.selected).length > 0 &&
      consultants &&
      consultants.filter((consultant) => consultant.selected).length > 0 &&
      updatedUser !== undefined,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () => dispatch(removeAgentLinked()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
