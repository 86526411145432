import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import fetch from "node-fetch";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  image: {},
  img: {
    margin: "auto",
    maxWidth: "100%",
  },
  newsletterBox: {
    marginTop: "20px",
  },
  inputBox: {
    marginTop: "0 !important",
  },
});

class NewsletterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      hasSubmited: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    // Post off to Sharpspring here
    var url =
      "https://app-3QNFJS5T58.marketingautomation.services/webforms/receivePostback/MzawMDGzNDMxAQA/1d860bd9-89de-474e-a142-efc5f9334a0a/jsonp/?";
    url =
      url +
      "FirstName=" +
      this.state.first_name +
      "&LastName=" +
      this.state.last_name +
      "&Email=" +
      this.state.email;
    return fetch(url, {
      mode: "no-cors",
      method: "GET",
    })
      .then((response) => {
        this.setState({ hasSubmitted: true });
        alert(
          "Success, you've been signed up for our Trade Newsletter, please check your email for more information!"
        );
      })
      .catch((error) => {
        alert(
          "Could not sign you up, please check your details and try submitting again. Error received is:" +
            error
        );
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} id="newsLetterForm" className={classes.newsletterBox}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom className={"fontOverride"}>
            Subscribe to our Trade Newsletter
          </Typography>
          {this.state.hasSubmited == false && (
            <form onSubmit={this.handleSubmit}>
              <div className="field-line">
                <TextField
                  required
                  className={classes.inputBox}
                  floatingLabelText="First Name"
                  name="first_name"
                  type="text"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                />
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <TextField
                  required
                  className={classes.inputBox}
                  floatingLabelText="Last Name"
                  name="last_name"
                  type="text"
                  value={this.state.last_name}
                  onChange={this.handleChange}
                />
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <TextField
                  required
                  className={classes.inputBox}
                  floatingLabelText="Email"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                <RaisedButton
                  className="button-login"
                  type="submit"
                  label="Subscribe"
                  primary
                />
              </div>
            </form>
          )}
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(NewsletterForm);
//export default (NewsletterForm);
