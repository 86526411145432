import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardText } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import EyeButton from "material-ui/svg-icons/image/remove-red-eye";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";

const ForgotForm = ({
  onSubmit,
  onChange,
  passwords,
  message,
  header,
  isPassVisible,
  isSendDisabled,
  toggle,
}) => (
  <Card className="authentication-card">
    <form action="/" onSubmit={onSubmit}>
      <h2 className="authentication-card__heading">{header}</h2>
      {message && <p className="error-message">{message}</p>}

      <div className="field-line">
        <TextField
          floatingLabelText="New Password"
          name="pass1"
          type={isPassVisible ? "text" : "password"}
          onChange={onChange}
          value={passwords.pass1}
        />
        <TextField
          floatingLabelText="Confirm Password"
          name="pass2"
          type={isPassVisible ? "text" : "password"}
          onChange={onChange}
          value={passwords.pass2}
        />
        <IconButton tooltip="Toggle visibility" onClick={() => toggle()}>
          <EyeButton />
        </IconButton>
      </div>

      <div className="button-line">
        <RaisedButton
          className="button-login"
          type="submit"
          label="Change Password"
          primary
          disabled={isSendDisabled}
        />
      </div>
    </form>
  </Card>
);

ForgotForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  passwords: PropTypes.object.isRequired,
};

export default ForgotForm;
