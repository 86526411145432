import React from "react";
import { Card, CardTitle } from "material-ui/Card";

const NoAccessWarning = () => {
  let link = (
    <a href={"mailto:reservations@londolozi.co.za"}>
      reservations@londolozi.co.za
    </a>
  );

  return (
    <Card className="booking-card__warning">
      <CardTitle
        title={
          <div>
            It looks like you do not have access to any lodge portals. Please
            follow the existing account link provided in the Londolozi
            Reservation invitation email to gain access to the lodge portal, or
            contact Londolozi administration at {link}!
          </div>
        }
      />
    </Card>
  );
};

export default NoAccessWarning;
