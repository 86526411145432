import React from "react";

import { connect } from "react-redux";
import Property from "./Property";

const Properties = ({ properties }) => {
  return properties ? (
    <div>
      {Object.values(properties).map((property) => (
        <Property className="camps" key={property.id} {...property} />
      ))}
    </div>
  ) : (
    <div />
  );
};
const mapStateToProps = ({ properties }) => {
  return { properties: properties.properties };
};

export default connect(mapStateToProps)(Properties);
