// Takes in this.props.name which is the name of the NotesType
import React from "react";

const NotesSection = ({
  name,
  text,
  myState,
  updateTextField,
  placeholder,
}) => (
  <div>
    <h4>{name}:</h4>
    <textarea
      className="notes-textarea"
      placeholder={placeholder}
      value={text}
      onChange={updateTextField(myState)}
      rows="4"
    />
  </div>
);

export default NotesSection;
