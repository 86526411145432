import moment from "moment";

export default (arrival, departure, availability) => {
  const format = "YYYY-MM-DD";
  const nights = moment(departure, format).diff(
    moment(arrival, format),
    "days"
  );
  let selectionAvailability = [];
  for (let i = 0; i < nights; i++) {
    selectionAvailability.push(
      availability[moment(arrival, format).add(i, "days").format(format)]
    );
  }
  return selectionAvailability;
};
