import React from "react";
import { connect } from "react-redux";
import RaisedButton from "material-ui/RaisedButton";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import ArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import WithRoleCheck from "./../../../hoc/WithRoleCheck";
import HighlightTypeDropDown from "./HighlightTypeDropDown";

import { moveCalendar } from "./../../../actions/bookingFilters";
import { VictoryLegend } from "victory";

const BookingCalendarHeader = ({ moveCalendar }) => (
  <div className="calendar__header">
    <RaisedButton
      className="btn-movedays"
      icon={<ArrowBack />}
      onClick={() => moveCalendar(7, "left")}
      style={{ maxHeight: 38 }}
    />
    <HighlightTypeDropDownWithRoleCheck role="lodgeAdmin" />
    <RaisedButton
      className="btn-movedays"
      icon={<ArrowForward />}
      onClick={() => moveCalendar(7, "right")}
      style={{ maxHeight: 38 }}
    />
  </div>
);

const HighlightTypeDropDownWithRoleCheck = WithRoleCheck(HighlightTypeDropDown);

const mapDispatchToProps = (dispatch) => ({
  moveCalendar: (numberOfDays, direction) =>
    dispatch(moveCalendar(numberOfDays, direction)),
});
export default connect(undefined, mapDispatchToProps)(BookingCalendarHeader);
