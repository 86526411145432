import React from "react";
import { connect } from "react-redux";
import { Card, CardTitle, CardText } from "material-ui/Card";
import Match from "./Match";
import getWizardResults from "./../../../functions/getWizardResults";
import { setSearchState } from "./../../../actions/bookingFilters";

let val = false;
let storagevalues = {};
let counter = 0;
let firstentry = true;

const Wizard = ({
  exactMatches,
  closeMatches,
  areDatesValid,
  isSearch,
  setSearchState,
  numberChildren,
  numberAdults,
  numberGuests,
  rooms,
}) => {
  if (isSearch == true) {
    storagevalues = { exactMatches, closeMatches, areDatesValid };
    counter++;
    setSearchState(false);
  } else {
    if (firstentry == true) {
      firstentry = false;
      counter = 0;
    } else if (counter < 2) {
      setSearchState(true);
    } else {
      counter = 0;
    }
  }

  return (
    <Card className="booking-card">
      {storagevalues.areDatesValid && (
        <div>
          <CardTitle
            title="RESULTS"
            className="booking-page-title"
            subtitle={`Exact dates (${storagevalues.exactMatches.length})`}
          />
          {storagevalues.exactMatches.length > 0 ? (
            <div className="matching-list">
              {storagevalues.exactMatches.map((exactMatch, i) => (
                <Match
                  key={i}
                  match={exactMatch}
                  showDates={true}
                  className={"exact-card"}
                  searchParams={{
                    numberChildren,
                    numberAdults,
                    numberGuests,
                    rooms,
                  }}
                />
              ))}
            </div>
          ) : (
            <CardText>Sorry no exact matches...</CardText>
          )}
          {closeMatches.length > 0 && (
            <div>
              <CardTitle
                title="CLOSE MATCHES"
                className="booking-page-title"
                subtitle={`Close dates (${storagevalues.closeMatches.length})`}
              />
              <div className="matching-list">
                {storagevalues.closeMatches.map((closeMatch, i) => (
                  <Match
                    key={i}
                    match={closeMatch}
                    showDates={true}
                    searchParams={{
                      numberChildren,
                      numberAdults,
                      numberGuests,
                      rooms,
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

const mapStateToProps = ({ properties, bookingFilters }) => {
  const { searchedDates } = bookingFilters;
  const wizardResults =
    properties.properties === undefined
      ? []
      : getWizardResults({
          properties: properties.properties,
          startDate: searchedDates.startDate,
          endDate: searchedDates.endDate,
          rooms: bookingFilters.numberOfRooms,
          isChildren: bookingFilters.isChildren,
          isGroup1: bookingFilters.isGroup1,
          isGroup2: bookingFilters.isGroup2,
          isGroup3: bookingFilters.isGroup3,
          isGroup4: bookingFilters.isGroup4,
          flexiDays: 2,
          numberChildren: bookingFilters.numberChildren,
          numberAdults: bookingFilters.numberAdults,
          numberGuests: bookingFilters.numberGuests,
        });
  return {
    exactMatches: wizardResults.exactMatches,
    closeMatches: wizardResults.closeMatches,
    areDatesValid:
      bookingFilters.startDate !== null && bookingFilters.endDate !== null,
    isSearch: bookingFilters.isSearch,
    rooms: bookingFilters.numberOfRooms,
    numberChildren: bookingFilters.numberChildren,
    numberAdults: bookingFilters.numberAdults,
    numberGuests: bookingFilters.numberGuests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchState: (val) => dispatch(setSearchState(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
