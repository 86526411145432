const defaultAgentManagement = {
  agents: undefined,
  searchWord: "",
  first_name: "",
  last_name: "",
  email: "",
  consultants: undefined,
  accessToken: undefined,
  agentsWithAccess: undefined,
};

export default (state = defaultAgentManagement, action) => {
  switch (action.type) {
    case "SET_AGENTS":
      return {
        ...state,
        agents: action.agents.map((agent) => {
          return {
            ...agent,
            selected: false,
          };
        }),
        searchWord: action.searchWord,
        agentsLoading: false,
      };
    case "SET_CONSULTANTS":
      return {
        ...state,
        consultants: action.consultants.map((consultant) => {
          return {
            ...consultant,
            selected: false,
          };
        }),
        first_name: action.first_name,
        last_name: action.last_name,
        email: action.email,
        consultantsLoading: false,
      };
    case "SELECT_AGENT":
      return {
        ...state,
        agents: state.agents.map((agent, index) => {
          if (index === action.rowIndex) {
            return { ...agent, selected: true };
          } else {
            return { ...agent, selected: false };
          }
        }),
      };
    case "SELECT_CONSULTANT":
      return {
        ...state,
        consultants: state.consultants.map((consultant, index) => {
          if (index === action.rowIndex) {
            return { ...consultant, selected: true };
          } else {
            return { ...consultant, selected: false };
          }
        }),
      };
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.token,
      };
    case "REMOVE_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: undefined,
      };
    case "SET_AGENT_LINKED":
      console.log(action);
      return {
        ...state,
        updatedUser: action.updatedUser,
      };
    case "REMOVE_AGENT_LINKED":
      return {
        ...state,
        updatedUser: undefined,
      };
    case "START_LOADING": {
      switch (action.thingThatsLoading) {
        case "agents":
          return {
            ...state,
            agentsLoading: true,
          };
        case "consultants":
          return {
            ...state,
            consultantsLoading: true,
          };
        default:
          return state;
      }
    }
    case "REMOVE_AGENTS":
      return {
        ...state,
        agents: [],
      };

    case "REMOVE_CONSULTANTS":
      return {
        ...state,
        consultants: [],
      };
    case "SET_AGENTS_WITH_ACCESS":
      return {
        ...state,
        agentsWithAccess: action.agents,
      };
    case "REMOVE_AGENT_WITH_ACCESS":
      return {
        ...state,
        agentsWithAccess: state.agentsWithAccess.filter(
          (agent) => agent.id !== action.id
        ),
      };
    case "SELECT_AGENT_WITH_ACCESS":
      return {
        ...state,
        agentsWithAccess: state.agentsWithAccess.map((agent) => {
          if (agent.id === action.id) {
            return {
              ...agent,
              selected: true,
            };
          } else {
            return agent;
          }
        }),
      };

    case "DESELECT_AGENT_WITH_ACCESS":
      return {
        ...state,
        agentsWithAccess: state.agentsWithAccess.map((agent) => {
          if (agent.id === action.id) {
            return {
              ...agent,
              selected: false,
            };
          } else {
            return agent;
          }
        }),
      };
    case "RESET_STATE":
      return defaultAgentManagement;
    default:
      return state;
  }
};
