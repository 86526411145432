//Creates an array of dates including and between the start and end date.
import moment from "moment";

const format = "YYYY-MM-DD";

export const createDateArray = (startDate, endDate) => {
  startDate = moment(startDate, format);
  endDate = moment(endDate, format);
  let dateArray = [];
  const arrayLength = endDate.diff(startDate, "days") + 1;

  for (let i = 0; i < arrayLength; i++) {
    dateArray.push(startDate.clone().add(i, "days").format(format));
  }

  return dateArray;
};

export const createMomentDateArray = (startDate, endDate) => {
  const dateArray = createDateArray(startDate, endDate);
  return dateArray.map((date) => moment(date, format));
};
export const createMonthArray = (datesArray) => {
  const datesArrayMonthFormat = datesArray.map((date) => {
    return moment(date, format).format("MMMM YYYY");
  });

  let months = [];
  let prevMonth = datesArrayMonthFormat[0];
  let count = 0;
  for (let i = 1; i <= datesArrayMonthFormat.length; i++) {
    const month = datesArrayMonthFormat[i - 1];
    if (month === prevMonth) {
      count++;
    } else {
      months.push({
        display: prevMonth,
        colSpan: count,
      });
      count = 1;
    }
    prevMonth = month;
    if (i === datesArrayMonthFormat.length) {
      months.push({
        display: month,
        colSpan: count,
      });
    }
  }

  return months;
};

export const getDatesStayed = (arrival, departure) => {
  arrival = moment(arrival, format);
  departure = moment(departure, format);
  const nights = departure.diff(arrival, "days");
  let datesStayed = [];
  datesStayed.push(arrival.format(format));
  for (let i = 1; i < nights; i++) {
    datesStayed.push(arrival.add(1, "days").format(format));
  }
  return datesStayed;
};

export const getArrivalAndDeparture = (selectedDatesArray) => {
  const arrival = selectedDatesArray[0];
  const departure = moment(
    selectedDatesArray[selectedDatesArray.length - 1],
    format
  )
    .add(1, "days")
    .format(format);
  return [arrival, departure];
};

export const getNightsStayed = (arrival, departure) => {
  arrival = moment(arrival, format);
  departure = moment(departure, format);
  return departure.diff(arrival, "days");
};
