import React from "react";
import { Card, CardTitle, CardHeader } from "material-ui/Card";
import { connect } from "react-redux";

const styles = {
  policy: {
    whiteSpace: "pre-wrap",
  },
  propertyName: {
    fontSize: "20px",
  },
};
class Policies extends React.Component {
  render() {
    const { policies } = this.props;
    return policies ? (
      <Card className="booking-card">
        <CardHeader
          className="booking-card-policy"
          title="Policies"
          subtitle="Please click to read all property policies before booking"
          actAsExpander={true}
          showExpandableButton={true}
        />
        {policies.map(({ propertyName, policy }, i) => {
          return (
            <div key={i} style={styles.propertyPolicy} expandable={true}>
              <CardTitle
                className="policy-subheading"
                title={propertyName}
                titleStyle={styles.propertyName}
              >
                <div style={styles.policy} className="policy-content">
                  {policy}
                </div>
              </CardTitle>
            </div>
          );
        })}
      </Card>
    ) : null;
  }
}

const mapStateToProps = ({ properties }) => {
  const policies =
    Object.values(properties.properties).length > 0
      ? Object.values(properties.properties).map((property) => {
          return {
            propertyName: property.name,
            policy: property.policy ? property.policy : "No policy stated.",
          };
        })
      : null;
  return {
    policies,
  };
};
export default connect(mapStateToProps)(Policies);
