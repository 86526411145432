import React from "react";
import { Card, CardText } from "material-ui/Card";
import Toggle from "material-ui/Toggle";

const OptionTemplate = ({
  details,
  title,
  isVisible,
  handleToggle,
  children,
  fontSize,
}) => {
  const styles = {
    cardStyle: {
      padding: 10,
    },
    labelStyle: {
      fontSize,
    },
    labelStyle1: {
      backgroundColor: "#3798fb",
    },
    labelStyle2: {
      backgroundColor: "rgba(55,152,251,0.5)",
    },
  };

  switch (details.type) {
    case "property":
      styles.cardStyle.marginBottom = 20;
      // styles.cardStyle.backgroundColor = "grey";
      // styles.cardStyle.boxShadow = "none";
      break;
    case "accomType":
      styles.cardStyle.marginBottom = 20;
      styles.cardStyle.backgroundColor = "#fcfcfc";
      styles.cardStyle.border = "1px solid gainsboro";
      styles.cardStyle.boxShadow = "none";
      break;

    default:
      break;
  }

  return (
    <Card expanded={isVisible} style={styles.cardStyle}>
      <Toggle
        className="toggle-lodgeoptions"
        elementStyle={{ color: "purple", backgroundColor: "grey" }}
        label={title}
        labelStyle={styles.labelStyle}
        toggled={isVisible}
        onToggle={handleToggle(details)}
        thumbSwitchedStyle={styles.labelStyle1}
        trackSwitchedStyle={styles.labelStyle2}
      />

      <CardText expandable={true}>{children}</CardText>
    </Card>
  );
};

export default OptionTemplate;
