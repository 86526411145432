import React from "react";
import { connect } from "react-redux";

import BookingSearchBar from "./BookingSearchBar/index";
import BookingCalendar from "./BookingCalendar/index";
import Policies from "./Policies/index";
import BookingModal from "./BookingModal/index";
import WarningModal from "./WarningModal/index";
import Itinerary from "./Itinerary/index";
import Wizard from "./Wizard/index";
import WithDisabled from "./../../hoc/WithDisabled";
import ConfirmationModal from "./../../shared-components/ConfirmationModal/index";
import LodgeForceAPI from "../../wrappers/LodgeForceAPI";

const BookingPage = ({
  isCalendarDisabled,
  isItineraryDisabled,
  isWizardDisabled,
  isConfirmationOpen,
  isConfirmationLoading,
  isConfirmationProcessed,
  isConfirmationError,
}) => {
  return (
    <div>
      <div style={{ height: "110px" }}></div>
      <div id="view-make-booking">
        <BookingSearchBar />
        <WizardWithDisabled isDisabled={isWizardDisabled} />
        <Policies />
        <BookingCalendarWithDisabled isDisabled={isCalendarDisabled} />
        <ItineraryWithDisabled isDisabled={isItineraryDisabled} />
        <BookingModal />
        <WarningModal />
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          isLoading={isConfirmationLoading}
          loadingText="Please wait while booking is pending..."
          isProcessed={isConfirmationProcessed}
          processedText="Provisional booking has been successful. An email has been sent to you."
          isError={isConfirmationError}
          errorText="There was an error with the booking. This may be because there is no longer availability for the dates selected."
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ booking, properties, bookingFilters }) => {
  const { bookingStatus } = booking;
  return {
    isCalendarDisabled:
      Object.keys(properties.properties).length === 0 ||
      !bookingFilters.isCalendar,
    isWizardDisabled:
      Object.keys(properties.properties).length === 0 ||
      !bookingFilters.isWizard,
    isItineraryDisabled:
      booking.lineItems.filter((lineItem) => !!lineItem.confirmed).length === 0,
    isConfirmationOpen: bookingStatus !== null,
    isConfirmationLoading: bookingStatus === "pending",
    isConfirmationProcessed: bookingStatus === "success",
    isConfirmationError: bookingStatus === "error",
  };
};

const BookingCalendarWithDisabled = WithDisabled(BookingCalendar);
const ItineraryWithDisabled = WithDisabled(Itinerary);
const WizardWithDisabled = WithDisabled(Wizard);

export default connect(mapStateToProps)(BookingPage);
