export default ({ fullName, email }) =>
  `<p>Dear ${fullName},</p>

    <p>Your account is now active on the Londolozi Booking platform via the following link: <a href="http://${window.location.host}/">http://${window.location.host}/</a></p>

    <p>Please login using the details you provided: ${email}</p>
    
    <p>Should you need to reset your password, please use the Forgot password link on the Login page.</p>
    
    <p>Kind Regards,</p>

    <p>Jacqui Marais</p>`;
