import lodgeForce from "./../wrappers/LodgeForceAPI";

const setAgents = (searchWord, agents) => ({
  type: "SET_AGENTS",
  agents,
  searchWord,
});

const removeAgents = () => ({
  type: "REMOVE_AGENTS",
});

export const selectAgent = (rowIndex) => ({
  type: "SELECT_AGENT",
  rowIndex,
});

const startLoading = (thingThatsLoading) => ({
  type: "START_LOADING",
  thingThatsLoading,
});

export const startAgentSearch = (searchWord) => {
  return (dispatch) => {
    dispatch(startLoading("agents"));
    return lodgeForce
      .get(`manageagents/getagents/${searchWord}`)
      .then((agents) => {
        dispatch(removeAgents());
        dispatch(setAgents(searchWord, agents));
      })
      .catch((e) => console.log(e));
  };
};

const setConsultants = (first_name, last_name, email, consultants) => ({
  type: "SET_CONSULTANTS",
  consultants,
  first_name,
  last_name,
  email,
});

const removeConsultants = () => ({
  type: "REMOVE_CONSULTANTS",
});

export const selectConsultant = (rowIndex) => ({
  type: "SELECT_CONSULTANT",
  rowIndex,
});

export const startConsultantSearch = (first_name, last_name, email) => {
  return (dispatch) => {
    dispatch(startLoading("consultants"));
    return lodgeForce
      .get(
        `manageagents/getconsultants?first_name=${first_name}&last_name=${last_name}&email=${email}`
      )
      .then((consultants) => {
        dispatch(removeConsultants());
        dispatch(setConsultants(first_name, last_name, email, consultants));
      })
      .catch((e) => console.log(e));
  };
};

const setAccessToken = (token) => ({
  type: "SET_ACCESS_TOKEN",
  token,
});

export const removeAccessToken = () => ({
  type: "REMOVE_ACCESS_TOKEN",
});

const setAgentLinked = (updatedUser) => ({
  type: "SET_AGENT_LINKED",
  updatedUser,
});

export const removeAgentLinked = () => ({
  type: "REMOVE_AGENT_LINKED",
});

export const startSendEmail = ({ to, from, subject, html }) => {
  return (dispatch) => {
    return lodgeForce
      .post("/manageagents/submit_email", {
        to,
        from,
        subject,
        html,
      })
      .then((result) => {
        return result;
      })
      .catch((e) => {
        return e;
      });
  };
};

export const startSetAccessToken = ({ agentName, agentId, consultantId }) => {
  return (dispatch) => {
    return lodgeForce
      .post("manageagents/generate_token", {
        agentName,
        agentId,
        consultantId,
      })
      .then(({ token }) => {
        dispatch(setAccessToken(token));
      })
      .catch((e) => console.log(e));
  };
};

export const startLinkAgentToUser = ({
  agentName,
  agentId,
  consultantId,
  agentEmail,
}) => {
  return (dispatch) => {
    return lodgeForce
      .post("manageagents/link_agent_to_user", {
        agentName,
        agentId,
        consultantId,
        agentEmail,
      })
      .then((updatedUser) => {
        dispatch(setAgentLinked(updatedUser));
      })
      .catch((e) => {
        throw e;
      });
  };
};

const setAgentsWithAccess = (agents) => ({
  type: "SET_AGENTS_WITH_ACCESS",
  agents,
});

const removeAgentWithAccess = (id) => ({
  type: "REMOVE_AGENT_WITH_ACCESS",
  id,
});

export const startRemoveAgentWithAccess = (id) => {
  return (dispatch) => {
    return lodgeForce
      .post("manageagents/remove_agent_access", { id })
      .then(() => {
        dispatch(removeAgentWithAccess(id));
      })
      .catch((e) => {
        throw e;
      });
  };
};

export const startSetAgentsWithAccess = () => {
  return (dispatch) => {
    return lodgeForce
      .get("manageagents/get_agents_with_access")
      .then((agents) => {
        dispatch(setAgentsWithAccess(agents));
      });
  };
};

export const resetState = () => ({
  type: "RESET_STATE",
});
export const selectAgentWithAccess = (id) => ({
  type: "SELECT_AGENT_WITH_ACCESS",
  id,
});

export const deselectAgentWithAccess = (id) => ({
  type: "DESELECT_AGENT_WITH_ACCESS",
  id,
});
