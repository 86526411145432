import React from "react";
import FindAgent from "./FindAgent/index";
import FindConsultant from "./FindConsultant/index";
import LinkConsultantToUser from "./LinkConsultantToUser/index";
import EmailTemplate from "./EmailTemplate/index";
const ManageAgentsPage = () => {
  return (
    <div>
      <div style={{ height: "110px" }}></div>
      <div id="view-add-agents">
        <FindAgent />
        <FindConsultant />
        <LinkConsultantToUser />
        <EmailTemplate />
      </div>
    </div>
  );
};

export default ManageAgentsPage;
