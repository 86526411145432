import React from "react";
import update from "immutability-helper";
import LodgeOptionList from "./LodgeOptionList";

import lodgeForce from "./../../wrappers/LodgeForceAPI";
import WithLoading from "./../../hoc/WithLoading";

const LodgeOptionListWithLoading = WithLoading(LodgeOptionList);
export default class LodgeOptions extends React.Component {
  state = {
    isLoading: true,
    properties: {},
  };

  componentDidMount() {
    lodgeForce
      .get("/lodges")
      .then((response) => {
        const { properties } = response;
        this.setState({
          properties,
          isLoading: false,
        });
      })
      .catch((err) => {
        throw err;
      });
  }

  saveLodges = () => {
    return lodgeForce.post("/lodges", {
      properties: this.state.properties,
    });
  };

  changeNumber = (propertyName, accomTypeName, rateGroupName, type, value) => {
    this.setState(
      (state) => {
        return update(state, {
          properties: {
            [propertyName]: {
              accomTypes: {
                [accomTypeName]: {
                  rateGroups: {
                    [rateGroupName]: {
                      [type]: {
                        $set: value,
                      },
                    },
                  },
                },
              },
            },
          },
        });
      },
      () => {
        this.saveLodges().catch((e) => alert("Something went wrong, please try again"));
      }
    );
  };

  changeCapacity = (propertyName, accomTypeName, value) => {
    this.setState(
      (state) => {
        return update(state, {
          properties: {
            [propertyName]: {
              accomTypes: {
                [accomTypeName]: {
                  max_capacity: {
                    $set: value,
                  },
                },
              },
            },
          },
        });
      },
      () => {
        this.saveLodges().catch((e) => alert(e));
      }
    );
  };

  editPolicy = (value, propertyName) => {
    this.setState(
      (state) => {
        return update(state, {
          properties: {
            [propertyName]: {
              policy: { $set: value },
            },
          },
        });
      },
      () => {
        this.saveLodges().catch((e) => alert(e));
      }
    );
  };

  handleToggle = (details) => (event, toggle) => {
    const { type, property, accomType, rateGroup } = details;

    switch (type) {
      case "property":
        this.setState(
          ({ properties }) => ({
            properties: {
              ...properties,
              [property]: {
                ...properties[property],
                isVisible: toggle,
              },
            },
          }),
          () => {
            this.saveLodges().catch((e) => alert(e));
          }
        );
        break;
      case "accomType":
        this.setState(
          ({ properties }) => ({
            properties: {
              ...properties,
              [property]: {
                ...properties[property],
                accomTypes: {
                  ...properties[property].accomTypes,
                  [accomType]: {
                    ...properties[property].accomTypes[accomType],
                    isVisible: toggle,
                  },
                },
              },
            },
          }),
          () => {
            this.saveLodges().catch((e) => alert(e));
          }
        );
        break;
      case "rateGroup":
        this.setState(
          ({ properties }) => ({
            properties: {
              ...properties,
              [property]: {
                ...properties[property],
                accomTypes: {
                  ...properties[property].accomTypes,
                  [accomType]: {
                    ...properties[property].accomTypes[accomType],
                    rateGroups: {
                      ...properties[property].accomTypes[accomType].rateGroups,
                      [rateGroup]: {
                        ...properties[property].accomTypes[accomType]
                          .rateGroups[rateGroup],
                        isVisible: toggle,
                      },
                    },
                  },
                },
              },
            },
          }),
          () => {
            this.saveLodges().catch((e) => alert(e));
          }
        );
        break;
    }
  };

  render() {
    const { properties, isLoading } = this.state;
    return (
      <div className="lodge-page">
        <div style={{ height: "110px" }}></div>
        <div id="view-manage-lodge">
          <LodgeOptionListWithLoading
            properties={properties}
            handleToggle={this.handleToggle}
            isLoading={isLoading}
            changeNumber={this.changeNumber}
            editPolicy={this.editPolicy}
            save={this.saveLodges}
            changeCapacity={this.changeCapacity}
          />
        </div>
      </div>
    );
  }
}
