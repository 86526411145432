import React from "react";
import Dates from "./Dates";
import Properties from "./Properties";
import BookingCalendarHeader from "./BookingCalendarHeader";
import { Card } from "material-ui/Card";

const BookingCalendar = () => {
  return (
    <Card className="calendar booking-card">
      <div className="calendarLegend">
        <div style={{ width: "26%", display: "flex" }}>
          <div
            className="calendarlegendicon"
            style={{ backgroundColor: "#68b3ff" }}
          ></div>
          <p className="calendarlegendtext">Availability over selected dates</p>
        </div>
        <div style={{ width: "16%", display: "flex" }}>
          <div
            className="calendarlegendicon"
            style={{ backgroundColor: "#969696" }}
          ></div>
          <p className="calendarlegendtext">Unavailable</p>
        </div>
        <div style={{ width: "20%", display: "flex" }}>
          <div
            className="calendarlegendicon"
            style={{ backgroundColor: "#258ef9" }}
          ></div>
          <p className="calendarlegendtext">Selected booking</p>
        </div>
        <div style={{ width: "20%", display: "flex" }}>
          <div className="calendarlegendicon" style={{}}>
            1
          </div>
          <p className="calendarlegendtext">1 room available</p>
        </div>
        <div style={{ width: "25%", display: "flex" }}>
          <div className="calendarlegendicon" style={{}}>
            1+
          </div>
          <p className="calendarlegendtext">More than 1 room available</p>
        </div>
      </div>
      <BookingCalendarHeader />

      <Dates />
      <Properties />
    </Card>
  );
};

export default BookingCalendar;
