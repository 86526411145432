const defaultBookingFilters = {
  lineItems: [],
  lodge: "",
  propertyName: "",
  propertyId: "",
  accomName: "",
  accomId: "",
  arrival: "",
  departure: "",
  highlightType: "booking",
  bookingStatus: null,
};

export default (state = defaultBookingFilters, action) => {
  switch (action.type) {
    case "SET_LODGE": {
      return { ...state, lodge: action.lodge };
    }
    case "ADD_STAGING_LINE_ITEM": {
      const { type, ...stagedLineItem } = action;
      return {
        ...state,
        lineItems: [...state.lineItems, stagedLineItem],
        accomName: stagedLineItem.accomName,
        accomId: stagedLineItem.accomId,
        propertyName: stagedLineItem.propertyName,
        propertyId: stagedLineItem.propertyId,
        arrival: stagedLineItem.arrival,
        departure: stagedLineItem.departure,
        rateGroups: stagedLineItem.rateGroups,
        max_capactity: stagedLineItem.max_capactity,
        propertyPolicy: stagedLineItem.propertyPolicy,
      };
    }

    case "REMOVE_LINE_ITEM": {
      return {
        ...state,
        lineItems: state.lineItems.filter(
          (lineItem) => lineItem.lineId !== action.lineId
        ),
      };
    }
    case "REMOVE_STAGED_LINE_ITEMS": {
      return {
        ...state,
        lineItems: state.lineItems.filter((lineItem) => !!lineItem.confirmed),
      };
    }

    case "CONFIRM_LINE_ITEMS": {
      return {
        ...state,
        lineItems: state.lineItems.map((lineItem) => ({
          ...lineItem,
          confirmed: true,
        })),
      };
    }

    case "SET_RATE": {
      let lineItem = state.lineItems.find(
        (lineItem) => lineItem.lineId === action.lineId
      );
      lineItem.rate = action.rate;

      return {
        ...state,
        lineItems: state.lineItems.map((lineItem) => {
          if (lineItem.lineId === action.lineId) {
            return { ...lineItem, rate: action.rate };
          }
          return lineItem;
        }),
      };
    }
    case "SET_LEVI": {
      let lineItem = state.lineItems.find(
        (lineItem) => lineItem.lineId === action.lineId
      );
      lineItem.levy = action.levy;

      return {
        ...state,
        lineItems: state.lineItems.map((lineItem) => {
          if (lineItem.lineId === action.lineId) {
            return { ...lineItem, levy: action.levy };
          }
          return lineItem;
        }),
      };
    }

    case "SET_OVERRIDE": {
      let lineItem = state.lineItems.find(
        (lineItem) => lineItem.lineId === action.lineId
      );
      lineItem.override = action.override;

      return {
        ...state,
        lineItems: state.lineItems.map((lineItem) => {
          if (lineItem.lineId === action.lineId) {
            return { ...lineItem, override: action.override };
          }
          return lineItem;
        }),
      };
    }

    case "REMOVE_RATE": {
      return {
        ...state,
        lineItems: state.lineItems.map((lineItem) => {
          if (lineItem.lineId === action.lineId) {
            return { ...lineItem, rate: undefined };
          }
          return lineItem;
        }),
      };
    }

    case "SET_DEFAULT_CONFIG": {
      const lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.lineId === action.lineId) {
          return {
            ...lineItem,
            rateGroups: lineItem.rateGroups.map((rateGroup) => {
              return {
                ...rateGroup,
                number: rateGroup.defaultNumber.toString(),
              };
            }),
          };
        }
        return lineItem;
      });

      return { ...state, lineItems };
    }

    case "SET_CONFIG": {
      const lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.lineId === action.lineId) {
          return {
            ...lineItem,
            rateGroups: lineItem.rateGroups.map((rateGroup) => {
              if (rateGroup.id === action.rateGroupId) {
                return {
                  ...rateGroup,
                  number: action.number,
                };
              }
              return rateGroup;
            }),
          };
        }
        return lineItem;
      });

      return { ...state, lineItems };
    }

    case "SET_HIGHLIGHT_TYPE": {
      return { ...state, highlightType: action.highlightType };
    }
    case "MAKE_BOOKING": {
      return { ...state, bookingStatus: "pending" };
    }
    case "BOOKING_SUCCESS": {
      return { ...state, bookingStatus: "success" };
    }
    case "BOOKING_ERROR": {
      return { ...state, bookingStatus: "error" };
    }
    case "CLEAR_BOOKING": {
      return defaultBookingFilters;
    }
    default:
      return state;
  }
};
