const defaultBookingManagement = {
  bookings: [],
};

export default (state = defaultBookingManagement, action) => {
  switch (action.type) {
    case "SET_BOOKINGS":
      return {
        ...state,
        bookings: action.bookings,
      };
    case "SELECT_BOOKING":
      return {
        ...state,
        bookings: state.bookings.map((booking) => {
          if (booking._id == action.id._id) {
            return {
              ...booking,
              selected: true,
            };
          } else {
            return booking;
          }
        }),
      };
    case "DESELECT_BOOKING":
      return {
        ...state,
        bookings: state.bookings.map((booking) => {
          if (booking._id == action.id) {
            return {
              ...booking,
              selected: false,
            };
          } else {
            return booking;
          }
        }),
      };
    case "SELECT_ITINERARY":
      return {
        ...state,
        itinerary: action.itinerary,
      };
    case "DESELECT_ITINERARY":
      return {
        ...state,
        itinerary: undefined,
      };

    case "UPDATE_SUCCESSFULL":
      return {
        ...state,
        successfull: action.val,
      };
    case "UPDATE_SUCCESSFULL_ITINERARY":
      return {
        ...state,
        successfullitinerary: action.val,
      };
    case "ERROR_UPDATE_ITINERARY":
      return {
        ...state,
        erroritinerary: action.val,
      };

    case "BOOKING_CHANGE":
      return {
        ...state,
        changepopup: action.val,
      };
    case "SET_RATE_UPDATE":
      return {
        ...state,
        rate: action.rate,
      };
    case "UPDATE_LATEST_BOOKING":
      return {
        ...state,
        bookings: state.bookings.map((booking) => {
          if (booking._id == action.booking._id) {
            return {
              ...action.booking,
              selected: true,
            };
          } else {
            return booking;
          }
        }),
      };

    default:
      return state;
  }
};
