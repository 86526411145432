import React from "react";
import Modal from "react-modal";
import { CardTitle } from "material-ui/Card";
import LinearProgress from "material-ui/LinearProgress";

const modalStyle = {
  overlay: {
    backgroundColor: "rgb(0,0,0,0.75)",
  },
};
Modal.setAppElement("#app");

const ConfirmationModal = ({
  isOpen,
  isLoading,
  loadingText,
  isProcessed,
  processedText,
  isError,
  errorText,
}) => {
  return (
    <Modal
      className="loadingpopup"
      isOpen={isOpen}
      style={modalStyle}
      ariaHideApp={true}
    >
      {isLoading && (
        <div>
          <CardTitle title={loadingText} />
          <LinearProgress mode="indeterminate" />
        </div>
      )}
      {isProcessed && (
        <div>
          <CardTitle title={processedText} />
          <LinearProgress mode="determinate" value={100} color="green" />
        </div>
      )}
      {isError && (
        <div>
          <CardTitle title={errorText} />
          <LinearProgress mode="determinate" value={100} color="red" />{" "}
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
