import React from "react";

const AccomHeader = ({ rateGroups }) => {
  return (
    <tr>
      <th>Ref Id</th>
      <th>Arrival</th>
      <th>Departure</th>
      <th>Nights</th>
      {rateGroups.map(({ desc }, i) => (
        <th key={i}>{desc}</th>
      ))}
      <th>Levy</th>
      <th>Commission</th>
      <th>Payable to Lodge</th>
      <th>Payable by Client</th>
      <th>Remove</th>
    </tr>
  );
};

export default AccomHeader;
