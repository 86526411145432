import React from "react";
import { connect } from "react-redux";
import { CardTitle } from "material-ui/Card";
import ConsultantSearchBar from "./ConsultantSearchBar";
import ConsultantTable from "./ConsultantTable";
import WithDisabled from "./../../../hoc/WithDisabled";
import SearchResults from "./../SearchResults";

const FindConsultant = ({
  resultCount,
  isConsultantListDisabled,
  isSearched,
  isVisible,
}) => {
  return isVisible ? (
    <div className="booking-card">
      <CardTitle title="2. Select a Consultant" />
      <ConsultantSearchBar />
      <SearchResults resultCount={resultCount} isSearched={isSearched} />
      <ConsultantTableWithDisabled isDisabled={isConsultantListDisabled} />
    </div>
  ) : (
    <div />
  );
};

const ConsultantTableWithDisabled = WithDisabled(ConsultantTable);

const mapStateToProps = ({ agentManagement }) => {
  const { consultants, agents, accessToken } = agentManagement;
  return {
    resultCount: consultants && consultants.length,
    isConsultantListDisabled: !consultants,
    isSearched: consultants !== undefined,
    isVisible:
      agents &&
      agents.filter((agent) => agent.selected).length > 0 &&
      accessToken == undefined,
  };
};

export default connect(mapStateToProps)(FindConsultant);
