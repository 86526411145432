import React from "react";
import { connect } from "react-redux";
import RaisedButton from "material-ui/RaisedButton";
import getSelectionAvailability from "./../../../functions/getSelectionAvailability";
import { startAddStagingLineItem } from "./../../../actions/booking";
import { v4 as uuid } from "uuid";
import _ from "lodash";

const AddRoom = ({
  selectionAvailability,
  addStagingLineItem,
  bookingDetails,
  isMixed,
}) => {
  const isRoomLeft = selectionAvailability.every(
    (availability) => availability > 0
  );
  return isMixed ? null : (
    <RaisedButton
      label="Add another room"
      fullWidth={true}
      onClick={() => addStagingLineItem(bookingDetails)}
      disabled={!isRoomLeft}
      style={{ marginTop: 30 }}
      className="add-another-room"
    />
  );
};

const mapStateToProps = ({ booking, properties }) => {
  const stagedLineItems = booking.lineItems.filter(
    (lineItem) => lineItem.confirmed !== true
  );
  const isMixed = !stagedLineItems.every(
    (lineItem) => stagedLineItems[0].accomName === lineItem.accomName
  );

  let bookingDetails = _.pick(booking, [
    "propertyName",
    "propertyId",
    "accomName",
    "accomId",
    "arrival",
    "departure",
    "rateGroups",
    "propertyPolicy",
  ]);

  bookingDetails.max_capacity = stagedLineItems[0].max_capacity;

  return {
    selectionAvailability: getSelectionAvailability(
      booking.arrival,
      booking.departure,
      properties.properties[booking.propertyName] &&
        properties.properties[booking.propertyName].accomTypes[
          booking.accomName
        ].availability
    ),
    bookingDetails,
    isMixed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addStagingLineItem: ({
      propertyName,
      propertyId,
      accomName,
      accomId,
      arrival,
      departure,
      rateGroups,
      propertyPolicy,
      max_capacity,
    }) => {
      dispatch(
        startAddStagingLineItem({
          propertyName,
          propertyId,
          accomName,
          accomId,
          arrival,
          departure,
          rateGroups,
          propertyPolicy,
          lineId: uuid(),
          max_capacity,
        })
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRoom);
