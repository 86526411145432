import React from "react";
import { connect } from "react-redux";
import { CardTitle } from "material-ui/Card";
import AgentSearchBar from "./AgentSearchBar";
import AgentTable from "./AgentTable";
import WithDisabled from "./../../../hoc/WithDisabled";

import SearchResults from "./../SearchResults";

const FindAgent = ({
  resultCount,
  isAgentListDisabled,
  isSearched,
  isVisible,
}) => {
  return isVisible ? (
    <div className="booking-card">
      <CardTitle title="1. Select an Agent" />
      <AgentSearchBar />
      <SearchResults resultCount={resultCount} isSearched={isSearched} />
      <AgentTableWithDisabled isDisabled={isAgentListDisabled} />
    </div>
  ) : (
    <div />
  );
};

const AgentTableWithDisabled = WithDisabled(AgentTable);

const mapStateToProps = ({ agentManagement }) => {
  const { agents, accessToken } = agentManagement;
  return {
    resultCount: agents && agents.length,
    isAgentListDisabled: !agents,
    isSearched: agents !== undefined,
    isVisible: accessToken == undefined,
  };
};

export default connect(mapStateToProps)(FindAgent);
