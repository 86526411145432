import React from "react";
import { connect } from "react-redux";
import IconButton from "material-ui/IconButton";
import { Card, CardTitle } from "material-ui/Card";
import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";
import Search from "material-ui/svg-icons/action/search";
import {
  setStateDates,
  setSearchDates,
  setCalendarRangeDates,
  setNumberOfRooms,
  switchView,
  setGuestNumbers,
  setWizardstate,
  setSearchState,
} from "./../../../actions/bookingFilters";
import { startSetLodges, selectLodge } from "./../../../actions/lodges";
import { startSetProperties } from "./../../../actions/properties";
import { setLodge } from "./../../../actions/booking";
import WithLoading from "./../../../hoc/WithLoading";
import DatePicker from "./../../../shared-components/DatePicker";
import { DropDown } from "./../../../shared-components/DropDown";
import {
  DropDownMultiple,
  DropDownGuests,
  DropDownRooms,
} from "./../../../shared-components/DropDown";
import { getNightsStayed } from "./../../../functions/dates";

const DropDownWithLoading = WithLoading(DropDown);
const IconButtonWithLoading = WithLoading(IconButton);
const FlatButtonWithLoading = WithLoading(FlatButton);
let nights = 0;

class BookingSearchBar extends React.Component {
  componentWillMount() {
    this.props.setLodges().catch((e) => console.log(e));
  }

  state = {
    adults: 0,
    children6_11: 0,
    children12_15: 0,
    isGuestOpen: false,
    isRoomsOpen: false,
    guestCount: 0,
    roomCount: 1,
  };

  changeProperty = (event, index, lodge) => {
    this.props.selectLodge(lodge);
    // this.setState({ selectedLodge: value });
  };

  checkAvailability = () => {
    this.props.setProperties(this.props.selectedLodge || this.props.lodges[0]);
    this.props.setCalendarRangeDates();
    this.props.setLodge(this.props.selectedLodge || this.props.lodges[0]);
  };

  handleGuestClose = (event) => {
    this.setState({
      isGuestOpen: false,
    });

    this.props.setGuestNumbers([
      { Adults: this.state.adults + this.state.children12_15 },
      { Children: this.state.children6_11 },
    ]);
  };

  handleGuestOpen = (event) => {
    this.setState({
      isGuestOpen: true,
    });
  };

  handleRoomsClose = (event) => {
    this.setState({
      isRoomsOpen: false,
    });

    this.props.setNumberOfRooms(this.state.roomCount);
  };

  handleRoomsOpen = (event) => {
    this.setState({
      isRoomsOpen: true,
    });
  };

  guestchange = (g1, g2, g3) => {
    this.setState({
      adults: g1,
      children6_11: g2,
      children12_15: g3,
      guestCount: g1 + g2 + g3,
    });
  };

  roomchange = (c1) => {
    this.setState({
      roomCount: c1,
    });
  };

  donebuttonclickguest = () => {
    this.setState({
      isGuestOpen: false,
    });

    this.props.setGuestNumbers([
      { Adults: this.state.adults + this.state.children12_15 },
      { Children: this.state.children6_11 },
    ]);
  };

  donebuttonclickroom = () => {
    this.setState({
      isRoomsOpen: false,
    });
    this.props.setNumberOfRooms(this.state.roomCount);
  };

  setSearchDatesFunction = (startDate, endDate) => {
    this.props.setSearchDates(startDate, endDate);
  };

  render() {
    const {
      startDate,
      setSearchDates,
      endDate,
      selectedOption,
      setStateDates,
      setNumberOfRooms,
      rooms,
      isCalendar,
      isWizard,
      isChildren,
      switchView,
      selectedLodge,
      lodges,
      isGroup1,
      isGroup2,
      isGroup3,
      isGroup4,
      setGuestNumbers,
    } = this.props;

    const selectedItem =
      selectedLodge ||
      (typeof lodges == "object" && lodges.length === 1
        ? lodges[0]
        : undefined);

    const isReadyToSearch = startDate && endDate && !!selectedItem;
    const hasNoAccess = lodges !== undefined && lodges.length === 0;
    if (isReadyToSearch) {
      nights = getNightsStayed(startDate, endDate);
    } else {
      nights = 0;
    }

    return (
      <div className="booking-card-search-parent">
        <Card
          className={
            hasNoAccess ? "booking-card__warning" : "booking-card-searchbar"
          }
        >
          {hasNoAccess ? (
            <CardTitle
              title={
                "Sorry, you cannot make a booking as you have not been granted access to any lodges yet!"
              }
            />
          ) : (
            <div>
              <div className="search-dates-header">
                <h2>Make a Booking</h2>
              </div>
              <div className="search-dates-header2">Details</div>

              <div className="search-dates-header3">Dates</div>

              <div className="search-dates-box">
                <div className="search-dates">
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    //value={(startDate, endDate)}
                    setSearchDates={setSearchDates}
                  />
                </div>
              </div>

              <div className="search-dates-header4_1">Rooms</div>
              <div className="search-dates-header4_2">Guests</div>

              <div className="search-config-box">
                <DropDownRooms
                  className="search-config-box-rooms"
                  open={this.state.isRoomsOpen}
                  onClose={this.handleRoomsClose}
                  onOpen={this.handleRoomsOpen}
                  onClick={this.roomchange}
                  //,isGroup2 ? "Adult Single" : "", isGroup3 ? "Children 12-16" : "", isGroup4 ? "Children 6-11" : ""
                  style={{ maxWidth: 250 }}
                  roomCount={this.state.roomCount}
                  donebuttonclick={this.donebuttonclickroom}
                />

                <DropDownGuests
                  className="search-config-box-guests"
                  open={this.state.isGuestOpen}
                  onClose={this.handleGuestClose}
                  onOpen={this.handleGuestOpen}
                  onClick={this.guestchange}
                  //,isGroup2 ? "Adult Single" : "", isGroup3 ? "Children 12-16" : "", isGroup4 ? "Children 6-11" : ""
                  style={{ maxWidth: 250 }}
                  adultcount={this.state.adults}
                  children1count={this.state.children6_11}
                  children2count={this.state.children12_15}
                  guestCount={this.state.guestCount}
                  donebuttonclick={this.donebuttonclickguest}
                />
              </div>

              <div className="search-dates-header5">
                {nights} {nights > 1 ? "nights" : "night"}
              </div>

              <div className="search-button-box">
                <FlatButtonWithLoading
                  className="search-button-style"
                  label="Search"
                  onClick={() => {
                    isReadyToSearch && this.checkAvailability();

                    this.props.setSearchState(true);
                    this.props.setWizardstate(true);
                  }}
                  isLoading={this.props.isSearching}
                  fullWidth={true}
                >
                  {/* <Search disabled={!isReadyToSearch} /> */}
                </FlatButtonWithLoading>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ bookingFilters, lodges, properties }) => {
  return {
    startDate: bookingFilters.searchedDates.startDate,
    endDate: bookingFilters.searchedDates.endDate,
    lodges: lodges ? lodges.lodges : [],
    rooms: bookingFilters.numberOfRooms,
    isWizard: bookingFilters.isWizard,
    isChildren: bookingFilters.isChildren,
    isCalendar: bookingFilters.isCalendar,
    isSearching: properties.isSearching,
    selectedLodge: lodges.selectedLodge,
    isGroup1: bookingFilters.isGroup1,
    isGroup2: bookingFilters.isGroup2,
    isGroup3: bookingFilters.isGroup3,
    isGroup4: bookingFilters.isGroup4,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchDates: (startDate, endDate) =>
      dispatch(setSearchDates({ startDate, endDate })),
    setStateDates: (startDate, endDate) =>
      dispatch(setSearchDates({ startDate, endDate })),
    setLodges: () => dispatch(startSetLodges()),
    setProperties: (lodge) => dispatch(startSetProperties(lodge)),
    setCalendarRangeDates: () => dispatch(setCalendarRangeDates()),
    setLodge: (lodge) => dispatch(setLodge(lodge)),
    setNumberOfRooms: (rooms) => dispatch(setNumberOfRooms(rooms)),
    switchView: (view) => dispatch(switchView(view)),
    selectLodge: (lodge) => dispatch(selectLodge(lodge)),
    setGuestNumbers: (guests) => dispatch(setGuestNumbers(guests)),
    setWizardstate: (val) => dispatch(setWizardstate(val)),
    setSearchState: (val) => dispatch(setSearchState(val)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BookingSearchBar);
