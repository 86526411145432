import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  createDateArray,
  createMomentDateArray,
  createMonthArray,
} from "./../../../functions/dates";

import DateHeader from "./DateHeader";
import DateRow from "./DateRow";

const Dates = ({ dates, months }) => {
  return (
    <table className="table-drag-select dates">
      <tbody>
        <DateHeader months={months} />
        <DateRow dates={dates} format={"dd"} />
        <DateRow dates={dates} format={"D"} />
      </tbody>
    </table>
  );
};

const mapStateToProps = ({ bookingFilters }) => {
  const datesArray = createDateArray(
    bookingFilters.calendarRangeDates.startDate,
    bookingFilters.calendarRangeDates.endDate
  );
  const months = createMonthArray(datesArray);
  const dates = createMomentDateArray(
    bookingFilters.calendarRangeDates.startDate,
    bookingFilters.calendarRangeDates.endDate
  );
  return {
    months,
    dates,
  };
};

export default connect(mapStateToProps)(Dates);
