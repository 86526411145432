import React from "react";
import lodgeForce from "./../../wrappers/LodgeForceAPI";
import { Card, CardTitle } from "material-ui/Card";
class Verify extends React.Component {
  state = {
    message: "Loading...",
    error: false,
  };
  componentDidMount() {
    const { token } = this.props.match.params;
    return lodgeForce
      .get(`/agentaccess/verify/${token}`)
      .then(({ message }) => {
        this.setState({ message, error: false });
      })
      .catch(({ message }) => this.setState({ message, error: true }));
  }

  render() {
    return (
      <Card
        className={this.state.error ? "booking-card__warning" : "booking-card"}
      >
        <CardTitle title={this.state.message} />
      </Card>
    );
  }
}

export default Verify;
