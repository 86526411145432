import React from "react";
import autosize from "autosize";

export default class InlineEdit extends React.Component {
  state = {
    focussed: false,
    value: this.props.defaultValue,
  };
  componentDidMount() {
    autosize(this.textarea);
  }
  onCancel = () => {
    const { defaultValue, name, updateValue } = this.props;
    updateValue(defaultValue, name);
    this.setState({ value: defaultValue, focussed: false });
  };

  onBlur = (e) => {
    if (e.relatedTarget && e.relatedTarget.name === "cancel") {
      return;
    }
    const { value } = e.target;

    const { name, updateValue } = this.props;
    updateValue(value, name);
    this.setState({ focussed: false });
  };

  onFocus = () => {
    this.setState({ focussed: true });
  };

  onChange = (e) => {
    const { value } = e.target;
    this.setState({ value });
  };

  render() {
    const { style } = this.props;

    return (
      <div className="policy">
        <textarea
          ref={(c) => (this.textarea = c)}
          onBlur={this.onBlur}
          value={this.state.value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          style={style.text}
          autoFocus={false}
        />
        {this.state.focussed && (
          <div>
            <button name="confirm" style={style.confirm}>
              Confirm
            </button>
            <button name="cancel" onClick={this.onCancel} style={style.cancel}>
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  }
}
