import React from "react";
import lodgeForce from "./../../wrappers/LodgeForceAPI";
import ForgotForm from "./ForgotForm";

export default class ForgotPassword extends React.Component {
  state = {
    redirect: false,
    errors: {},
    email: "",
    header: "Forgot your password?",
    isSendDisabled: false,
  };

  processForm = (event) => {
    event.preventDefault();
    const { email } = this.state;
    const { hostname } = window.location;
    return lodgeForce
      .post("/users/send_reset_password_email", {
        email,
        hostname,
      })
      .then(() => {
        this.setState({
          header:
            "You will receive an email with instructions about how to reset your password in a few minutes.",
          isSendDisabled: true,
          errors: {
            summary: "",
          },
        });
      })
      .catch(() => {
        this.setState(() => ({
          errors: {
            summary:
              "Unfortunately we don't recognise those details. Please try again.",
          },
          email: "",
        }));
      });
  };

  changeEmail = (event) => {
    const email = event.target.value;

    this.setState(() => ({ email }));
  };

  render() {
    return (
      <ForgotForm
        header={this.state.header}
        onSubmit={this.processForm}
        onChange={this.changeEmail}
        errors={this.state.errors}
        email={this.state.email}
        isSendDisabled={this.state.isSendDisabled}
      />
    );
  }
}
