import React from "react";
import { connect } from "react-redux";
import { CardTitle } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import ContentClear from "material-ui/svg-icons/content/clear";
import IconButton from "material-ui/IconButton";
import Modal from "react-modal";
import {
  deselectAgentWithAccess,
  startRemoveAgentWithAccess,
} from "../../../actions/agentManagement";

const modalStyle = {
  content: {
    outline: "none",
    backgroundColor: "white",
    width: "60rem",
    border: "0.1rem solid #888888",
    boxShadow: "0.5rem 1rem 1rem #888888",
    margin: "auto",
    textAlign: "center",
    top: "115px",
  },
};
Modal.setAppElement("#app");

class AgentModal extends React.Component {
  render() {
    const { agentDetails } = this.props;
    return (
      <Modal
        isOpen={!!agentDetails}
        style={modalStyle}
        onRequestClose={() => this.props.exit(agentDetails.id)}
      >
        <div className="agent-header">
          <div />
          <CardTitle title="Agent Info" />
          <div id="agent-exit-button">
            <IconButton
              styles={{ width: 2 }}
              onClick={() => this.props.exit(agentDetails.id)}
            >
              <ContentClear />
            </IconButton>
          </div>
        </div>

        {agentDetails && (
          <table className="agent-info-table">
            <tr>
              <td>First name: </td>
              <td>{agentDetails.firstName}</td>
            </tr>
            <tr>
              <td>Last name: </td>
              <td>{agentDetails.lastName}</td>
            </tr>
            <tr>
              <td>Email: </td>
              <td>{agentDetails.email}</td>
            </tr>
            <tr>
              <td>Agency: </td>
              <td>{agentDetails.access.agentName}</td>
            </tr>
            <tr>
              <td>Agency Id: </td>
              <td>{agentDetails.access.agentId}</td>
            </tr>
            <tr>
              <td>Consultant Id: </td>
              <td>{agentDetails.access.consultantId}</td>
            </tr>
          </table>
        )}
        <FlatButton
          label="Remove Agent"
          onClick={() => {
            this.props.removeAgentWithAccess(agentDetails.id);
          }}
        >
          {" "}
        </FlatButton>
      </Modal>
    );
  }
}

const mapStateToProps = ({ agentManagement }) => {
  const agentDetails =
    agentManagement.agentsWithAccess &&
    agentManagement.agentsWithAccess.find((agent) => agent.selected === true);
  return { agentDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exit: (id) => dispatch(deselectAgentWithAccess(id)),
    removeAgentWithAccess: (id) => dispatch(startRemoveAgentWithAccess(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentModal);
