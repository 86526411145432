import React, { useEffect } from "react";
import { connect } from "react-redux";
import { startSetLodges } from "./../../actions/lodges";
import NoAccessWarning from "./Shared/NoAccessWarning/index";
import DashboardTiles from "./Shared/DashboardTiles/index";
import NewsletterForm from "./Shared/NewsletterForm/index";

const Dashboard = (props) => {
  const { lodges } = props;

  useEffect(() => {
    props.setLodges();
  }, []);

  return (
    <div>
      <div style={{ height: "110px" }}></div>
      {lodges && lodges.length === 0 && <NoAccessWarning />}

      <DashboardTiles />
      <NewsletterForm />
      <div style={{ height: "30px" }}></div>
    </div>
  );
};

const mapStateToProps = ({ lodges }) => {
  return {
    lodges: lodges.lodges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLodges: () => dispatch(startSetLodges()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
