import React from "react";
import { connect } from "react-redux";

import moment from "moment";
import FlatButton from "material-ui/FlatButton";
import Close from "material-ui/svg-icons/navigation/close";
import formatRate from "./../../../functions/formatRate";
import { getNightsStayed } from "./../../../functions/dates";
import { startRemoveLineItem } from "./../../../actions/booking";
const format = "YYYY-MM-DD";
const newFormat = "Do MMM YYYY";

const AccomLine = ({ lineItem, refId, removeLineItem }) => {
  // console.log("lineItem --> ", lineItem)
  const { arrival, departure, rateGroups, rate, lineId, levy } = lineItem;
  const arrivalFormatted = moment(arrival, format).format(newFormat);
  const departureFormatted = moment(departure, format).format(newFormat);
  const nights = getNightsStayed(arrival, departure);
  const { gross, comm, nett } = rate;
  return (
    <tr>
      <td>{refId}</td>
      <td>{arrivalFormatted}</td>
      <td>{departureFormatted}</td>
      <td>{nights}</td>
      {rateGroups.map(({ number }, i) => (
        <td key={i}>{number}</td>
      ))}
      <td>{formatRate(levy)}</td>
      <td>{formatRate(comm)}</td>
      <td>{formatRate(nett + levy)}</td>
      <td>{formatRate(gross + levy)}</td>
      <td>
        <FlatButton icon={<Close />} onClick={() => removeLineItem(lineId)} />
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeLineItem: (lineId) => dispatch(startRemoveLineItem(lineId)),
  };
};

export default connect(undefined, mapDispatchToProps)(AccomLine);
