import fetch from "node-fetch";

class LondoloziLiveApi {
  constructor() {
    this.url = "https://trade.londolozi.com/wp-json/wp/v2/";
  }

  get(contentType, contentId) {
    var urlToFetch = this.url + contentType + "/" + contentId;
    //        this.url += contentType + '/' + contentId;

    return fetch(urlToFetch, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((res) => {
            throw res;
          });
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        throw error;
      });
  }
}
export default new LondoloziLiveApi();
