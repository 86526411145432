/* eslint-disable no-undef*/

import React from "react";
import { connect } from "react-redux";
import { Card, CardTitle } from "material-ui/Card";
import RaisedButton from "material-ui/FlatButton";
import ContentClear from "material-ui/svg-icons/content/clear";
import IconButton from "material-ui/IconButton";
import Modal from "react-modal";
import {
  deselectItinerary,
  updateItinerary,
  updateSuccessfull,
  startSetRateUpdate,
  setRateUpdate,
  updateSuccessfullItinerary,
  errorUpdateItinerary,
} from "./../../../actions/bookingManagement";
import ReactTable from "react-table";
import LoaderBookingUpdate from "./../ConfirmedUpdate/index";
import "react-table/react-table.css";
import TextField from "material-ui/TextField";
import { DropDown } from "./../../../shared-components/DropDown";
import WithLoading from "./../../../hoc/WithLoading";
import DropDownPicker from "./../../../shared-components/DropdownPickerItineraryItem";
import formatRate from "./../../../functions/formatRate";
import Select from "react-select";
import lodgeForce from "./../../../wrappers/LodgeForceAPI";
import moment, { months } from "moment";

const Span = ({ children }) => (
  <span style={{ height: "100vh" }}>{children}</span>
);
const SpanWithLoading = WithLoading(Span);

const modalStyle = {
  content: {
    outline: "none",
    backgroundColor: "white",
    width: "80rem",
    height: "50rem",
    border: "0.1rem solid #888888",
    boxShadow: "0.5rem 1rem 1rem #888888",
    margin: "auto",
    textAlign: "center",
  },
};

const optionsMonth = [];

for (let i = 0; i < 365; i++) {
  optionsMonth.push({
    value: moment().add(i, "days").format("YYYY-MM-DD"),
    label: moment().add(i, "days").format("YYYY-MM-DD"),
  });
}

Modal.setAppElement("#app");

class BookingModal extends React.Component {
  state = {
    startdate: "",
    enddate: "",
    property: "",
    accom: "",
    properties: "",
    propertyoptions: [{ value: "", label: "" }],
    accommodationoptions: [{ value: "", label: "" }],
    adult: 0,
    adultsingle: 0,
    child: 0,
    adultoptions: [{ value: "", label: "" }],
    adultsingleoptions: [{ value: "", label: "" }],
    childoptions: [{ value: "", label: "" }],
    amount: "",
    accomId: "",
    propertyId: "",
    rateGroups: "",
    loading: false,
  };

  componentDidMount() {
    lodgeForce
      .get("/lodges")
      .then((response) => {
        const { properties } = response;

        this.setState({
          properties,
          propertyoptions: Object.keys(properties)
            .filter((propvisible) => {
              if (properties[propvisible].isVisible) {
                return true; // skip
              }
              return false;
            })
            .map((val) => {
              return { value: val, label: val };
            }),
        });
      })
      .catch((err) => {
        throw err;
      });
  }

  updateTextField = (myState) => (e) => {
    const notes = e.target.value;
    if (myState == "generalNotes") {
      this.props.bookingDetails.generalNotes = notes;
    } else if (myState == "guestNotes") {
      this.props.bookingDetails.guestNotes = notes;
    } else if (myState == "internalMemo") {
      this.props.bookingDetails.internalMemo = notes;
    }
    this.setState({ [myState]: notes });
  };

  changeDropdown = (value) => {
    this.setState({ updateType: value });
  };

  onChangeProperty = (property) => {
    this.setState({
      property: property,
      accommodationoptions: Object.keys(
        this.state.properties[property].accomTypes
      )
        .filter((accomvisible) => {
          if (
            this.state.properties[property].accomTypes[accomvisible].isVisible
          ) {
            return true; // skip
          }
          return false;
        })
        .map((val) => {
          if (this.state.properties[property].accomTypes[val].isVisible) {
            return {
              value: val,
              label: val.includes("Camp Chalet") ? "Camp Chalet" : val,
            };
          } else {
            return { value: "", label: "" };
          }
        }),
      accom: "",
    });

    this.state.propertyId = this.state.properties[this.state.property].id;
  };

  updateRate = () => {
    this.setState({ amount: 0 });
    let rateObj = {
      lodge: "Londolozi",
      arrival: this.state.startdate,
      departure: this.state.enddate,
      accomId: this.state.accomId,
      rateGroups: this.state.rateGroups,
    };

    this.props.setRate(rateObj);
  };

  onChangeInput = (newValue) => {
    let updateAmount = formatRate(newValue.target.value);

    this.setState({
      amount: updateAmount,
    });
    // var nPrice = newValue.target.value.replace(/,/g, '').replace(/ZAR/g, " ");
    // var newPrice = nPrice.split('.')[0]
    // this.props.setRate({
    //     rate:
    // })
    // this.props.setOverride({
    //     lineId: this.props.lineId,
    //     override: newPrice
    // })
  };

  onChangeAccommodation = (accom) => {
    this.setState({ accom: accom });

    let adultmax = this.state.properties[this.state.property].accomTypes[accom]
      .rateGroups["Adult"].maxNumber;
    let adultsinglemax = this.state.properties[this.state.property].accomTypes[
      accom
    ].rateGroups["Adult Single"].maxNumber;
    let childmax = this.state.properties[this.state.property].accomTypes[accom]
      .rateGroups["Child 6 - 11"].maxNumber;

    this.state.adultoptions = this.getGuestNumberArray(adultmax);
    this.state.adultsingleoptions = this.getGuestNumberArray(adultsinglemax);
    this.state.childoptions = this.getGuestNumberArray(childmax);

    this.state.adult =
      adultmax > this.state.adult ? this.state.adult : adultmax;
    this.state.adultsingle =
      adultsinglemax > this.state.adultsingle
        ? this.state.adultsingle
        : adultsinglemax;
    this.state.child =
      childmax > this.state.child ? this.state.child : childmax;

    if (this.state.properties && this.state.properties[this.state.property]) {
      this.state.accomId = this.state.properties[
        this.state.property
      ].accomTypes[accom].id;
    }
  };

  getGuestNumberArray = (maxNumber) => {
    let guestNumberArray = [];
    for (let i = 0; i <= maxNumber; i++) {
      guestNumberArray.push({ value: i.toString(), label: i.toString() });
    }
    return guestNumberArray;
  };

  exit = (val) => {
    this.setState({ startdate: "", loading: false });
    this.props.exit(val);
  };

  render() {
    const { itinerary, rate } = this.props;

    if (itinerary != undefined && this.state.startdate == "") {
      this.state.propertyId = itinerary.propertyId;
      this.state.accomId = itinerary.accomId;
      this.state.rateGroups = itinerary.rateGroups;
      this.state.startdate = itinerary.arrival;
      this.state.enddate = itinerary.departure;
      this.state.property = itinerary.propertyName;
      this.state.accom = itinerary.accomName;
      if (this.state.properties && this.state.properties[this.state.property]) {
        this.state.accommodationoptions = Object.keys(
          this.state.properties[this.state.property].accomTypes
        )
          .filter((accomvisible) => {
            if (
              this.state.properties[this.state.property].accomTypes[
                accomvisible
              ].isVisible
            ) {
              return true; // skip
            }
            return false;
          })
          .map((val) => {
            if (
              this.state.properties[this.state.property].accomTypes[val]
                .isVisible
            ) {
              return {
                value: val,
                label: val.includes("Camp Chalet") ? "Camp Chalet" : val,
              };
            } else {
              return { value: "", label: "" };
            }
          });
      }

      this.state.adult = itinerary.adult;
      this.state.adultsingle = itinerary.adultsingle;
      this.state.child = itinerary.child;
      this.state.amount = formatRate(itinerary.rate.nett);

      this.onChangeAccommodation(this.state.accom);
    } else if (rate != undefined) {
      this.state.amount = formatRate(rate.nett);
      this.props.setRateUpdate(undefined);
    } else if (this.props.successfullitinerary == true) {
      this.setState({ startdate: "", loading: false });
      // this.props.exit(bookingDetails._id)
      this.props.changesuccess(false);
      this.props.exit(true);
    } else if (this.props.erroritinerary == true) {
      this.setState({ loading: false });
      this.props.changesuccess(false);
      this.props.errorUpdateItinerary(false);
    }

    return (
      <Modal
        isOpen={!!itinerary}
        style={modalStyle}
        onRequestClose={() => {
          this.exit(true);
        }}
      >
        <div className="manage-itinerary-popup">
          <div className="agent-header" style={{ border: "1px solid #d6d6d6" }}>
            <div />
            <CardTitle title="Itinerary update" />
            <div id="agent-exit-button">
              <IconButton styles={{ width: 2 }} onClick={() => this.exit(true)}>
                <ContentClear />
              </IconButton>
            </div>
          </div>
          {/* <div style={{ height: '10px' }}></div> */}

          <div
            style={{
              display: "block",
              width: "100% - 1px",
              paddingTop: "20px",
            }}
          >
            <div style={{ display: "inline-block", width: "50%" }}>
              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <DropDownPicker
                  dropdownvalue={this.state.startdate}
                  itemname={"Arrival"}
                  optionvalues={optionsMonth}
                  //value={(startDate, endDate)}
                  setSearchDates={(startDate) => {
                    this.setState({ startdate: startDate });
                    this.updateRate();
                  }}
                />
              </div>
              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <DropDownPicker
                  dropdownvalue={this.state.enddate}
                  itemname={"Departure"}
                  optionvalues={optionsMonth}
                  //value={(startDate, endDate)}
                  setSearchDates={(enddate) => {
                    this.setState({ enddate: enddate });
                    this.updateRate();
                  }}
                />
              </div>
              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <DropDownPicker
                  dropdownvalue={this.state.property}
                  itemname={"Property"}
                  optionvalues={this.state.propertyoptions}
                  //value={(startDate, endDate)}
                  setSearchDates={(property) => this.onChangeProperty(property)}
                />
              </div>
              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <DropDownPicker
                  dropdownvalue={
                    this.state.accom.includes("Camp Chalet")
                      ? "Camp Chalet"
                      : this.state.accom
                  }
                  itemname={"Accommodation"}
                  optionvalues={this.state.accommodationoptions}
                  //value={(startDate, endDate)}
                  setSearchDates={(accom) => {
                    this.onChangeAccommodation(accom);
                    this.updateRate();
                  }}
                />
              </div>
            </div>
            <div style={{ display: "inline-block", width: "50%" }}>
              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <DropDownPicker
                  dropdownvalue={this.state.adult}
                  itemname={"Adults"}
                  optionvalues={this.state.adultoptions}
                  //value={(startDate, endDate)}
                  setSearchDates={(adult) => {
                    this.state.rateGroups = this.state.rateGroups.map(
                      (item) => {
                        if (item.id == "RS2") {
                          let test = item;
                          test.number = adult;
                          return test;
                        } else {
                          return item;
                        }
                      }
                    );
                    this.setState({ adult: adult });
                    this.updateRate();
                  }}
                />
              </div>

              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <DropDownPicker
                  dropdownvalue={this.state.adultsingle}
                  itemname={"Adults Single"}
                  optionvalues={this.state.adultsingleoptions}
                  //value={(startDate, endDate)}
                  setSearchDates={(adultsingle) => {
                    this.state.rateGroups = this.state.rateGroups.map(
                      (item) => {
                        if (item.id == "WB3") {
                          let test = item;
                          test.number = adultsingle;
                          return test;
                        } else {
                          return item;
                        }
                      }
                    );
                    this.setState({ adultsingle: adultsingle });
                    this.updateRate();
                  }}
                />
              </div>

              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <DropDownPicker
                  dropdownvalue={this.state.child}
                  itemname={"Child"}
                  optionvalues={this.state.childoptions}
                  //value={(startDate, endDate)}
                  setSearchDates={(child) => {
                    this.state.rateGroups = this.state.rateGroups.map(
                      (item) => {
                        if (item.id == "RS3") {
                          let test = item;
                          test.number = child;
                          return test;
                        } else {
                          return item;
                        }
                      }
                    );
                    this.setState({ child: child });
                    this.updateRate();
                  }}
                />
              </div>

              <div
                className="search-dates"
                style={{
                  border: "1px solid #d6d6d6",
                  height: "60px",
                  padding: "5px",
                  margin: "5px",
                }}
              >
                <div
                  style={{ display: "block", width: 350, textAlign: "left" }}
                >
                  <div
                    style={{
                      display: "block",
                      width: 350,
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        width: 100,
                        paddingLeft: "10px",
                      }}
                    >
                      {"Amount:"}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: 150,
                        paddingLeft: "10px",
                      }}
                    >
                      <SpanWithLoading isLoading={!this.state.amount}>
                        <input
                          type="text"
                          value={this.state.amount}
                          onChange={(val) => {
                            this.onChangeInput(val);
                          }}
                        />
                      </SpanWithLoading>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "5px" }}></div>

          <div style={{ border: "1px solid #d6d6d6" }}>
            <RaisedButton
              label={
                <SpanWithLoading isLoading={this.state.loading} size="10">
                  Update
                </SpanWithLoading>
              }
              fullWidth={true}
              primary={true}
              onClick={() => {
                this.setState({ loading: true });
                this.props.updateItinerary(itinerary, this.state);
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ bookingManagement }) => {
  let error = false;
  if (bookingManagement.erroritinerary != undefined) {
    error = bookingManagement.erroritinerary;
  }

  if (bookingManagement.itinerary != undefined) {
    if (bookingManagement.rate != undefined) {
      return {
        itinerary: bookingManagement.itinerary,
        rate: bookingManagement.rate,
        erroritinerary: error,
      };
    }

    if (bookingManagement.successfullitinerary != undefined) {
      return {
        itinerary: bookingManagement.itinerary,
        successfullitinerary: bookingManagement.successfullitinerary,
        erroritinerary: error,
      };
    }

    return { itinerary: bookingManagement.itinerary, erroritinerary: error };
  } else {
    return { itinerary: undefined };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changesuccess: (val) => dispatch(updateSuccessfullItinerary(val)),
    exit: () => dispatch(deselectItinerary(undefined)),
    updateItinerary: (itinerary, newdata) =>
      dispatch(updateItinerary(itinerary, newdata)),
    setRate: (rateObj) => dispatch(startSetRateUpdate(rateObj)),
    setRateUpdate: (val) => dispatch(setRateUpdate(val)),
    errorUpdateItinerary: (val) => dispatch(errorUpdateItinerary(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
