import React from "react";
import { connect } from "react-redux";
import { CardTitle } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import { startLinkAgentToUser } from "./../../../actions/agentManagement";
import WithLoading from "./../../../hoc/WithLoading";
import ConfirmationModal from "../../../shared-components/ConfirmationModal";
class LinkConsultantToUser extends React.Component {
  state = {
    isLoading: false,
    errors: {},
    isModalOpen: false,
    isModalLoading: false,
  };

  onClick = () => {
    this.setState({ isLoading: true });

    this.props
      .setAgentLinked({
        agentName: this.props.agent.last_name,
        agentId: this.props.agent.id,
        consultantId: this.props.consultant.id,
        agentEmail: this.props.consultant.email,
      })
      .then(() => this.setState({ isLoading: false }))
      .catch((err) => {
        this.setState({
          isModalLoading: true,
          isModalOpen: true,
          isError: true,
          errors: err.error,
        });

        setTimeout(() => {
          this.setState({
            isModalLoading: false,
            isError: false,
            isModalOpen: false,
            isLoading: false,
          });
        }, 5000);
      });
  };

  render() {
    const { agent, consultant, isVisible } = this.props;
    return isVisible ? (
      <div className="booking-card">
        <CardTitle
          title={`3. Link Consultant to Existing User Account ${
            consultant && consultant.first_name
          } ${consultant && consultant.last_name} from ${
            agent && agent.last_name
          }.`}
        />
        <div className="generate-token-button">
          <FlatButtonWithLoading
            label="Link Now"
            fullWidth={true}
            autoFocus={true}
            onClick={this.onClick}
            isLoading={this.state.isLoading}
          />
        </div>
        <ConfirmationModal
          isOpen={this.state.isModalOpen}
          isError={this.state.isError}
          errorText={this.state.errors}
        />
      </div>
    ) : null;
  }
}

const FlatButtonWithLoading = WithLoading(FlatButton);

const mapStateToProps = ({ agentManagement }) => {
  const { agents, consultants, updatedUser } = agentManagement;
  return {
    agent: agents && agents.find((agent) => agent.selected),
    consultant:
      consultants && consultants.find((consultant) => consultant.selected),
    isVisible:
      agents &&
      agents.filter((agent) => agent.selected).length > 0 &&
      consultants &&
      consultants.filter((consultant) => consultant.selected).length > 0 &&
      updatedUser == undefined,
    updatedUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAgentLinked: ({ agentName, agentId, consultantId, agentEmail }) =>
      dispatch(
        startLinkAgentToUser({ agentName, agentId, consultantId, agentEmail })
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkConsultantToUser);
