import React from "react";
import { connect } from "react-redux";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import WithLoading from "./../../../hoc/WithLoading";
import { getTotal } from "./../../../functions/formatRate";
import formatRate from "./../../../functions/formatRate";
const SpanWithLoading = WithLoading(({ children }) => <span>{children}</span>);

const Total = ({ totalGross, totalCommission, totalNett, totalLevi }) => (
  <Table className="upper-table-totals">
    <TableBody displayRowCheckbox={false} className="table-totals">
      <TableRow className="total-client">
        <TableRowColumn className="totals-booking-modal">
          Total Payable by Client:{" "}
        </TableRowColumn>
        <TableRowColumn className="totals-booking-modal-value">
          <SpanWithLoading isLoading={!totalGross}>
            {totalGross != undefined
              ? formatRate(
                  Number(totalGross.replace(/,/g, "").replace(/ZAR/g, " ")) +
                    Number(totalLevi)
                )
              : 0}
          </SpanWithLoading>
        </TableRowColumn>
      </TableRow>
      <TableRow className="total-lodge">
        <TableRowColumn className="totals-booking-modal">
          Total Payable to Lodge:{" "}
        </TableRowColumn>
        <TableRowColumn
          isLoading={!totalNett}
          className="totals-booking-modal-value"
        >
          <SpanWithLoading>
            {totalNett != undefined
              ? formatRate(
                  Number(totalNett.replace(/,/g, "").replace(/ZAR/g, " ")) +
                    Number(totalLevi)
                )
              : 0}
          </SpanWithLoading>
        </TableRowColumn>
      </TableRow>
      <TableRow className="total-commission">
        <TableRowColumn className="totals-booking-modal">
          Total Commission:{" "}
        </TableRowColumn>
        <TableRowColumn className="totals-booking-modal-value">
          <SpanWithLoading
            isLoading={!totalCommission}
            className="totals-booking-modal-value"
          >
            {totalCommission}
          </SpanWithLoading>
        </TableRowColumn>
      </TableRow>
      <TableRow className="total-levy">
        <TableRowColumn className="totals-booking-modal">
          Total Sabi Sands Levy:{" "}
        </TableRowColumn>
        <TableRowColumn className="totals-booking-modal-value">
          <SpanWithLoading
            isLoading={!totalCommission}
            className="totals-booking-modal-value"
          >
            {formatRate(totalLevi)}
          </SpanWithLoading>
        </TableRowColumn>
      </TableRow>
    </TableBody>
  </Table>
);

const mapStateToProps = ({ booking }) => {
  const stagedLineItems = booking.lineItems.filter(
    (lineItem) => !lineItem.confirmed
  );

  return {
    totalGross: getTotal(stagedLineItems, "gross", true),
    totalNett: getTotal(stagedLineItems, "nett", true),
    totalCommission: getTotal(stagedLineItems, "comm", true),
    totalLevi: stagedLineItems
      .map((lineItem) => lineItem.levy)
      .reduce((a, b) => a + b),
  };
};

export default connect(mapStateToProps)(Total);
