import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import IconButton from "material-ui/IconButton";
import ContentClear from "material-ui/svg-icons/content/clear";
import RaisedButton from "material-ui/FlatButton";
import update from "immutability-helper";
import {
  bookingchange,
  deselectBooking,
  cancelBooking,
} from "./../../../../actions/bookingManagement";
import { Redirect } from "react-router-dom";

let aa = false;
let updateType;

const modalStyle = {
  content: {
    outline: "none",
    backgroundColor: "white",
    width: "40rem",
    height: "20rem",
    border: "0.1rem solid #888888",
    boxShadow: "0.5rem 1rem 1rem #888888",
    margin: "auto",
    textAlign: "center",
    paddingTop: "60px",
  },
};

Modal.setAppElement("#app");

class LoaderBookingUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      redirect: false,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalRedirect = this.handleCloseModalRedirect.bind(this);
  }

  handleCloseModal = () => {
    if (aa == true) {
      this.props.bookingchange(false);
    }
    aa = false;
    this.setState({ showModal: true });
  };

  handleCloseModalRedirect = () => {
    if (aa == true) {
      this.props.bookingchange(false);
    }
    aa = false;
    this.setState({ redirect: true, showModal: true });
  };

  render() {
    if (this.props.updateType != undefined) {
      updateType = this.props.updateType;
    }

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/bookingcalendar",
          }}
        />
      );
    }

    return (
      <div>
        <Modal
          isOpen={aa}
          style={modalStyle}
          ariaHideApp={true}
          onRequestClose={this.handleCloseModal}
        >
          <div className="manage-itinerary-popup">
            <p style={{ fontSize: "18px" }}>
              To be able to change a specifications, this booking will be
              cancelled and a new one will be created.
              <br />
            </p>

            <RaisedButton
              label="Create new booking"
              fullWidth={false}
              primary={true}
              onClick={() => {
                this.props.updateBooking(
                  "Quotation",
                  this.props.bookingDetails
                );
                this.props.exit(this.props.bookingDetails._id);
                this.handleCloseModalRedirect();
              }}
            />
            <RaisedButton
              label="Close"
              fullWidth={false}
              primary={true}
              onClick={() => {
                this.handleCloseModal();
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ bookingManagement }) => {
  if (bookingManagement.changepopup) {
    aa = true;
  }

  let bookingDetails =
    bookingManagement.bookings &&
    bookingManagement.bookings.find((booking) => booking.selected === true);

  return {
    bookingDetails,
    changepopup: bookingManagement.changepopup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exit: (id) => dispatch(deselectBooking(id)),
    bookingchange: (val) => dispatch(bookingchange(val)),
    updateBooking: (updateType, booking) =>
      dispatch(cancelBooking(updateType, booking)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderBookingUpdate);
