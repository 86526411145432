const defaultUser = {
  email: "",
  role: "",
};

export default (state = defaultUser, action) => {
  switch (action.type) {
    case "SET_USER_DETAILS": {
      const { type, ...userDetails } = action;
      return {
        ...state,
        ...userDetails,
      };
    }
    default:
      return state;
  }
};
