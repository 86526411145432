import React from "react";
import CircularProgress from "material-ui/CircularProgress";

const WithLoading = (Component) => {
  return ({ isLoading, ...props }) => {
    return isLoading ? (
      <CircularProgress size={35} />
    ) : (
      <Component {...props} />
    );
  };
};

export default WithLoading;
