import React from "react";
import { getTotal } from "./../../../functions/formatRate";
import formatRate from "./../../../functions/formatRate";
import { CardTitle, CardText } from "material-ui/Card";
import AccomHeader from "./AccomHeader";
import AccomLine from "./AccomLine";
import AccomLineTotal from "./AccomLineTotal";

const AccomTable = ({ lineItemGroup }) => {
  // console.log("lineItemGroup --> ", lineItemGroup)
  const { propertyName, accomName, rateGroups } = lineItemGroup[0];
  const totalCommission = getTotal(lineItemGroup, "comm");
  const totalRate = getTotal(lineItemGroup, "gross");
  const totalNett = getTotal(lineItemGroup, "nett");
  const totalLevi = lineItemGroup
    .map((lineItem) => lineItem.levy)
    .reduce((a, b) => a + b);
  const blankColCount = 4 + rateGroups.length;

  return (
    <div>
      <CardTitle
        title={`${propertyName} - ${
          accomName.includes("Camp Chalet") ? "Camp Chalet" : accomName
        }`}
      />
      <table className="itinerary-table">
        <thead className="Itinerary__header">
          <AccomHeader rateGroups={rateGroups} />
        </thead>
        <tbody className="Itinerary__body">
          {lineItemGroup.map((lineItem, i) => (
            <AccomLine key={i} refId={i + 1} lineItem={lineItem} />
          ))}
          <AccomLineTotal
            blankColCount={blankColCount}
            commission={totalCommission}
            rate={formatRate(
              Number(totalRate.replace(/,/g, "").replace(/ZAR/g, " ")) +
                Number(totalLevi)
            )}
            nett={formatRate(
              Number(totalNett.replace(/,/g, "").replace(/ZAR/g, " ")) +
                Number(totalLevi)
            )}
            levy={formatRate(totalLevi)}
          />
        </tbody>
      </table>
    </div>
  );
};

export default AccomTable;
