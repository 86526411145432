import React from "react";

const maxCount = 20;

const SearchResults = ({ isSearched, resultCount }) => (
  <div>
    {isSearched && (
      <div>{`${resultCount} match${resultCount == 1 ? "" : "es"}`}</div>
    )}
    {resultCount > maxCount && <div>Only displaying the first {maxCount}.</div>}
  </div>
);

export default SearchResults;
