import React from "react";
import AgentTable from "./AgentTable";
import AgentModal from "./AgentModal";
import WithRoleCheck from "../../hoc/WithRoleCheck";
import ManageExternalBookings from "../Dashboard/ManageExternalBookings/index";

class LodgeAdmin extends React.Component {
  render() {
    return (
      <div>
        <div style={{ height: "110px" }}></div>
        <div id="view-agents-info">
          <AgentTable />
          <AgentModal />
        </div>
      </div>
    );
  }
}
const LodgeAdminWithRoleCheck = WithRoleCheck(LodgeAdmin);
const ManageExternalBookingsWithRoleCheck = WithRoleCheck(
  ManageExternalBookings
);

export default LodgeAdmin;
