import React from "react";
import { connect } from "react-redux";
import { Card, CardTitle } from "material-ui/Card";
import RaisedButton from "material-ui/FlatButton";
import ContentClear from "material-ui/svg-icons/content/clear";
import IconButton from "material-ui/IconButton";
import Modal from "react-modal";
import {
  deselectBooking,
  updateBooking,
  updateSuccessfull,
  bookingchange,
} from "./../../../../actions/bookingManagement";
import ReactTable from "react-table";
import LoaderBookingUpdate from "./../ConfirmedUpdate/index";
import "react-table/react-table.css";
import TextField from "material-ui/TextField";
import NotesSection from "./NotesSection";
import { DropDown } from "./../../../../shared-components/DropDown";
import WithLoading from "./../../../../hoc/WithLoading";
import formatRate from "./../../../../functions/formatRate";
const Span = ({ children }) => (
  <span style={{ height: "100vh" }}>{children}</span>
);
const SpanWithLoading = WithLoading(Span);

const moment = require("moment");

const modalStyle = {
  content: {
    outline: "none",
    backgroundColor: "white",
    width: "85%",
    border: "0.1rem solid #888888",
    boxShadow: "0.5rem 1rem 1rem #888888",
    margin: "auto",
    textAlign: "center",
    top: "115px",
  },
};

Modal.setAppElement("#app");
let optionsarray = [];

class BookingModal extends React.Component {
  state = {
    generalNotes: "",
    guestNotes: "",
    internalMemo: "",
    updateType: "",
    nationality: "",
    loading: false,
  };

  updateTextField = (myState) => (e) => {
    const notes = e.target.value;
    if (myState == "generalNotes") {
      this.props.bookingDetails.generalNotes = notes;
    } else if (myState == "guestNotes") {
      this.props.bookingDetails.guestNotes = notes;
    } else if (myState == "internalMemo") {
      this.props.bookingDetails.internalMemo = notes;
    }
    this.setState({ [myState]: notes });
  };

  changeNationality = (v) => {
    this.props.bookingDetails.nationality = v;
    this.setState({ nationality: v });
  };

  changeDropdown = (value) => {
    this.setState({ updateType: value });
  };

  render() {
    const { bookingDetails } = this.props;

    let {
      bookingDetails2,
      bookingDetailsID,
      bookingDetailsName,
      guestNotes,
      generalNotes,
      internalMemo,
      status,
      nationality,
    } = [];
    if (bookingDetails != undefined) {
      bookingDetailsID = this.props.bookingDetails._id;
      bookingDetailsName = this.props.bookingDetails.bookingName;
      bookingDetails2 = this.props.bookingDetails.LF_Itinerary[0];
      guestNotes = this.props.bookingDetails.guestNotes;
      generalNotes = this.props.bookingDetails.generalNotes;
      internalMemo = this.props.bookingDetails.internalMemo;
      nationality = this.props.bookingDetails.nationality;

      status = this.props.bookingDetails.status;
      this.state.generalNotes = generalNotes;
      this.state.guestNotes = guestNotes;
      this.state.internalMemo = internalMemo;
      this.state.nationality = nationality;

      if (this.state.updateType == "") {
        this.state.updateType = status[0];
      }

      if (status == "Provisional") {
        optionsarray = ["Update booking", "Cancel booking"];
      } else {
        optionsarray = ["Update booking"];
      }

      if (this.props.success == true) {
        this.setState({ loading: false, updateType: "" });
        this.props.exit(bookingDetails._id);
        this.props.changesuccess(false);
      }
    } else {
    }

    return (
      <Modal
        isOpen={!!bookingDetails}
        style={modalStyle}
        onRequestClose={() => {
          this.props.exit(bookingDetails._id);
          this.setState({ loading: false, updateType: "" });
        }}
      >
        <div className="manage-booking-popup">
          <div
            className="agent-header"
            style={{ border: "1px solid #d6d6d6", width: "100%" }}
          >
            <div />
            <CardTitle title="Booking Info" />
            <div id="agent-exit-button">
              <IconButton
                styles={{ width: 2 }}
                onClick={() => this.props.exit(bookingDetails._id)}
              >
                <ContentClear />
              </IconButton>
            </div>
          </div>
          {/* <Card> */}
          <div style={{ height: "10px" }}></div>
          <div
            style={{
              display: "inline-block",
              width: "100%",
              float: "center",
              paddingBottom: "20px",
              border: "1px solid #d6d6d6",
            }}
          >
            <div
              style={{
                width: "60%",
                display: "inline-block",
                textAlign: "left",
                float: "left",
                paddingLeft: "15px",
              }}
            >
              <h3> Reservation number: </h3> {bookingDetailsID}
              <h3> Booking name: </h3> {bookingDetailsName}
            </div>
            <div
              style={{
                width: "20%",
                display: "inline-block",
                textAlign: "left",
                float: "right",
              }}
            >
              <h3> Status: </h3> {status}
            </div>
          </div>
          <div style={{ height: "10px" }}></div>
          {/* </Card> */}

          <ReactTable
            data={bookingDetails2}
            columns={[
              {
                columns: [
                  {
                    Header: "Arrival",
                    accessor: "arrival",
                  },
                  {
                    Header: "Departure",
                    accessor: "departure",
                  },
                  {
                    Header: "Property",
                    accessor: "propertyName",
                  },
                  {
                    Header: "Accommodation",
                    accessor: "accomName",
                  },
                  {
                    Header: "Nights",
                    accessor: "nights",
                  },
                  {
                    Header: "Adult",
                    accessor: "adult",
                  },
                  {
                    Header: "Adult Single",
                    accessor: "adultsingle",
                  },
                  {
                    Header: "Child",
                    accessor: "child",
                  },
                  {
                    Header: "Levy",
                    Cell: (c) => <span>{formatRate(c.original.levy)}</span>,
                    Footer: (
                      <span>
                        <strong>
                          {
                            // Get the total of the price
                            bookingDetails2 != undefined
                              ? formatRate(
                                  bookingDetails2.reduce(
                                    (total, { levy }) => (total += levy),
                                    0
                                  )
                                )
                              : formatRate(0)
                          }
                        </strong>
                      </span>
                    ),
                  },
                  {
                    Header: "Commission",
                    Cell: (c) => <span>{formatRate(c.original.comm)}</span>,
                    Footer: (
                      <span>
                        <strong>
                          {
                            // Get the total of the price
                            bookingDetails2 != undefined
                              ? formatRate(
                                  bookingDetails2.reduce(
                                    (total, { comm }) => (total += comm),
                                    0
                                  )
                                )
                              : formatRate(0)
                          }
                        </strong>
                      </span>
                    ),
                  },
                  {
                    Header: "Total",

                    Cell: (c) => <span>{formatRate(c.original.amount)}</span>,
                    Footer: (
                      <span>
                        <strong>
                          {
                            // Get the total of the price
                            bookingDetails2 != undefined
                              ? formatRate(
                                  bookingDetails2.reduce(
                                    (total, { amount }) => (total += amount),
                                    0
                                  )
                                )
                              : formatRate(0)
                          }
                        </strong>
                      </span>
                    ),
                  },
                ],
              },
            ]}
            //defaultPageSize={20}
            //filterable
            showPagination={false}
            showPageSizeOptions={false}
            minRows={0}
            className="-striped -highlight"
            getTrProps={(state, rowInfo) => {
              return {
                onClick: () => {
                  this.props.bookingchange(true);
                },
                style: { cursor: "pointer" },
              };
            }}
            onSortedChange={(c, s) => {
              document.activeElement.blur();
            }}
          />

          <Card style={{ textAlign: "left", paddingLeft: "15px" }}>
            <NotesSection
              name="General Notes"
              text={generalNotes}
              myState={"generalNotes"}
              updateTextField={this.updateTextField}
            />
            <NotesSection
              name="Internal Memo"
              text={internalMemo}
              myState={"internalMemo"}
              updateTextField={this.updateTextField}
            />
            <NotesSection
              name="Guest Info"
              text={guestNotes}
              myState={"guestNotes"}
              updateTextField={this.updateTextField}
            />

            <DropDown
              floatingLabelText={"Nationality"}
              onChange={(e, i, v) => this.changeNationality(v)}
              items={[
                "Qatar",
                "Costa Rica",
                "Bulgaria",
                "Seychelles",
                "Iran",
                "Paraquay",
                "Uruguay",
                "Argentina",
                "Ireland",
                "Israel",
                "Italy",
                "Japan",
                "Kenya",
                "Lesotho",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Australia",
                "Mauritius",
                "Austria",
                "Mozambique",
                "Namibia",
                "Netherlands",
                "New Zealand",
                "Bahamas",
                "Norway",
                "Bangladesh",
                "Barbados",
                "South Africa",
                "Spain",
                "Sri Lanka",
                "Swaziland",
                "Sweden",
                "Switzerland",
                "Tanzania",
                "Belgium",
                "Belize",
                "Uganda",
                "United Kingdom",
                "United States",
                "Zambia",
                "Zimbabwe",
                "Hong Kong",
                "Russia",
                "Bermuda",
                "Cayman Island",
                "Greece",
                "Portugal",
                "Turkey",
                "Estonia",
                "Lativa",
                "Venezuela",
                "Czech Republic",
                "South Korea",
                "Mixed",
                "Saudi Arabia",
                "Columbian",
                "Thailand",
                "Jordan",
                "Singapore",
                "Kuwait",
                "Ghana",
                "Lebanon",
                "Scotland",
                "Luxembourg",
                "Phillipines",
                "Panama",
                "Poland",
                "Ethopia",
                "Zululand",
                "Hungary",
                "Equatorial Guinea",
                "Morocco",
                "Cypris",
                "South America",
                "West Indies",
                "Maltese",
                "Hawaii",
                "Puerto Rico",
                "Asia",
                "Oman",
                "Ukrane",
                "Texas",
                "Indonesia",
                "Serbia",
                "Botswana",
                "Cameroon",
                "Monaco",
                "Brazil",
                "Burundi",
                "Canada",
                "China",
                "Congo",
                "Cuba",
                "Denmark",
                "Angola",
                "Egypt",
                "Finland",
                "France",
                "Germany",
                "Antigua and Barbuda",
                "India",
                "United Arab Emerites",
                "Mexico",
                "Lithuania",
                "Nigeria",
                "Chili",
                "Peru",
                "Pakistan",
                "Scandinavia",
                "Croatia",
                "Slovakia",
                "Korea (Republic Of)",
              ].sort()}
              selectedItem={nationality}
              style={{ maxWidth: 300, marginBottom: "20px", textAlign: "left" }}
            />
          </Card>

          <Card className="bookingdetail2-card">
            <DropDown
              floatingLabelText={"Change status"}
              onChange={(e, i, v) => this.changeDropdown(v)}
              items={optionsarray}
              selectedItem={this.state.updateType}
              style={{ maxWidth: 200, textAlign: "left" }}
            />

            <RaisedButton
              label={
                <SpanWithLoading isLoading={this.state.loading} size="10">
                  Update
                </SpanWithLoading>
              }
              fullWidth={false}
              primary={true}
              onClick={() => {
                this.setState({ loading: true });
                this.props.updateBooking(
                  this.state.updateType == "Cancel booking"
                    ? "Quotation"
                    : this.state.updateType,
                  bookingDetails
                );
              }}
            />
          </Card>
          <LoaderBookingUpdate updateType={this.state.updateType} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ bookingManagement }) => {
  const { lineItems, lodge } = bookingManagement;
  let bookingDetails =
    bookingManagement.bookings &&
    bookingManagement.bookings.find((booking) => booking.selected === true);

  if (bookingDetails != undefined) {
    let modifiedBookingDetail = bookingDetails.LF_Itinerary[0].map((item) => {
      let levy = item.levy != undefined ? item.levy : 0;
      item = {
        ...item,
        adult:
          item.rateGroups.find((rateGroup) => rateGroup.id === "RS2") !=
          undefined
            ? item.rateGroups.find((rateGroup) => rateGroup.id === "RS2").number
            : "0",
        child:
          item.rateGroups.find((rateGroup) => rateGroup.id === "RS3") !=
          undefined
            ? item.rateGroups.find((rateGroup) => rateGroup.id === "RS3").number
            : "0",
        adultsingle:
          item.rateGroups.find((rateGroup) => rateGroup.id === "WB3") !=
          undefined
            ? item.rateGroups.find((rateGroup) => rateGroup.id === "WB3").number
            : "0",
        amount: item.rate.gross + levy,
        levy: levy,
        comm: item.rate.comm,
      };
      return {
        ...item,
        nights: moment(item.departure).diff(moment(item.arrival), "days"),
        lineItems,
        lodge,
      };
    });

    bookingDetails.LF_Itinerary[0] = modifiedBookingDetail;
  }

  let success = false;

  if (bookingManagement.successfull != undefined) {
    success = bookingManagement.successfull;
  }

  return { bookingDetails, success };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changesuccess: (val) => dispatch(updateSuccessfull(val)),
    exit: (id) => dispatch(deselectBooking(id)),
    updateBooking: (updateType, booking) =>
      dispatch(updateBooking(updateType, booking)),
    bookingchange: (val) => dispatch(bookingchange(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
