import React from "react";
import BookingTable from "./BookingTable/index";
import BookingModal from "./BookingModal/index";
import LoaderBookingUpdate from "./ConfirmedUpdate/index";
import BookingChange from "./BookingChange/index";

const ManageBookingPage = () => {
  return (
    <div id="view-manage-bookings">
      <BookingTable />
      <BookingModal />
      <LoaderBookingUpdate />
      <BookingChange />
    </div>
  );
};

export default ManageBookingPage;
