import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter,
  withRouter,
  Link,
  Switched,
  Route,
} from "react-router-dom";
import MenuItem from "material-ui/MenuItem";
import Dashboard from "material-ui/svg-icons/action/dashboard";
import Calendar from "@material-ui/icons/CalendarToday";
import Assignment from "material-ui/svg-icons/action/assignment";
import Settings from "material-ui/svg-icons/action/settings";
import WithRoleCheck from "./../hoc/WithRoleCheck";

import Map from "material-ui/svg-icons/maps/map";
import Rates from "material-ui/svg-icons/editor/monetization-on";
import Gallery from "material-ui/svg-icons/image/image";
import News from "material-ui/svg-icons/communication/rss-feed";
import Impact from "material-ui/svg-icons/image/nature-people";
import Experience from "material-ui/svg-icons/action/pets";

import ManageUsers from "material-ui/svg-icons/social/people";
import Add from "material-ui/svg-icons/action/note-add";
import Details from "material-ui/svg-icons/image/details";
import { userLogout } from "./../actions/root";
import SvgIcon from "@material-ui/core/SvgIcon";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HamburgerMenu from "react-hamburger-menu";
import Login from "./../components/Login/index";
import MenuProfile from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import ManageAgentsPage from "../components/ManageAgentsPage";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

const MenuItemWithRoleCheck = WithRoleCheck(MenuItem);

class SideBar extends React.Component {
  state = {
    menuOpen: false,
    slideMenuActive: false,
    openHamburger: false,
    auth: true,
    anchorEl: null,
  };

  handleChange = (event) => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick() {
    this.setState({
      openHamburger: !this.state.openHamburger,
      menuOpen: !this.state.menuOpen,
    });
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({
      menuOpen: false,
      openHamburger: false,
    });
  }

  signOut() {
    this.props.onLogOut();
    this.props.userLogout();
  }

  render() {
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <div className="headercontent">
          <div className="header-inner">
            <div className="lion-logo">
              <Link to={"/"}>
                <img
                  src="https://blog.londolozi.com/wp-content/themes/londolozi/img/londolozi-logo-img-small.gif"
                  alt="logo"
                  className="img-responsive"
                ></img>
              </Link>
              <div className="logo-text">
                <a>
                  <h1 className="site-title">
                    <span className="logo-l outside-letters">L</span>
                    <span className="inside-letters">ondoloz</span>
                    <span className="logo-i outside-letters">I</span>
                  </h1>
                </a>
                <div className="iconhover">
                  <Link className="iconhover" to={"/"}>
                    <SvgIcon className="iconhover" style={{ fontSize: 30 }}>
                      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                    </SvgIcon>
                  </Link>
                </div>
                <h2 className="site-subtitle">Bookings</h2>
              </div>
            </div>
            <div
              className={
                !this.state.openHamburger ? "menu-button" : "menu-button-active"
              }
            >
              <HamburgerMenu
                isOpen={this.state.openHamburger}
                menuClicked={() => {
                  this.handleClick();
                }}
                width={18}
                height={15}
                strokeWidth={1}
                rotate={0}
                color="black"
                borderRadius={0}
                animationDuration={0.5}
              />
            </div>
            <div className="profile-button">
              <div>
                <IconButton onClick={this.handleMenu} color="inherit">
                  <AccountCircle />
                </IconButton>
                <MenuProfile
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      this.signOut();
                    }}
                    containerElement={<Link to="/login" />}
                  >
                    Logout
                  </MenuItem>
                </MenuProfile>
              </div>
            </div>
          </div>
        </div>
        <div className="page-layout">
          <div>
            <SwipeableDrawer
              anchor={"right"}
              open={this.state.menuOpen}
              onClose={() => this.closeMenu()}
              onOpen={() => this.handleClick()}
            >
              <MenuItemWithRoleCheck
                className="menuitem"
                containerElement={<Link to="/" />}
                primaryText="Dashboard"
                leftIcon={<Dashboard />}
                onClick={() => this.closeMenu()}
              />

              <MenuItemWithRoleCheck
                className="menuitem"
                containerElement={<Link to="/new-booking" />}
                primaryText="Make a booking"
                leftIcon={<Details />}
                onClick={() => this.closeMenu()}
              />

              <MenuItemWithRoleCheck
                className="menuitem"
                containerElement={<Link to="/booking-calendar" />}
                primaryText="Booking calendar"
                leftIcon={<Calendar />}
                onClick={() => this.closeMenu()}
              />

              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Manage Bookings"
                containerElement={<Link to="/booking_management" />}
                leftIcon={<Assignment />}
                requiredRole="lodgeAdmin"
                userRole={this.props.role}
                selected={true}
                onClick={() => this.closeMenu()}
              />

              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Edit Lodge Display"
                containerElement={<Link to="/lodgeoptions" />}
                leftIcon={<Settings />}
                requiredRole="lodgeAdmin"
                userRole={this.props.role}
                onClick={() => this.closeMenu()}
              />

              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Add Agents"
                containerElement={<Link to="/agent_management" />}
                leftIcon={<Add />}
                requiredRole="lodgeAdmin"
                userRole={this.props.role}
                selected={true}
                onClick={() => this.closeMenu()}
              />

              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Manage Agents"
                containerElement={<Link to="/manage_agents" />}
                leftIcon={<ManageUsers />}
                requiredRole="lodgeAdmin"
                userRole={this.props.role}
                selected={true}
                onClick={() => this.closeMenu()}
              />

              {/* All Trade information Links */}
              <hr />
              <Link to={"/trade/dashboard"}>
                <h2 style={headingStyles}>Trade Information</h2>
              </Link>
              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Camp Information"
                containerElement={<Link to="/trade/campInfo" />}
                leftIcon={<Map />}
                selected={true}
                onClick={() => this.closeMenu()}
              />
              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Impact & Sustainability"
                containerElement={<Link to="/trade/impact" />}
                leftIcon={<Impact />}
                selected={true}
                onClick={() => this.closeMenu()}
              />
              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Latest Updates"
                containerElement={<Link to="/trade/latestUpdates" />}
                leftIcon={<News />}
                selected={true}
                onClick={() => this.closeMenu()}
              />
              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Londolozi Experience"
                containerElement={<Link to="/trade/londoloziExperience" />}
                leftIcon={<Experience />}
                selected={true}
                onClick={() => this.closeMenu()}
              />
              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Gallery and Media"
                containerElement={<Link to="/trade/mediaGallery" />}
                leftIcon={<Gallery />}
                selected={true}
                onClick={() => this.closeMenu()}
              />
              <MenuItemWithRoleCheck
                className="menuitem"
                primaryText="Rates, Information and Policies"
                containerElement={<Link to="/trade/rates" />}
                leftIcon={<Rates />}
                selected={true}
                onClick={() => this.closeMenu()}
              />
            </SwipeableDrawer>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
  };
};

const headingStyles = {
  marginBottom: "0px",
  padding: "10px 20px",
  textAlign: "left",
};

export default withRouter(connect(undefined, mapDispatchToProps)(SideBar));
