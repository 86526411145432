import React from "react";

const DateRow = ({ dates, format }) => {
  return (
    <tr>
      {dates.map((date, i) => (
        <td className="day weekday-name" key={i}>
          {date.format(format)}
        </td>
      ))}
    </tr>
  );
};

export default DateRow;
