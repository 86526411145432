import React from "react";
import { connect } from "react-redux";
import { selectAgent } from "./../../../actions/agentManagement";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

const AgentTable = ({ handleSelection, agents }) => {
  return (
    <Table
      onRowSelection={([rowIndex]) => {
        if (rowIndex !== undefined) {
          handleSelection(rowIndex);
        }
      }}
      height="200px"
    >
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>Business</TableHeaderColumn>
          <TableHeaderColumn>Email</TableHeaderColumn>
          <TableHeaderColumn>Physical Address</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody deselectOnClickaway={false}>
        {agents.map(({ business, email, address, selected }, i) => {
          return (
            <TableRow selected={selected} key={i}>
              <TableRowColumn>{business}</TableRowColumn>
              <TableRowColumn>{email}</TableRowColumn>
              <TableRowColumn>{address}</TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = ({ agentManagement }) => {
  return {
    agents: agentManagement.agents.slice(0, 19).map((agent) => {
      return {
        business: agent.last_name,
        email: agent.email,
        address: `${agent.phys_city}${agent.phys_city && ","} ${
          agent.phys_country_name
        }`,
        selected: agent.selected,
      };
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSelection: (rowIndex) => dispatch(selectAgent(rowIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentTable);
