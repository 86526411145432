import React from "react";
import BookingTable from "./BookingTable/index";
import BookingModal from "./BookingModal/index";
import LoaderBookingUpdate from "./ConfirmedUpdate/index";
import ItineraryUpdate from "./ItineraryUpdate/index";
import ItineraryFailedUpdate from "./ItineraryFailedUpdate/index";

const ManageBookingPage = () => {
  return (
    <div>
      <div style={{ height: "110px" }}></div>
      <div id="view-manage-bookings">
        <BookingTable />
        <BookingModal />
        <LoaderBookingUpdate />
        <ItineraryUpdate />
        <ItineraryFailedUpdate />
      </div>
    </div>
  );
};

export default ManageBookingPage;
