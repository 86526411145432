import React from "react";
import { Redirect } from "react-router-dom";
import fetch from "node-fetch";
import SignUpForm from "./SignUpForm";
import validateSignupForm from "../../functions/validateSignupForm";
import lodgeForce from "./../../wrappers/LodgeForceAPI";
export default class Signup extends React.Component {
  state = {
    redirect: false,
    errors: {},
    user: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      password: "",
    },
    isPassVisible: false,
    isLoading: false,
  };

  changeUser = (event) => {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;

    this.setState(() => ({ user }));
  };
  toggleVisiblity = () => {
    this.setState(({ isPassVisible }) => {
      return {
        isPassVisible: !isPassVisible,
      };
    });
  };
  processForm = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { email, password, firstName, lastName, company } = this.state.user;
    const { errors, success } = validateSignupForm(this.state.user);
    this.setState(() => ({ errors }));
    const { hostname } = window.location;
    if (success) {
      localStorage.clear();
      fetch("/users", {
        method: "POST",
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          lodgeAccess: [],
          role: "externalAgent",
          hostname,
          company,
        }),
        headers: { "content-type": "application/json" },
      })
        .then((res) => {
          if (res.status === 400) {
            this.setState(() => ({
              isLoading: false,
              errors: {
                email:
                  "Something went wrong. This email address may already be in use.",
              },
            }));
            throw "Username taken";
          }

          this.setState(() => ({
            redirect: true,
          }));
        })
        .catch((err) => err);
    } else {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <SignUpForm
        onSubmit={this.processForm}
        onChange={this.changeUser}
        errors={this.state.errors}
        user={this.state.user}
        toggle={this.toggleVisiblity}
        isPassVisible={this.state.isPassVisible}
        isLoading={this.state.isLoading}
      />
    );
  }
}
