import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { startRemoveStagedLineItems } from "./../../../actions/booking";
import { CardTitle } from "material-ui/Card";
import RoomConfig from "./RoomConfig";
import Total from "./Total";
import Header from "./Header";
import AddRoom from "./AddRoom";
import Confirm from "./Confirm";
import ModalPolicy from "./ModalPolicy";

import Treecamp_img7 from "../../../assets/images/londo_treecamp9.jpeg";
import VartyChaletcamp_img14 from "../../../assets/images/may16_varty4.jpeg";
import PrivateGraniteSuites_img17 from "../../../assets/images/2018.granite9-min.jpg";
import Founderscamp_img2 from "../../../assets/images/founders-header.jpg";
import Pioneercamp_img4 from "../../../assets/images/living7-min.jpg";
import General1 from "../../../assets/images/General Daytrippers-min.jpg";
import General2 from "../../../assets/images/General Pilot Rooms-min.jpg";
import General3 from "../../../assets/images/Landrovers Honeymoon-min.jpg";
import General4 from "../../../assets/images/Landrovers Private-min.jpg";
import General5 from "../../../assets/images/Lodge Internal Accounts-min.jpg";
import General6 from "../../../assets/images/Lodge Staff Accounts.jpg";

const modalStyle = {
  content: {
    outline: "none",
    backgroundColor: "white",
    width: "100rem",
    border: "0.1rem solid #888888",
    boxShadow: "0.5rem 1rem 1rem #888888",
    margin: "auto",
    textAlign: "center",
    top: "115px",
  },
};

Modal.setAppElement("#app");

class BookingModal extends React.Component {
  handleCancel = (e) => {
    e.preventDefault();
    this.props.removeStagedLineItems();
  };

  campnamecleaned = (property) => {
    switch (property) {
      case "Founders Camp":
        return "founders-camp";
      case "Pioneer Camp":
        return "pioneer-camp";
      case "Private Granite Suites":
        return "private-granite-suites";
      case "Tree Camp":
        return "tree-camp";
      case "Varty Camp":
        return "varty-camp";
      case "General":
        return "general";
      case "Landrovers":
        return "landrovers";
      case "Lodge: Miscellaneous":
        return "lodge-miscellaneous";
    }
  };

  imageMap(propertyNameClicked, campNameClicked) {
    let imageCamp;

    if (propertyNameClicked === "Tree Camp") {
      imageCamp = Treecamp_img7;
    } else if (propertyNameClicked === "Varty Camp") {
      imageCamp = VartyChaletcamp_img14;
    } else if (propertyNameClicked === "Private Granite Suites") {
      imageCamp = PrivateGraniteSuites_img17;
    } else if (propertyNameClicked === "Founders Camp") {
      imageCamp = Founderscamp_img2;
    } else if (propertyNameClicked === "Pioneer Camp") {
      imageCamp = Pioneercamp_img4;
    } else if (propertyNameClicked === "General") {
      if (campNameClicked.includes("Day Trippers")) {
        imageCamp = General1;
      } else if (campNameClicked.includes("Pilot")) {
        imageCamp = General2;
      } else {
        imageCamp = General1;
      }
    } else if (propertyNameClicked === "Landrovers") {
      if (campNameClicked.includes("Honeymoon")) {
        imageCamp = General3;
      } else if (campNameClicked.includes("Private")) {
        imageCamp = General4;
      }
    } else if (propertyNameClicked === "Lodge: Miscellaneous") {
      if (campNameClicked.includes("Internal")) {
        imageCamp = General5;
      } else if (campNameClicked.includes("Staff")) {
        imageCamp = General6;
      }
    }

    return imageCamp;
  }

  render() {
    let propertyNameClicked;
    let campNameClicked;

    if (this.props.stagedLineItemsWithWarnings.length > 0) {
      propertyNameClicked = this.props.stagedLineItemsWithWarnings[0]
        .propertyName;
      campNameClicked = this.props.stagedLineItemsWithWarnings[0].accomName;
    }

    const imageCamp = this.imageMap(propertyNameClicked, campNameClicked);

    return (
      <Modal
        isOpen={this.props.stagedLineItemsWithWarnings.length > 0}
        style={modalStyle}
        onRequestClose={this.handleCancel}
        ariaHideApp={true}
      >
        <div
          className="modal-camp"
          data-name={this.campnamecleaned(propertyNameClicked)}
        >
          <div className="topimagecontainer">
            <img className="topimage" src={imageCamp} />
            <button className="closemodalimage" onClick={this.handleCancel}>
              X
            </button>
          </div>
          <div className="booking-form">
            <Header />
            <ModalPolicy />
            <CardTitle className="bookingmodalheaders" title="Room Selection" />
            <div className="rooms">
              {this.props.stagedLineItemsWithWarnings.map((lineItem, i) => (
                <RoomConfig
                  key={i}
                  configNumber={i + 1}
                  lodge={this.props.lodge}
                  {...lineItem}
                />
              ))}
            </div>

            <AddRoom />
            <Total />

            <Confirm
              badGuestSelection={this.props.badGuestSelection}
              className="confirm-button"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ booking, bookingFilters }) => {
  const stagedLineItems = booking.lineItems.filter(
    (lineItem) => lineItem.confirmed !== true
  );

  const stagedLineItemsWithWarnings = stagedLineItems.map((lineItem) => {
    const { rateGroups } = lineItem;
    let isOverCapacity = false;

    for (let i = 0; i < rateGroups.length; i++) {
      if (parseInt(rateGroups[i].number) > parseInt(rateGroups[i].maxNumber)) {
        isOverCapacity = true;
        break;
      }
    }

    const totalGuests = rateGroups
      .map(({ number }) => number)
      .reduce((a, b) => parseInt(a) + parseInt(b));

    isOverCapacity =
      isOverCapacity || totalGuests > lineItem.max_capacity;
    const noSelection = totalGuests == 0;

    return { ...lineItem, isOverCapacity, noSelection };
  });

  const badGuestSelection = stagedLineItemsWithWarnings.some(
    ({ isOverCapacity, noSelection }) => {
      return isOverCapacity || noSelection;
    }
  );

  return {
    lodge: booking.lodge,
    stagedLineItemsWithWarnings,
    badGuestSelection,
    numberChildren: bookingFilters.numberChildren,
    numberAdults: bookingFilters.numberAdults,
    numberGuests: bookingFilters.numberGuests,
    numberRooms: bookingFilters.numberOfRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeStagedLineItems: () => dispatch(startRemoveStagedLineItems()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
