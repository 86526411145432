import React from "react";

const AccomLineTotal = ({ blankColCount, commission, rate, nett, levy }) => {
  return (
    <tr>
      {Array(blankColCount)
        .fill("x")
        .map((el, i) => (
          <th key={i} />
        ))}
      <th>{levy}</th>
      <th>{commission}</th>
      <th>{nett}</th>
      <th>{rate}</th>
      <th />
    </tr>
  );
};

export default AccomLineTotal;
