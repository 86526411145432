import React from "react";
import DatePicker from "react-date-picker";

import moment from "moment";

export default class MonthPicker extends React.Component {
  render() {
    return (
      <DatePicker
        maxDetail={"year"}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        onChange={this.props.onChange}
        value={this.props.date}
        clearIcon={null}
      />
    );
  }
}
