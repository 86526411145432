import React from "react";
import OptionTemplate from "./OptionTemplate";
import { DropDown } from "./../../shared-components/DropDown";
import InlineEdit from "./InlineEdit";
import Card, { CardText } from "material-ui/Card";

function campnamecleaned(property) {
  switch (property) {
    case "Founders Camp":
      return "founders-camp";
    case "Pioneer Camp":
      return "pioneer-camp";
    case "Private Granite Suites":
      return "private-granite-suites";
    case "Tree Camp":
      return "tree-camp";
    case "Varty Camp":
      return "varty-camp";
    case "General":
      return "general";
    case "Landrovers":
      return "landrovers";
    case "Lodge: Miscellaneous":
      return "lodge-miscellaneous";
  }
}

const LodgeOptionList = ({
  properties,
  handleToggle,
  changeNumber,
  editPolicy,
  changeCapacity,
}) => {
  return (
    <div>
      {Object.values(properties).map((property, i) => (
        <div className="edit-lodge" data-name={campnamecleaned(property.name)}>
          <OptionTemplate
            className="property-template"
            key={i}
            details={{
              type: "property",
              property: property.name,
            }}
            title={property.name}
            isVisible={property.isVisible}
            handleToggle={handleToggle}
            fontsize={20}
          >
            <div className="edit-lodge-contents">
              <div className="edit-lodge-policy">
                <div>Property Policy</div>
                <InlineEdit
                  name={property.name}
                  defaultValue={property.policy}
                  updateValue={editPolicy}
                  style={{
                    text: {
                      width: "99.4%",
                      resize: "none",
                      padding: "10px",
                    },
                  }}
                />
              </div>
              <br />
              <div className="edit-lodge-rooms">
                {Object.values(property.accomTypes).map((accomType) => (
                  <div className="edit-lodge-room">
                    <OptionTemplate
                      key={accomType.id}
                      details={{
                        type: "accomType",
                        property: property.name,
                        accomType: accomType.name,
                      }}
                      title={accomType.name}
                      isVisible={accomType.isVisible}
                      handleToggle={handleToggle}
                      fontsize={20}
                    >
                      <div className="rate-group-container">
                        {Object.values(accomType.rateGroups).map(
                          (rateGroup) => (
                            <OptionTemplate
                              key={accomType.id + rateGroup.id}
                              details={{
                                type: "rateGroup",
                                property: property.name,
                                accomType: accomType.name,
                                rateGroup: rateGroup.desc,
                              }}
                              title={rateGroup.desc}
                              isVisible={rateGroup.isVisible}
                              handleToggle={handleToggle}
                              fontsize={20}
                            >
                              <DropDown
                                floatingLabelText="Max Number"
                                onChange={(e, i, v) =>
                                  changeNumber(
                                    property.name,
                                    accomType.name,
                                    rateGroup.desc,
                                    "maxNumber",
                                    v
                                  )
                                }
                                items={Array.apply(null, {
                                  length: 99,
                                }).map((v, i) => (i + 1).toString())}
                                selectedItem={rateGroup.maxNumber}
                                style={{ width: "15rem" }}
                              />
                            </OptionTemplate>
                          )
                        )}
                        <Card style={{ width: "20rem", padding: 10 }}>
                          <CardText>
                            Max Capacity
                            <DropDown
                              floatingLabelText="Max Capacity"
                              onChange={(e, i, v) =>
                                changeCapacity(property.name, accomType.name, v)
                              }
                              items={Array.apply(null, {
                                length: 99,
                              }).map((v, i) => (i + 1).toString())}
                              selectedItem={accomType.max_capacity}
                              style={{ width: "15rem" }}
                            />
                          </CardText>
                        </Card>
                      </div>
                    </OptionTemplate>
                  </div>
                ))}
              </div>
            </div>
          </OptionTemplate>
        </div>
      ))}
    </div>
  );
};

export default LodgeOptionList;
