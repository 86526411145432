import lodgeForce from "./../wrappers/LodgeForceAPI";

export const getBookings = () => {
  return (dispatch) => {
    return lodgeForce
      .get(`managebookings/getbookings`)
      .then((bookings) => {
        dispatch(setBookings(bookings));
      })
      .catch((e) => console.log(e));
  };
};

export const getmyBookings = () => {
  return (dispatch) => {
    return lodgeForce
      .get(`agentaccess/book/get_my_bookings`)
      .then((bookings) => {
        dispatch(setBookings(bookings));
      })
      .catch((e) => console.log(e));
  };
};

const setBookings = (bookings) => ({
  type: "SET_BOOKINGS",
  bookings,
});

export const selectBooking = (id) => ({
  type: "SELECT_BOOKING",
  id,
});

export const deselectBooking = (id) => ({
  type: "DESELECT_BOOKING",
  id,
});

export const selectItinerary = (itinerary) => ({
  type: "SELECT_ITINERARY",
  itinerary,
});

export const deselectItinerary = (itinerary) => ({
  type: "DESELECT_ITINERARY",
  itinerary,
});

export const setRateUpdate = (rate) => ({
  type: "SET_RATE_UPDATE",
  rate,
});

export const startSetRateUpdate = ({
  lodge,
  arrival,
  departure,
  rateGroups,
  accomId,
}) => {
  // rateGroups = [{id: "RS2", desc: "Adult", number:2 ...},{id: "RS3", desc: "Child"}]
  let rateGroupsFormatted = rateGroups.map((rateGroup) => [
    rateGroup.id,
    rateGroup.number,
  ]);
  const unitRateGroup = ["RS1", 1];
  rateGroupsFormatted.push(unitRateGroup);

  return (dispatch) => {
    return lodgeForce
      .post(`/agentaccess/rate/${lodge}`, {
        accomId,
        arrival,
        departure,
        rateGroups: rateGroupsFormatted,
      })
      .then((rate) => {
        return dispatch(setRateUpdate(rate));
      })
      .catch((e) => e);
  };
};

export const updateSuccessfull = (val) => ({
  type: "UPDATE_SUCCESSFULL",
  val,
});

export const updateSuccessfullItinerary = (val) => ({
  type: "UPDATE_SUCCESSFULL_ITINERARY",
  val,
});

export const errorUpdateItinerary = (val) => ({
  type: "ERROR_UPDATE_ITINERARY",
  val,
});

export const bookingchange = (val) => ({
  type: "BOOKING_CHANGE",
  val,
});

export const cancelBooking = (updateType, booking) => {
  return (dispatch) => {
    if (booking.status == "Cancelled") {
      return;
    } else if (updateType != undefined) {
      return lodgeForce
        .post(`agentaccess/updatebooking/${booking.lodgeId}`, {
          booking,
          updateType,
        })
        .catch((e) => console.log(e));
    }
  };
};

export const updateBooking = (updateType, booking) => {
  return (dispatch) => {
    if (updateType == "Update booking" || updateType == booking.status) {
      return lodgeForce
        .post(`agentaccess/updatenotes/${booking.lodgeId}`, {
          booking,
        })
        .then((val) => {
          dispatch(updateSuccessfull(true));
        })
        .catch((e) => console.log(e));
    } else if (updateType != undefined) {
      return lodgeForce
        .post(`agentaccess/updatebooking/${booking.lodgeId}`, {
          booking,
          updateType,
        })
        .then((val) => {
          dispatch(updateSuccessfull(true));
        })
        .catch((e) => console.log(e));
    }
  };
};

export const updateItinerary = (itinerary, newdata) => {
  return (dispatch) => {
    return lodgeForce
      .post(`agentaccess/updateitinerary/Londolozi`, {
        //TODO fix hardcode
        itinerary: itinerary,
        ItineraryData: newdata,
      })
      .then((val) => {
        dispatch(updateSuccessfullItinerary(true));
      })
      .catch((e) => {
        console.log(e);
        dispatch(errorUpdateItinerary(true));
      });
  };
};

export const getLatestBookingData = (id) => {
  return (dispatch) => {
    return lodgeForce
      .get(`agentaccess/book/get_specific_bookings/${id}`, {
        //TODO fix hardcode
      })
      .then((booking) => {
        dispatch(updateLatestBooking(booking));
      })
      .catch((e) => console.log(e));
  };
};

export const updateLatestBooking = (booking) => ({
  type: "UPDATE_LATEST_BOOKING",
  booking,
});
