export const getRole = () => {
  try {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    return parsedUser.role;
  } catch (err) {
    console.log(err);
    return "externalAgent";
  }
};

export const getEmail = () => {
  try {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    return parsedUser.email;
  } catch (err) {
    console.log(err);
    return null;
  }
};
