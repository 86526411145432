import React, { useEffect, useState } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./components/Dashboard/index";
import Login from "./components/Login/index";
import ForgotPassword from "./components/ForgotPassword/index";
import ChangePassword from "./components/ChangePassword/index";
import Signup from "./components/Signup/index";
import BookingPage from "./components/BookingPage/index";
import ManageAgentsPage from "./components/ManageAgentsPage/index";
import ManageBookingPage from "./components/ManageBookingPage/index";
import BookingCalendarPage from "./components/BookingCalendarPage/index";
import LodgeAdmin from "./components/LodgeAdmin/index";
import Verify from "./components/Verify/index";
import LodgeOptions from "./components/LodgeOptions/index";
import TradeCampInfo from "./components/TradeInfo/campinfo";
import TradeImpact from "./components/TradeInfo/impact";
import TradeLatestUpdates from "./components/TradeInfo/latestUpdates";
import TradeLondoloziExperience from "./components/TradeInfo/londoloziExperience";
import TradeRates from "./components/TradeInfo/rates";
import TradeDashboard from "./components/TradeInfo/dashboard";
import TradeMediaGallery from "./components/TradeInfo/mediaGallery";
import FoundersMediaGallery from "./components/TradeInfo/mediaGalleries/foundersMediaGallery";
import PioneerMediaGallery from "./components/TradeInfo/mediaGalleries/pioneerMediaGallery";
import GraniteMediaGallery from "./components/TradeInfo/mediaGalleries/graniteMediaGallery";
import TreeMediaGallery from "./components/TradeInfo/mediaGalleries/treeMediaGallery";
import VartyMediaGallery from "./components/TradeInfo/mediaGalleries/vartyMediaGallery";
import OnSafariMediaGallery from "./components/TradeInfo/mediaGalleries/onSafariMediaGallery";
import ExperienceMediaGallery from "./components/TradeInfo/mediaGalleries/experienceMediaGallery";
import WildlifeMediaGallery from "./components/TradeInfo/mediaGalleries/wildlifeMediaGallery";
import AccountConfirmation from "./modules/AccountConfirmation";
import useLocalStorage from "./functions/useLocalStorage";

import SideBar from "./modules/SideBar";

import NotFoundPage from "./components/NotFoundPage";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Auth0ProviderWithHistory from "./Auth0ProviderWithHistory";
import { useAuth0 } from "@auth0/auth0-react";
import lodgeForceApiClient from "./wrappers/LodgeForceAPI";

const ProtectedRoute = ({ component, ...args }) => {
  const {
    user,
    logout,
    isLoading,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const [serverUser, setServerUser] = useState(null);
  const [storedUser, setStoredUser] = useLocalStorage("user", null);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((tk) => {
        lodgeForceApiClient.setToken(tk);

        lodgeForceApiClient
          .get("/users/me")
          .then((serUser) => {
            setServerUser(serUser);
            setStoredUser(serUser);
          })
          .catch((err) => {
            logout();
            setStoredUser(null);
          });
      });
    }
  }, [isAuthenticated]);

  if (!isLoading && !isAuthenticated) {
    return loginWithRedirect();
  }

  if (isLoading || !serverUser) {
    return null;
  }

  if (serverUser && !serverUser.confirmed) {
    return <AccountConfirmation />;
  }

  return (
    <div className="page-layout">
      <SideBar
        user={user}
        role={serverUser.role}
        onLogOut={() => {
          logout({ returnTo: window.location.origin });
          setStoredUser(null);
        }}
      />
      <div className="content" id="content">
        <div className="booking-page">
          <Route
            component={withAuthenticationRequired(component, {})}
            {...args}
          />
        </div>
      </div>
    </div>
  );
};

class AppRouter extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    // this simulates an async action, after which the component will render the content
    mainAsyncLoad().then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <MuiThemeProvider>
            <Switch>
              <ProtectedRoute path="/" component={Dashboard} exact={true} />
              <ProtectedRoute path="/verify/:token" component={Verify} />
              <ProtectedRoute
                path="/confirm/:confirmToken"
                component={Dashboard}
              />
              <ProtectedRoute path="/new-booking" component={BookingPage} />
              <ProtectedRoute
                path="/LodgeOptions"
                component={LodgeOptions}
                role="lodgeAdmin"
              />
              <ProtectedRoute
                path="/agent_management"
                component={ManageAgentsPage}
                role="lodgeAdmin"
              />
              <ProtectedRoute
                path="/manage_agents"
                component={LodgeAdmin}
                role="lodgeAdmin"
              />
              <ProtectedRoute
                path="/booking_management"
                component={ManageBookingPage}
                role="lodgeAdmin"
              />
              <ProtectedRoute
                path="/booking-calendar"
                component={BookingCalendarPage}
                role="lodgeAdmin"
              />
              <ProtectedRoute
                path="/trade/dashboard"
                component={TradeDashboard}
              />
              <ProtectedRoute
                path="/trade/campInfo"
                component={TradeCampInfo}
              />
              <ProtectedRoute path="/trade/impact" component={TradeImpact} />
              <ProtectedRoute
                path="/trade/latestUpdates"
                component={TradeLatestUpdates}
              />
              <ProtectedRoute
                path="/trade/londoloziExperience"
                component={TradeLondoloziExperience}
              />
              <ProtectedRoute path="/trade/rates" component={TradeRates} />
              <ProtectedRoute
                path="/trade/mediaGallery/founders"
                component={FoundersMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery/pioneer"
                component={PioneerMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery/granite"
                component={GraniteMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery/tree"
                component={TreeMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery/varty"
                component={VartyMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery/onSafari"
                component={OnSafariMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery/experience"
                component={ExperienceMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery/wildlife"
                component={WildlifeMediaGallery}
              />
              <ProtectedRoute
                path="/trade/mediaGallery"
                component={TradeMediaGallery}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </MuiThemeProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    );
  }
}

function mainAsyncLoad() {
  return new Promise((resolve) => resolve());
}

export default AppRouter;
