import React from "react";
import { connect } from "react-redux";
import { selectConsultant } from "./../../../actions/agentManagement";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

const AgentTable = ({ handleSelection, consultants }) => {
  return (
    <Table
      onRowSelection={([rowIndex]) => {
        if (rowIndex !== undefined) {
          handleSelection(rowIndex);
        }
      }}
      height="200px"
    >
      <TableHeader>
        <TableRow>
          <TableHeaderColumn>Name</TableHeaderColumn>
          <TableHeaderColumn>Email</TableHeaderColumn>
          <TableHeaderColumn>Physical Address</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody deselectOnClickaway={false}>
        {consultants.map(({ name, email, address, selected }, i) => {
          return (
            <TableRow selected={selected} key={i}>
              <TableRowColumn>{name}</TableRowColumn>
              <TableRowColumn>{email}</TableRowColumn>
              <TableRowColumn>{address}</TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = ({ agentManagement }) => {
  return {
    consultants: agentManagement.consultants.slice(0, 19).map((consultant) => {
      return {
        name: `${consultant.first_name} ${consultant.last_name}`,
        email: consultant.email,
        address: `${consultant.phys_city}${consultant.phys_city && ","} ${
          consultant.phys_country_name
        }`,
        selected: consultant.selected,
      };
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSelection: (rowIndex) => dispatch(selectConsultant(rowIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentTable);
