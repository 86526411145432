import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import IconButton from "material-ui/IconButton";
import ContentClear from "material-ui/svg-icons/content/clear";
import update from "immutability-helper";
import {
  getmyBookings,
  selectBooking,
} from "./../../../../actions/bookingManagement";
let aa = false;
let updateType;

const modalStyle = {
  content: {
    outline: "none",
    backgroundColor: "white",
    width: "40rem",
    height: "20rem",
    border: "0.1rem solid #888888",
    boxShadow: "0.5rem 1rem 1rem #888888",
    margin: "auto",
    textAlign: "center",
  },
};

Modal.setAppElement("#app");
class LoaderBookingUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal = () => {
    aa = false;
    this.setState({ showModal: true });
    this.props.setBookings();
  };

  render() {
    if (this.props.updateType != undefined) {
      updateType = this.props.updateType;
    }

    return (
      <div>
        <Modal
          isOpen={aa}
          style={modalStyle}
          ariaHideApp={true}
          onRequestClose={this.handleCloseModal}
        >
          <IconButton styles={{ width: 2 }} onClick={this.handleCloseModal}>
            <ContentClear />
          </IconButton>
          <p style={{ fontSize: "15px" }}>
            The status of the booking has been changed to:
            <br />
            <div
              style={{
                paddingTop: "20px",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              {updateType}
            </div>
          </p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ bookingManagement }) => {
  if (bookingManagement.successfull) {
    aa = true;
  }

  return {
    success: bookingManagement.successfull,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookings: () => dispatch(getmyBookings()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderBookingUpdate);
