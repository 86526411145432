import React from "react";
import { connect } from "react-redux";
import { setHighlightType } from "./../../../actions/booking";
import { DropDown } from "./../../../shared-components/DropDown";

const HighlightTypeDropDown = ({ selectedItem, setHighlightType }) => (
  <DropDown
    className="booking-card-dropdown-calendar"
    floatingLabelText="Highlight Type"
    onChange={(e, i, v) => setHighlightType(v)}
    items={["booking", "block", "unblock"]}
    selectedItem={selectedItem}
  />
);

const mapStateToProps = ({ booking }) => {
  return {
    selectedItem: booking.highlightType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHighlightType: (highlightType) =>
      dispatch(setHighlightType(highlightType)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HighlightTypeDropDown);
