// Takes in this.props.name which is the name of the NotesType
import React from "react";

const NotesSection = ({ name, text, myState, updateTextField }) => (
  <div>
    <h4>{name}:</h4>
    <textarea
      className="notesbooking-textarea"
      value={text}
      onChange={updateTextField(myState)}
      rows="4"
    />
  </div>
);

export default NotesSection;
