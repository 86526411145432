import React from "react";
import { v4 as uuid } from "uuid";
import TableDragSelect from "react-table-drag-select";
import { connect } from "react-redux";
import {
  createDateArray,
  getDatesStayed,
  getArrivalAndDeparture,
} from "./../../../functions/dates";
import { getRole } from "./../../../functions/getRole";
import Snackbar from "material-ui/Snackbar";
import { startAddStagingLineItem } from "./../../../actions/booking";
import { startSetBlockData } from "./../../../actions/properties";
import WarningModal from "./../WarningModal";

class AccomType extends React.Component {
  state = {
    cells: [Array(23).fill(false)],
    selection: false,
    isOpen: true,
    snackOpen: false,
    warningTog: false,
    role: getRole(),
  };

  handleSelection = (cells) => {
    this.setState({ warningTog: false });
    const selectedDates = this.props.rangeDatesArray.filter(
      (date, i) => cells[0][i + 2]
    );

    const [arrival, departure] = getArrivalAndDeparture(selectedDates);
    const {
      propertyName,
      propertyId,
      highlightType,
      lodgeId,
      propertyPolicy,
    } = this.props;
    const accomName = this.props.name;

    if (highlightType === "booking") {
      let accomArray = [];

      const { lineItems } = this.props;

      lineItems.map((lineItem) => {
        accomArray.push(lineItem.accomName);
      });

      const includeAccomCheck = accomArray.every((v) => v === accomArray[0]);

      const isPropertyInLineItems = lineItems.some((lineItem) => {
        return lineItem.propertyName === propertyName;
      });
      const isAccomNameInLineItems = lineItems.some((lineItem) => {
        return lineItem.accomName === accomName;
      });
      const isFollowOnTrue = lineItems.some((lineItem) => {
        return lineItem.departure === arrival;
      });
      const isLeadingOnTrue = lineItems.some((lineItem) => {
        return lineItem.arrival === departure;
      });
      const isArrivalSame = lineItems.some((lineItem) => {
        return lineItem.arrival === arrival;
      });
      const isDepartureSame = lineItems.some((lineItem) => {
        return lineItem.departure === departure;
      });
      const arrivalFirst = lineItems.map((arrivalTime) => {
        return arrivalTime.arrival;
      });
      const arrivalSec = lineItems.map((arrivalTime) => {
        return arrival;
      });
      const comp = () => {
        return arrivalFirst > arrivalSec;
      };

      if (this.state.role === "externalAgent") {
        if (lineItems.length > 0) {
          if (!isPropertyInLineItems) {
            this.setState({ warningTog: true });
            this.setState({ warningTog: false });
            return;
          }
          if (isPropertyInLineItems && isAccomNameInLineItems) {
            if (!isFollowOnTrue && !comp()) {
              this.setState({ warningTog: true });
              this.setState({ warningTog: false });
              return;
            }
            if (!isLeadingOnTrue && comp()) {
              this.setState({ warningTog: true });
              this.setState({ warningTog: false });
              return;
            }
            if (lineItems.length > 1 && !isFollowOnTrue && !comp()) {
              this.setState({ warningTog: true });
              this.setState({ warningTog: false });
              return;
            }
            if (lineItems.length > 1 && !isLeadingOnTrue && comp()) {
              this.setState({ warningTog: true });
              this.setState({ warningTog: false });
              return;
            }
            if (
              lineItems.length > 1 &&
              (!isArrivalSame || !isDepartureSame) &&
              !includeAccomCheck
            ) {
              this.setState({ warningTog: true });
              this.setState({ warningTog: false });
              return;
            }
          }

          if (
            isPropertyInLineItems &&
            !isAccomNameInLineItems &&
            (!isArrivalSame || !isDepartureSame)
          ) {
            this.setState({ warningTog: true });
            this.setState({ warningTog: false });
            return;
          }
        }
      }

      for (let i = 0; i < selectedDates.length; i++) {
        const roomsLeft = this.props.availability[selectedDates[i]];

        const isNotAvailable = !roomsLeft || roomsLeft <= 0;
        if (isNotAvailable) {
          this.setState({ snackOpen: true });
          return;
        }
      }

      const rateGroups = Object.values(this.props.rateGroups);

      this.setState({ warningTog: false });

      this.props.addStagingLineItem({
        propertyName,
        propertyId,
        accomName,
        accomId: this.props.id,
        max_capacity: this.props.max_capacity,
        arrival,
        departure,
        rateGroups,
        lineId: uuid(),
        propertyPolicy,
      });
    } else {
      this.props.updateBlockData({
        lodgeId,
        propertyName,
        accomName,
        highlightType,
        selectedDates,
      });
    }
  };

  render() {
    let { name, availability, blockObject, propertyId } = this.props;

    let selectDates = [];

    if (this.props.lineItems != undefined)
      this.props.lineItems.map((line) => {
        if (line.accomName == name && line.propertyId == propertyId) {
          let tempselectDates = createDateArray(line.arrival, line.departure);
          tempselectDates = tempselectDates.slice(
            0,
            tempselectDates.length - 1
          );

          selectDates += tempselectDates;
          return;
        }
      });

    const role = getRole();
    return (
      <div>
        <TableDragSelect
          value={this.state.cells}
          onChange={this.handleSelection}
        >
          {this.state.isOpen ? (
            <tr className="accomm-line">
              <td disabled className="room-type">
                {name.includes("Camp Chalet") ? "Camp Chalet" : name}
              </td>
              <td disabled className="price">
                ZAR
              </td>
              {this.props.rangeDatesArray.map((date) => {
                const isBooked = availability[date] <= 0 || !availability[date];
                const isSearched = this.props.searchedDatesArray.includes(date);
                const isBlocked = blockObject && blockObject[date] === "b";

                const isSelected = selectDates.includes(date);

                let className = ["day"];
                if (isBlocked) {
                  if (isSelected) {
                    className.push("booked-selected");
                  } else {
                    className.push("blocked");
                  }
                }
                if (isSelected) {
                  className.push("booked-selected");
                  return (
                    <td
                      key={uuid()}
                      className={className.join(" ")}
                      disabled={this.props.highlightType === "booking"}
                    />
                  );
                } else if (isBooked) {
                  className.push(isSearched ? "selected-booked" : "booked");
                  return (
                    <td
                      key={uuid()}
                      className={className.join(" ")}
                      disabled={this.props.highlightType === "booking"}
                    />
                  );
                } else {
                  if (isSearched) className.push("selected-notbooked");
                  return (
                    <td key={uuid()} className={className.join(" ")}>
                      {role === "externalAgent"
                        ? availability[date] == 1
                          ? "1"
                          : "1+"
                        : availability[date]}
                    </td>
                  );
                }
              })}
            </tr>
          ) : (
            <tr />
          )}
        </TableDragSelect>
        <Snackbar
          open={this.state.snackOpen}
          message="Rooms are unavailable for these dates"
          autoHideDuration={4000}
          onRequestClose={() => this.setState({ snackOpen: false })}
        />

        <WarningModal warningTog={this.state.warningTog} />
      </div>
    );
  }
}

const mapStateToProps = ({ bookingFilters, booking }) => {
  const { searchedDates, calendarRangeDates } = bookingFilters;
  const { highlightType, lodge, lineItems } = booking;

  return {
    rangeDatesArray: createDateArray(
      calendarRangeDates.startDate,
      calendarRangeDates.endDate
    ),
    searchedDatesArray: getDatesStayed(
      searchedDates.startDate,
      searchedDates.endDate
    ),
    highlightType,
    lodgeId: lodge,
    lineItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addStagingLineItem: (lineItem) => {
      dispatch(startAddStagingLineItem(lineItem));
    },
    updateBlockData: ({
      lodgeId,
      propertyName,
      accomName,
      highlightType,
      selectedDates,
    }) => {
      const blockId = highlightType == "block" ? "b" : "u";
      let blockObject = {};

      selectedDates.forEach((date) => {
        blockObject[date] = blockId;
      });
      dispatch(
        startSetBlockData({
          lodgeId,
          propertyName,
          accomName,
          blockObject,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccomType);
