import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardText } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import EyeButton from "material-ui/svg-icons/image/remove-red-eye";
import IconButton from "material-ui/IconButton";
import WithLoading from "./../../hoc/WithLoading";

const SignUpForm = ({
  onSubmit,
  onChange,
  errors,
  user,
  toggle,
  isPassVisible,
  isLoading,
}) => (
  <Card className="authentication-card">
    <div className="authentication-card__heading" />
    <form action="/" onSubmit={onSubmit}>
      <h2>Sign Up</h2>
      {errors.summary && <p className="error-message">{errors.summary}</p>}
      <div className="field-line">
        <TextField
          floatingLabelText="First Name"
          name="firstName"
          errorText={errors.firstName}
          onChange={onChange}
          value={user.firstName}
        />
      </div>
      <div className="field-line">
        <TextField
          floatingLabelText="Last Name"
          name="lastName"
          errorText={errors.lastName}
          onChange={onChange}
          value={user.lastName}
        />
      </div>
      <div className="field-line">
        <TextField
          floatingLabelText="Company"
          name="company"
          errorText={errors.company}
          onChange={onChange}
          value={user.company}
        />
      </div>
      <div className="field-line">
        <TextField
          floatingLabelText="Email"
          name="email"
          errorText={errors.email}
          onChange={onChange}
          value={user.email}
        />
      </div>
      <div className="field-line">
        <TextField
          floatingLabelText="Password"
          name="password"
          style={{ width: 210 }}
          type={isPassVisible ? "text" : "password"}
          errorText={errors.password}
          onChange={onChange}
          value={user.password}
        />
        <IconButton tooltip="Toggle visibility" onClick={() => toggle()}>
          <EyeButton />
        </IconButton>
      </div>

      <div className="button-line">
        <RaisedButtonWithLoading
          className="button-login"
          type="submit"
          label="Create New Account"
          primary
          isLoading={isLoading}
        />
      </div>
      <CardText>
        Already have an account? <Link to="/login">Log in</Link>
      </CardText>
    </form>
  </Card>
);

const RaisedButtonWithLoading = WithLoading(RaisedButton);
SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default SignUpForm;
