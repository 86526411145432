import React from "react";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import AppRouter from "./AppRouter";

import "normalize.css/normalize.css";
import "./styles/styles.scss";
import "react-dates/lib/css/_datepicker.css";
import "draft-js/dist/Draft.css";

const store = configureStore();
store.subscribe(() => {
  const state = store.getState();
});

ReactDOM.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,

  document.getElementById("app")
);
