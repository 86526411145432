import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardText } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";

const ForgotForm = ({
  onSubmit,
  onChange,
  errors,
  email,
  header,
  isSendDisabled,
}) => (
  <Card className="authentication-card">
    <form action="/" onSubmit={onSubmit}>
      <h2 className="authentication-card__heading">{header}</h2>
      {errors.summary && <p className="error-message">{errors.summary}</p>}

      <div className="field-line">
        <TextField
          floatingLabelText="Email"
          name="email"
          onChange={onChange}
          value={email}
        />
      </div>

      <div className="button-line">
        <RaisedButton
          className="button-login"
          type="submit"
          label="Submit"
          primary
          disabled={isSendDisabled}
        />
      </div>

      <CardText>
        Remembered your password? <Link to="/login">Log in</Link>
      </CardText>
    </form>
  </Card>
);

ForgotForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
};

export default ForgotForm;
