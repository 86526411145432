import lodgeForce from "./../wrappers/LodgeForceAPI";
import { changeAvailability, clearProperties } from "./properties";

import _ from "lodash";
import { resolve } from "url";

export const setLodge = (lodge) => ({
  type: "SET_LODGE",
  lodge,
});

export const addStagingLineItem = ({
  propertyName,
  propertyId,
  accomName,
  accomId,
  arrival,
  departure,
  rateGroups,
  lineId,
  max_capacity,
  propertyPolicy,
}) => ({
  type: "ADD_STAGING_LINE_ITEM",
  propertyName,
  propertyId,
  accomName,
  accomId,
  arrival,
  departure,
  rateGroups,
  lineId,
  max_capacity,
  propertyPolicy,
});

export const removeLineItem = (lineId) => ({
  type: "REMOVE_LINE_ITEM",
  lineId,
});

export const removeStagedLineItems = () => ({
  type: "REMOVE_STAGED_LINE_ITEMS",
});

export const setDefaultConfig = (lineId) => ({
  type: "SET_DEFAULT_CONFIG",
  lineId,
});

export const setConfig = (lineId, rateGroupId, number) => ({
  type: "SET_CONFIG",
  lineId,
  rateGroupId,
  number,
});

export const confirmLineItems = () => ({
  type: "CONFIRM_LINE_ITEMS",
});

export const setRate = (lineId, rate) => ({
  type: "SET_RATE",
  lineId,
  rate,
});

export const setLevi = (lineId, levy) => ({
  type: "SET_LEVI",
  lineId,
  levy,
});

export const setOverride = (lineId, override) => ({
  type: "SET_OVERRIDE",
  lineId,
  override,
});

export const removeRate = (lineId) => ({
  type: "REMOVE_RATE",
  lineId,
});

export const clearBooking = () => ({
  type: "CLEAR_BOOKING",
});

export const setHighlightType = (highlightType) => ({
  type: "SET_HIGHLIGHT_TYPE",
  highlightType,
});

export const startSetRate = ({
  lineId,
  lodge,
  arrival,
  departure,
  rateGroups,
  accomId,
}) => {
  // rateGroups = [{id: "RS2", desc: "Adult", number:2 ...},{id: "RS3", desc: "Child"}]
  let rateGroupsFormatted = rateGroups.map((rateGroup) => [
    rateGroup.id,
    rateGroup.number,
  ]);
  const unitRateGroup = ["RS1", 1];
  rateGroupsFormatted.push(unitRateGroup);

  return (dispatch) => {
    dispatch(removeRate(lineId));
    return lodgeForce
      .post(`/agentaccess/rate/${lodge}`, {
        accomId,
        arrival,
        departure,
        rateGroups: rateGroupsFormatted,
      })
      .then((rate) => {
        return dispatch(setRate(lineId, rate));
      })
      .catch((e) => e);
  };
};

const makeBooking = () => ({
  type: "MAKE_BOOKING",
});

const bookingSuccess = () => ({
  type: "BOOKING_SUCCESS",
});

const bookingError = () => ({
  type: "BOOKING_ERROR",
});

export const startMakeBooking = ({
  lodge,
  lineItems,
  bookingName,
  generalNotes,
  guestNotes,
  originator,
  repeatGuest,
  nationality,
  agency,
}) => {
  return (dispatch) => {
    dispatch(makeBooking());
    return lodgeForce
      .post(`/agentaccess/book/${lodge}`, {
        lineItems,
        bookingName,
        generalNotes,
        guestNotes,
        originator,
        repeatGuest,
        nationality,
        agency,
      })
      .then((result) => {
        dispatch(bookingSuccess());
        return new Promise((resolve) => {
          setTimeout(() => {
            dispatch(clearBooking());
            dispatch(clearProperties());
            return resolve(result);
          }, 3000);
        });
      })
      .catch((e) => {
        dispatch(bookingError());
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            dispatch(clearBooking());
            dispatch(clearProperties());
            return reject(e);
          }, 3000);
        });
      });
  };
};

export const startSetConfig = (lineId, rateGroupId, number) => {
  return (dispatch, getState) => {
    dispatch(setConfig(lineId, rateGroupId, number));
    const lineItem = getState().booking.lineItems.find(
      (lineItem) => lineItem.lineId == lineId
    );
    const lodge = getState().booking.lodge;
    return dispatch(startSetRate({ lineId, lodge, ...lineItem }));
  };
};

export const startAddStagingLineItem = (line) => {
  return (dispatch, getState) => {
    dispatch(
      changeAvailability({
        numberOfRooms: 1,
        arrival: line.arrival,
        departure: line.departure,
        accomName: line.accomName,
        propertyName: line.propertyName,
      })
    );
    dispatch(addStagingLineItem(line));

    dispatch(setDefaultConfig(line.lineId));
    const lineItem = getState().booking.lineItems.find(
      (lineItem) => lineItem.lineId == line.lineId
    );
    const lodge = getState().booking.lodge;
    return dispatch(startSetRate({ lineId: line.lineId, lodge, ...lineItem }));
  };
};

export const startRemoveLineItem = (lineId) => {
  return (dispatch, getState) => {
    const lineItem = getState().booking.lineItems.find(
      (lineItem) => lineItem.lineId === lineId
    );
    dispatch(
      changeAvailability({
        numberOfRooms: 1,
        ...lineItem,
        isIncreasingAvailability: true,
      })
    );
    dispatch(removeLineItem(lineId));
  };
};

// export const startRemoveStagedLineItems = () => {
//     return (dispatch, getState) => {
//         const stagedLineItemsCount = getState().booking.lineItems.filter(
//             lineItem => !lineItem.confirmed
//         ).length;
//         const lineItem = _.pick(getState().booking, [
//             "arrival",
//             "departure",
//             "accomName",
//             "propertyName"
//         ]);
//         dispatch(removeStagedLineItems());
//         dispatch(
//             changeAvailability({
//                 numberOfRooms: stagedLineItemsCount,
//                 ...lineItem,
//                 isIncreasingAvailability: true
//             })
//         );
//     };
// };

export const startRemoveStagedLineItems = () => {
  return (dispatch, getState) => {
    const lineIds = getState()
      .booking.lineItems.filter((lineItem) => !lineItem.confirmed)
      .map((lineItem) => lineItem.lineId);

    lineIds.forEach((lineId) => dispatch(startRemoveLineItem(lineId)));
  };
};
