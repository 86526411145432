const Switch = ({ value, fieldName, id, onChange, children }) => {
  return (
    <div className="switch-wrapper">
      <label className="switch" for={id}>
        <input
          type="checkbox"
          name={fieldName || id}
          checked={value}
          id={id}
          value={value ? "on" : "off"}
          onChange={onChange}
        />
        <span class="slider round"></span>
      </label>
      {children}
    </div>
  );
};

export default Switch;
