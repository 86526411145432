import React from "react";
import Select from "react-select";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

export default class DatePicker extends React.Component {
  state = {
    dropdownvalue: {
      value: this.props.dropdownvalue,
      label: this.props.dropdownvalue,
    },
    itemname: this.props.itemname,
    optionvalues: this.props.optionvalues,
  };
  render() {
    this.state.dropdownvalue = {
      value: this.props.dropdownvalue,
      label: this.props.dropdownvalue,
    };

    return (
      <div style={{ display: "block", width: 350, textAlign: "left" }}>
        <div style={{ display: "block", width: 350, paddingBottom: "20px" }}>
          <div
            style={{ display: "inline-block", width: 100, paddingLeft: "10px" }}
          >
            {this.state.itemname + ":"}
          </div>
          <div
            style={{ display: "inline-block", width: 150, paddingLeft: "10px" }}
          >
            <Select
              maxMenuHeight={200}
              value={this.state.dropdownvalue}
              options={this.props.optionvalues}
              onChange={(newValue) => {
                this.props.setSearchDates(newValue.value);
                this.setState({ dropdownvalue: newValue });
              }}
              placeholder={this.state.itemname}
            />
          </div>
        </div>
      </div>
    );
  }
}
