import moment from "moment";

export default (arrival, departure) => {
  const format = "YYYY-MM-DD";
  arrival = moment(arrival, format);
  departure = moment(departure, format);
  const nights = departure.diff(arrival, "days");
  let datesStayed = [];
  datesStayed.push(arrival.format(format));
  for (let i = 1; i < nights; i++) {
    datesStayed.push(arrival.add(1, "days").format(format));
  }
  return datesStayed;
};
