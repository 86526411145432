import React from "react";
import { connect } from "react-redux";
import { Card } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import { confirmLineItems } from "./../../../actions/booking";
import Scroll from "react-scroll";
import Checkbox from "material-ui/Checkbox";
import {
  createDateArray,
  getDatesStayed,
  getArrivalAndDeparture,
} from "./../../../functions/dates";
import WithRoleCheck from "./../../../hoc/WithRoleCheck";
import { getRole } from "./../../../functions/getRole";
import CardText from "../../../../node_modules/material-ui/Card/CardText";
import WarningModal from "../WarningModal";
let scroller = Scroll.scroller;

class Confirm extends React.Component {
  state = {
    checked: getRole() === "lodgeAdmin" ? true : false,
    role: getRole(),
    warningTog: false,
  };

  confirm = () => {
    this.setState({ warningTog: false });
    const { confirmLineItems, lineItems } = this.props;

    let [arrival, departure, accomName, propertyName] = "";

    let notConfirmed = lineItems.filter(
      (lineItem) => lineItem.confirmed == undefined
    );
    let notConfirmedDeparture = notConfirmed.map((items) => items["departure"]);
    let notConfirmedArrival = notConfirmed.map((items) => items["arrival"]);
    let notConfirmedpropertyname = notConfirmed.map(
      (items) => items["propertyName"]
    );
    let notConfirmedaccomName = notConfirmed.map((items) => items["accomName"]);
    let ConfirmedLineItems = lineItems.filter(
      (lineItem) => lineItem.confirmed == true
    );
    if (this.state.role === "externalAgent") {
      if (
        notConfirmedDeparture.every((v) => v === notConfirmedDeparture[0]) &&
        notConfirmedArrival.every((v) => v === notConfirmedArrival[0]) &&
        notConfirmedpropertyname.every((v) => v === notConfirmedpropertyname[0])
      ) {
        lineItems.map((lineItem) => {
          if (lineItem.confirm == undefined) {
            arrival = lineItem.arrival;
            departure = lineItem.departure;
            accomName = lineItem.accomName;
            propertyName = lineItem.propertyName;
          }
        });
      } else {
        this.setState({ warningTog: true });
        this.timepause();
        return;
      }
    }

    //lineItems.filter confirm == undefined
    // Check property, accom, dates

    //Confirmed == true and compare to 1 undefined

    let accomArray = [];

    ConfirmedLineItems.map((lineItem) => {
      accomArray.push(lineItem.accomName);
    });
    if (ConfirmedLineItems.length > 0) {
      const includeAccomCheck = accomArray.every((v) => v === accomArray[0]);

      const isPropertyInLineItems = ConfirmedLineItems.some((lineItem) => {
        return lineItem.propertyName === propertyName;
      });
      const isAccomNameInLineItems = ConfirmedLineItems.some((lineItem) => {
        return lineItem.accomName === accomName;
      });
      const isFollowOnTrue = ConfirmedLineItems.some((lineItem) => {
        return lineItem.departure === arrival;
      });
      const isLeadingOnTrue = ConfirmedLineItems.some((lineItem) => {
        return lineItem.arrival === departure;
      });
      const isArrivalSame = ConfirmedLineItems.some((lineItem) => {
        return lineItem.arrival === arrival;
      });
      const isDepartureSame = ConfirmedLineItems.some((lineItem) => {
        return lineItem.departure === departure;
      });
      const arrivalFirst = ConfirmedLineItems.map((arrivalTime) => {
        let tempArrival = arrivalTime.arrival;
        return tempArrival;
      });
      const arrivalSec = ConfirmedLineItems.map((arrivalTime) => {
        return arrival;
      });
      const comp = () => {
        return arrivalFirst > arrivalSec;
      };

      if (this.state.role === "externalAgent") {
        if (ConfirmedLineItems.length > 0) {
          if (!isPropertyInLineItems) {
            this.setState({ warningTog: true });
            this.timepause();
            return;
          }
          if (isPropertyInLineItems && isAccomNameInLineItems) {
            if (!isFollowOnTrue && !comp()) {
              this.setState({ warningTog: true });
              this.timepause();
              return;
            }
            if (!isLeadingOnTrue && comp()) {
              this.setState({ warningTog: true });
              this.timepause();
              return;
            }
            if (ConfirmedLineItems.length > 1 && !isFollowOnTrue && !comp()) {
              this.setState({ warningTog: true });
              this.timepause();
              return;
            }
            if (ConfirmedLineItems.length > 1 && !isLeadingOnTrue && comp()) {
              this.setState({ warningTog: true });
              this.timepause();
              return;
            }
            if (
              ConfirmedLineItems.length > 1 &&
              (!isArrivalSame || !isDepartureSame) &&
              !includeAccomCheck
            ) {
              this.setState({ warningTog: true });
              this.timepause();
              return;
            }
          }

          if (
            isPropertyInLineItems &&
            !isAccomNameInLineItems &&
            (!isArrivalSame || !isDepartureSame)
          ) {
            this.setState({ warningTog: true });
            this.timepause();
            return;
          }
        }
      }
    }

    confirmLineItems();
    setTimeout(() => {
      scroller.scrollTo("itinerary", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -100,
      });
    }, 500);
  };

  timepause = () => {
    setTimeout(() => {
      this.setState({ warningTog: false });
    }, 500);
  };
  check = () => {
    this.setState(({ checked }) => ({
      checked: !checked,
    }));
  };

  render() {
    const { isRateLoading, badGuestSelection } = this.props;
    const isBadSelection =
      badGuestSelection && this.state.role === "externalAgent";
    return (
      <div>
        <CheckboxWithRoleCheck
          label="I confirm that I have read the property policies."
          checked={this.state.checked}
          onCheck={this.check}
          role="externalAgent"
          style={{ marginBottom: "10px", maxWidth: 400 }}
          className="policy-confirmation"
        />
        <Card
          className={`confirm-button-bottom ${
            isBadSelection && "room-config__warning"
          }`}
        >
          {isBadSelection && (
            <CardText>
              {"Please fix the room configurations before continuing."}
            </CardText>
          )}

          <RaisedButton
            label="Make a Provisional booking"
            className="confirm-button-raised"
            fullWidth={true}
            onClick={this.confirm}
            disabled={isRateLoading || !this.state.checked || isBadSelection}
          />
        </Card>
        <WarningModal warningTog={this.state.warningTog} />
      </div>
    );
  }
}

const CheckboxWithRoleCheck = WithRoleCheck(Checkbox);

const mapStateToProps = ({ bookingFilters, booking }) => {
  const { searchedDates, calendarRangeDates } = bookingFilters;
  const { highlightType, lodge, lineItems } = booking;

  return {
    isRateLoading: booking.lineItems.some((lineItem) => !lineItem.rate),
    rangeDatesArray: createDateArray(
      calendarRangeDates.startDate,
      calendarRangeDates.endDate
    ),
    searchedDatesArray: getDatesStayed(
      searchedDates.startDate,
      searchedDates.endDate
    ),
    highlightType,
    lodgeId: lodge,
    lineItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmLineItems: () => dispatch(confirmLineItems()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
