import React from "react";

import BookingCalendar from "../BookingPage/BookingCalendar/index";
import {
  setStateDates,
  setSearchDates,
  setCalendarRangeDates,
  setWizardstate,
  setSearchState,
  resetSearchDates,
} from "../../actions/bookingFilters";
import Policies from "../BookingPage/Policies/index";
import BookingModal from "../BookingPage/BookingModal/index";
import WarningModal from "../BookingPage/WarningModal/index";
import MonthPicker from "../../shared-components/MonthPicker";
import Itinerary from "../BookingPage/Itinerary/index";
import WithDisabled from "./../../hoc/WithDisabled";
import ConfirmationModal from "./../../shared-components/ConfirmationModal/index";
import { setLodge } from "../../actions/booking";
import { startSetLodges, selectLodge } from "../../actions/lodges";
import { startSetProperties } from "../../actions/properties";
import { connect } from "react-redux";

import moment from "moment";

const dateFormat = "YYYY-MM-DD";

class BookingCalendarPage extends React.Component {
  componentWillMount() {
    const startDate = moment();
    const endDate = moment().add(2, "days");

    this.props.setSearchDates(startDate, endDate);

    this.props.setProperties(this.props.selectedLodge || this.props.lodges[0]);
    this.props.setLodge(this.props.selectedLodge || this.props.lodges[0]);

    this.props.setCalendarRangeDates();

    this.props.setSearchState(true);
    this.props.setWizardstate(true);
  }

  componentWillUnmount() {
    this.props.resetSearchDates();
  }

  setNewSearchDates(startDate) {
    const newStartDate = moment(startDate).startOf("month");
    const endDate = moment(newStartDate).add(3, "days");

    this.props.setSearchDates(newStartDate, endDate);

    this.props.setCalendarRangeDates();
  }

  render() {
    const startDate = this.props.searchedDates
      ? this.props.searchedDates.startDate
        ? this.props.searchedDates.startDate.toDate()
        : new Date()
      : new Date();

    return (
      <div id="booking-calendar-page">
        <div />

        <div className="search-dates">
          <div className="select-month-header search-dates-header2">
            Skip to month
          </div>

          <MonthPicker
            minDate={new Date()}
            maxDate={moment(new Date()).add(2, "years").endOf("year").toDate()}
            date={startDate}
            onChange={(val) => this.setNewSearchDates(val)}
          />
        </div>

        <BookingCalendar />
        <Policies />
        <ItineraryWithDisabled isDisabled={this.props.isItineraryDisabled} />
        <BookingModal />
        <WarningModal />
        <ConfirmationModal
          isOpen={this.props.isConfirmationOpen}
          isLoading={this.props.isConfirmationLoading}
          loadingText="Please wait while booking is pending..."
          isProcessed={this.props.isConfirmationProcessed}
          processedText="Provisional booking has been successful. An email has been sent to you."
          isError={this.props.isConfirmationError}
          errorText="There was an error with the booking. This may be because there is no longer availability for the dates selected."
        />
      </div>
    );
  }
}

const ItineraryWithDisabled = WithDisabled(Itinerary);

const mapStateToProps = ({ booking, properties, lodges, bookingFilters }) => {
  const { bookingStatus } = booking;

  return {
    lodges: lodges ? lodges.lodges : [],
    selectedLodge: lodges.selectedLodge,
    searchedDates: bookingFilters.searchedDates,

    isCalendarDisabled:
      Object.keys(properties.properties).length === 0 ||
      !bookingFilters.isCalendar,
    isWizardDisabled:
      Object.keys(properties.properties).length === 0 ||
      !bookingFilters.isWizard,
    isItineraryDisabled:
      booking.lineItems.filter((lineItem) => !!lineItem.confirmed).length === 0,
    isConfirmationOpen: bookingStatus !== null,
    isConfirmationLoading: bookingStatus === "pending",
    isConfirmationProcessed: bookingStatus === "success",
    isConfirmationError: bookingStatus === "error",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSearchDates: () => dispatch(resetSearchDates()),
    setSearchDates: (startDate, endDate) =>
      dispatch(setSearchDates({ startDate, endDate })),
    setStateDates: (startDate, endDate) =>
      dispatch(setSearchDates({ startDate, endDate })),
    setCalendarRangeDates: () => dispatch(setCalendarRangeDates()),
    setWizardstate: (val) => dispatch(setWizardstate(val)),
    setSearchState: (val) => dispatch(setSearchState(val)),
    setLodge: (lodge) => dispatch(setLodge(lodge)),
    setProperties: (lodge) => dispatch(startSetProperties(lodge)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingCalendarPage);
