import React from "react";
import { connect } from "react-redux";
import { CardTitle } from "material-ui/Card";
import moment from "moment";
import { getNightsStayed } from "./../../../functions/dates";
const format = "YYYY-MM-DD";
const Header = ({ arrival, departure, propertyName, nights }) => (
  <div>
    <CardTitle
      title={<div className="bold-modal-header">{propertyName}</div>}
      subtitle={
        <div>
          <div className="modal-nights">{`${nights} ${
            nights === 1 ? "night" : "nights"
          }`}</div>
          <div />
          <div className="modal-dates">{`${arrival} - ${departure}`}</div>
        </div>
      }
    />
  </div>
);

const mapStateToProps = ({ booking }) => {
  return {
    arrival: moment(booking.arrival, format).format("Do MMMM YYYY"),
    departure: moment(booking.departure, format).format("Do MMMM YYYY"),
    propertyName: booking.propertyName,
    accomName: booking.accomName,
    nights: getNightsStayed(booking.arrival, booking.departure),
  };
};
export default connect(mapStateToProps)(Header);
