import update from "immutability-helper";
import moment from "moment";

const format = "YYYY-MM-DD";

const defaultBookingFilters = {
  searchedDates: {
    startDate: undefined,
    endDate: undefined,
  },
  calendarRangeDates: {
    startDate: undefined,
    endDate: undefined,
  },
  selectedDates: {
    startDate: undefined,
    endDate: undefined,
  },
  stateDates: {
    startDate: undefined,
    endDate: undefined,
  },
  numberOfRooms: "1",
  isWizard: true,
  isCalendar: true,
  isChildren: false,
  isGroup1: false,
  isGroup2: false,
  isGroup3: false,
  isGroup4: false,
  numberChildren: 0,
  numberAdults: 0,
  numberGuests: 0,
  isSearch: false,
};

export default (state = defaultBookingFilters, action) => {
  switch (action.type) {
    case "SET_SEARCH_DATES":
      return update(state, {
        searchedDates: {
          $set: {
            startDate: action.startDate,
            endDate: action.endDate,
          },
        },
      });

    case "SET_CALENDAR_RANGE_DATES": {
      if (!state.searchedDates.startDate || !state.searchedDates.endDate)
        return state;
      const startDate = moment(state.searchedDates.startDate, format).subtract(
        7,
        "days"
      );
      const endDate = startDate.clone().add(20, "days");

      return update(state, {
        calendarRangeDates: {
          $set: {
            startDate: startDate.format(format),
            endDate: endDate.format(format),
          },
        },
      });
    }

    case "MOVE_CALENDAR": {
      let { startDate, endDate } = state.calendarRangeDates;
      startDate = moment(startDate, format);
      endDate = moment(endDate, format);
      if (action.direction === "left") {
        startDate.subtract(7, "days");
        endDate.subtract(7, "days");
      } else if (action.direction === "right") {
        startDate.add(7, "days");
        endDate.add(7, "days");
      }

      return update(state, {
        calendarRangeDates: {
          $set: {
            startDate: startDate.format(format),
            endDate: endDate.format(format),
          },
        },
      });
    }
    case "SET_NUMBER_OF_ROOMS": {
      return { ...state, numberOfRooms: action.numberOfRooms };
    }
    case "SET_STATE_DATES":
      return update(state, {
        stateDates: {
          $set: {
            startDate: action.startDate,
            endDate: action.endDate,
          },
        },
      });
    case "SWITCH_VIEW": {
      if (action.view === "calendar") {
        return {
          ...state,
          isCalendar: !state.isCalendar,
        };
      } else if (action.view === "wizard") {
        return {
          ...state,
          isWizard: !state.isWizard,
        };
      } else if (action.view === "children") {
        return {
          ...state,
          isChildren: !state.isChildren,
        };
      }
      break;
    }
    case "WIZARD_CHANGE": {
      return {
        ...state,
        isWizard: action.val,
      };
    }

    case "SEARCH_CHANGE": {
      return {
        ...state,
        isSearch: action.val,
      };
    }
    case "SET_GUEST_NUMBER": {
      return {
        ...state,
        isGroup1: action.guests[0].Adults > 0 ? true : false,
        isGroup4: action.guests[1].Children > 0 ? true : false,
        isChildren: action.guests[1].Children > 0 ? true : false,
        numberChildren: action.guests[1].Children,
        numberAdults: action.guests[0].Adults,
        numberGuests: action.guests[0].Adults + action.guests[1].Children,
      };
      /*
            if (action.guest[0] === "Adults") {
                console.log(state.isGroup1)
                return {
                    ...state,
                    isGroup1: !state.isGroup1
                };
            } else if (action.guest[0] === "Adult Single") {
                return {
                    ...state,
                    isGroup2: !state.isGroup2
                };
            } else if (action.guest[0] === "Children 12-16") {
                return {
                    ...state,
                    isGroup3: !state.isGroup3
                };
            } else if (action.guest[0] === "Children 6-11") {
                return {
                    ...state,
                    isGroup4: !state.isGroup4
                };
            }
            break;*/
    }

    default:
      return state;
  }
};
