import _ from "lodash";

const groupByAccomType = (lineItems) => {
  let groupedLineItems = [];
  const accomNames = _.uniq(lineItems.map((lineItem) => lineItem.accomName));
  accomNames.forEach((accomName) => {
    let groupedByAccomName = [];
    lineItems.forEach((lineItem) => {
      if (lineItem.accomName === accomName) {
        groupedByAccomName.push(lineItem);
      }
    });
    groupedLineItems.push(groupedByAccomName);
  });
  return groupedLineItems;
};

export default groupByAccomType;
