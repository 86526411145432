import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";

export default class DatePicker extends React.Component {
  state = {
    startDate: this.props.startDate == undefined ? null : this.props.startDate,
    endDate: this.props.endDate == undefined ? null : this.props.endDate,
    focusedInput:
      this.props.startDate == undefined ? null : this.props.startDate,
  };

  onFocusChange(focusedInput) {
    this.setState({
      focusedInput: !focusedInput ? null : focusedInput,
    });
  }

  render() {
    return (
      <div style={{ display: "inline-block", width: 550 }}>
        <DateRangePicker
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            this.props.setSearchDates(
              startDate,
              endDate == undefined ? this.state.endDate : endDate
            );
            this.setState({ startDate, endDate });
          }}
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          displayFormat="DD MMM YYYY"
          hideKeyboardShortcutsPanel
        />
      </div>
    );
  }
}
