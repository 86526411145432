// getWizardResults takes in the properties, numberOfRooms the startDate and endDates,
// as well as flexi-dates (which is the number of days either side to look).
// It returns an object like this...

// {
//     exactMatches: [...],
//     closeMatches: [...]
// }

// Exact matches mean that the wizard found a combination that fits the dates
// specified. Close matches match dates that are within the flexi dates.

// Within each of those arrays is an an Object that looks like this...

// {
//     matches: [...]
//     isMixed: true
// }

// isMixed just says whether the matches are within the the same
// or if it has found a mixed combination of accomTypes.

// Then each match looks like this...

// {
//     propertyName: 'Pioneer Camp',
//     propertyId: 'RS9',
//     arrival: '2018-11-15',
//     departure: '2018-11-22',
//     accomGroups: [{
//         accomName: 'Camp Suites',
//         accomId: 'WB17',
//         noOfRooms: 3,
//         rateGroups: [
//             {
//               id: 'RS2',
//               desc: 'Adult',
//               abbrv: 'AD',
//               'class': '1',
//               'default': 1,
//               isVisible: true,
//               maxNumber: '2',
//               defaultNumber: '2',
//               number: '2'
//             },
//             {
//               id: 'RS3',
//               desc: 'Child 6 - 11',
//               abbrv: 'CH',
//               'class': '2',
//               'default': 1,
//               isVisible: true,
//               maxNumber: '2',
//               defaultNumber: '0',
//               number: '0'
//             }
//         ]
//     }]
// }

import getSelectionAvailability from "./getSelectionAvailability";
import moment from "moment";

const format = "YYYY-MM-DD";

// Gets the accomodation
const getMatchingAccomType = ({
  propertyName,
  propertyId,
  isChildren,
  accomType,
  rooms,
  max_capacity,
  startDate,
  endDate,
  propertyPolicy,
  isGroup1,
  isGroup2,
  isGroup3,
  isGroup4,
}) => {
  const { availability, rateGroups, name, id } = accomType;
  const availabilityArray = getSelectionAvailability(
    startDate,
    endDate,
    availability
  );
  //Adult
  if (isGroup1) {
    const adultRateGroup = Object.values(rateGroups).find((rateGroup) => {
      return rateGroup.id === "RS2";
    });
    if (!adultRateGroup) return null;

    if (!adultRateGroup.isVisible) return null;
  }

  //Adult single
  if (isGroup2) {
    const adultSingleRateGroup = Object.values(rateGroups).find((rateGroup) => {
      return rateGroup.id === "WB3";
    });
    if (!adultSingleRateGroup) return null;

    if (!adultSingleRateGroup.isVisible) return null;
  }

  //Children 6-11
  if (isGroup4) {
    const childRateGroup = Object.values(rateGroups).find((rateGroup) => {
      return rateGroup.id === "RS3";
    });
    if (!childRateGroup) return null;

    if (!childRateGroup.isVisible) return null;
  }

  const isAvailable = availabilityArray.every((night) => night >= rooms);
  if (isAvailable) {
    return {
      propertyName,
      propertyId,
      propertyPolicy,

      arrival: startDate,
      departure: endDate,
      accomGroups: [
        {
          accomName: name,
          accomId: id,
          rateGroups: Object.values(rateGroups),
          noOfRooms: rooms,
          max_capacity,
        },
      ],
    };
  }
  return null;
};

const makeAlternatingArray = (flexiDays) => {
  let alternatingArray = [0];

  for (let i = 1; i <= flexiDays; i++) {
    alternatingArray.push(-i);
    alternatingArray.push(i);
  }

  return alternatingArray;
};

const getExactOrCloseMatchingAccomType = ({
  propertyName,
  propertyId,
  isChildren,
  accomType,
  rooms,
  max_capacity,
  startDate,
  endDate,
  flexiDays,
  propertyPolicy,
  isGroup1,
  isGroup2,
  isGroup3,
  isGroup4,
}) => {
  const alternatingArray = makeAlternatingArray(flexiDays);
  for (let dayShift of alternatingArray) {
    const shiftedStartDate = moment(startDate, format)
      .clone()
      .add(dayShift, "days")
      .format(format);

    const shiftedEndDate = moment(endDate, format)
      .clone()
      .add(dayShift, "days")
      .format(format);

    const match = getMatchingAccomType({
      propertyName,
      propertyId,
      accomType,
      isChildren,
      rooms,
      propertyPolicy,
      max_capacity,
      startDate: shiftedStartDate,
      endDate: shiftedEndDate,
      isGroup1,
      isGroup2,
      isGroup3,
      isGroup4,
    });
    if (match) {
      match.exactMatch = dayShift === 0;
      return match;
    }
  }

  return false;
};

const getWizardResults = ({
  properties,
  rooms,
  startDate,
  endDate,
  isChildren,
  isGroup1,
  isGroup2,
  isGroup3,
  isGroup4,
  flexiDays,
  numberChildren,
  numberAdults,
  numberGuests,
}) => {
  let wizardResults = {
    exactMatches: [],
    closeMatches: [],
  };

  Object.values(properties).forEach((property) => {
    const { accomTypes } = property;
    let isAMatch = false;
    Object.values(accomTypes).forEach((accomType) => {
      const match = getExactOrCloseMatchingAccomType({
        propertyName: property.name,
        propertyId: property.id,
        accomType,
        max_capacity: accomType.max_capacity,
        rooms,
        startDate,
        endDate,
        flexiDays,
        propertyPolicy: property.policy,
        isChildren,
        isGroup1,
        isGroup2,
        isGroup3,
        isGroup4,
      });

      if (match) {
        const matchCapacity = checkCapacity({
          match,
          numberAdults,
          numberChildren,
          numberGuests,
        });

        if (matchCapacity) {
          isAMatch = true;
          if (match.exactMatch) {
            wizardResults.exactMatches.push(match);
          } else {
            wizardResults.closeMatches.push(match);
          }
        }
      }
    });

    if (!isAMatch) {
      const match = getMixedMatch({
        propertyName: property.name,
        propertyId: property.id,
        accomTypes,
        rooms,
        isChildren,
        startDate,
        endDate,
        flexiDays,
        propertyPolicy: property.policy,
        isGroup1,
        isGroup2,
        isGroup3,
        isGroup4,
      });
      if (match) {
        const matchCapacity = checkCapacity({
          match,
          numberAdults,
          numberChildren,
          numberGuests,
        });
        if (matchCapacity) {
          if (match.exactMatch) {
            wizardResults.exactMatches.push(match);
          } else {
            wizardResults.closeMatches.push(match);
          }
        }
      }
    }
  });
  return wizardResults;
};

const checkCapacity = ({
  match,
  numberAdults,
  numberChildren,
  numberGuests,
}) => {
  let maxCap = 0;
  let maxAdults = 0;
  let maxChildren = 0;

  Object.values(match.accomGroups).forEach((accomType) => {
    let numberRooms = accomType.noOfRooms;
    maxCap += Number(accomType.max_capacity) * numberRooms;
    Object.values(accomType.rateGroups).forEach((rateGroup) => {
      if (rateGroup.desc == "Adult") {
        maxAdults += Number(rateGroup.maxNumber) * numberRooms;
      } else if (rateGroup.desc == "Child 6 - 11") {
        maxChildren += Number(rateGroup.maxNumber) * numberRooms;
      }
    });
  });
  if (
    numberGuests > maxCap ||
    numberAdults > maxAdults ||
    numberChildren > maxChildren
  ) {
    return false;
  } else {
    return match;
  }

  //     const { accomTypes } = property;
  //     let isAMatch = false;
  //     Object.values(accomTypes).forEach(accomType => {
};
// This is going to start with the first accomType, if there is atleast one room available
// for the dates presented, its going to add this to the match. If not it's going to go to
// the next accomType in that property. It will keep going through until it has filled up
// the number of rooms needed. If it is unable to do this for the dates selected, it will
// then shift the start and end date back a day, and repeat this process from stratch.

const getMixedMatch = ({
  propertyName,
  propertyId,
  propertyPolicy,
  isChildren,
  accomTypes,
  rooms,
  startDate,
  endDate,
  flexiDays,
  isGroup1,
  isGroup2,
  isGroup3,
  isGroup4,
}) => {
  const alternatingArray = makeAlternatingArray(flexiDays);
  for (let dayShift of alternatingArray) {
    const shiftedStartDate = moment(startDate, format)
      .clone()
      .add(dayShift, "days")
      .format(format);

    const shiftedEndDate = moment(endDate, format)
      .clone()
      .add(dayShift, "days")
      .format(format);

    let roomsLeft = rooms;
    let accomGroups = [];

    for (let accomType of Object.values(accomTypes)) {
      const { availability, rateGroups, name, id, max_capacity } = accomType;

      const availabilityArray = getSelectionAvailability(
        shiftedStartDate,
        shiftedEndDate,
        availability
      );

      if (isGroup1) {
        const adultRateGroup = Object.values(rateGroups).find((rateGroup) => {
          return rateGroup.id === "RS2";
        });
        if (!adultRateGroup) continue;

        if (!adultRateGroup.isVisible) continue;
      }

      if (isGroup2) {
        const adultSingleRateGroup = Object.values(rateGroups).find(
          (rateGroup) => {
            return rateGroup.id === "WB3";
          }
        );
        if (!adultSingleRateGroup) continue;

        if (!adultSingleRateGroup.isVisible) continue;
      }

      if (isGroup4) {
        const childRateGroup = Object.values(rateGroups).find((rateGroup) => {
          return rateGroup.id === "RS3";
        });
        if (!childRateGroup) continue;

        if (!childRateGroup.isVisible) continue;
      }

      const noOfRoomsAvailable = availabilityArray.sort((a, b) => a - b)[0];

      if (noOfRoomsAvailable <= 0) continue;

      const noOfRooms =
        noOfRoomsAvailable > roomsLeft ? roomsLeft : noOfRoomsAvailable;

      accomGroups.push({
        accomName: name,
        accomId: id,
        max_capacity,
        rateGroups: Object.values(rateGroups),
        noOfRooms,
      });

      roomsLeft -= noOfRooms;

      if (roomsLeft === 0) {
        return {
          propertyName,
          propertyId,
          arrival: shiftedStartDate,
          departure: shiftedEndDate,
          accomGroups,
          propertyPolicy,
          exactMatch: dayShift === 0,
        };
      }
    }
  }

  return false;
};
export { getWizardResults as default, getExactOrCloseMatchingAccomType };
