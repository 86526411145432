import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import LodgeForceAPI from "./wrappers/LodgeForceAPI";

const AuthenticatedAppWrapper = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((tk) => LodgeForceAPI.setToken(tk));
    }
  }, [isAuthenticated]);

  return children;
};

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="auth.londolozi.com"
      clientId="ybUjDkA74GEOKv9yZOEcKIZatbzfZwbF"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="londolozi-trade-portal"
    >
      <AuthenticatedAppWrapper>{children}</AuthenticatedAppWrapper>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
