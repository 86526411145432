import React from "react";
import { connect } from "react-redux";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import Search from "material-ui/svg-icons/action/search";
import WithLoading from "./../../../hoc/WithLoading";

import { startAgentSearch } from "./../../../actions/agentManagement";

class AgentSearchBar extends React.Component {
  state = {
    text: "",
  };
  updateTextField = (e) => {
    e.preventDefault();
    const text = e.target.value;
    this.setState({ text });
  };

  componentDidMount() {
    this.setState({ text: this.props.searchWord });
  }

  render() {
    return (
      <div className="agent-search-wrap">
        <TextField
          className="search-agent"
          floatingLabelText="Search Agents"
          value={this.state.text}
          onChange={this.updateTextField}
          autoFocus={true}
          onKeyPress={({ which }) => {
            const enterKeyCode = 13;
            if (which === enterKeyCode) {
              this.props.search(this.state.text);
            }
          }}
        />
        <IconButtonWithLoading
          iconStyle={{ transform: "scale(1.5)" }}
          onClick={() => this.props.search(this.state.text)}
          isLoading={this.props.isLoading}
        >
          <Search />
        </IconButtonWithLoading>
      </div>
    );
  }
}

const IconButtonWithLoading = WithLoading(IconButton);

const mapStateToProps = ({ agentManagement }) => {
  return {
    searchWord: agentManagement.searchWord,
    isLoading: agentManagement.agentsLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (searchWord) => dispatch(startAgentSearch(searchWord)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentSearchBar);
