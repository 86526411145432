import React from "react";
import { connect } from "react-redux";
import { Card, CardTitle } from "material-ui/Card";
import { startAddStagingLineItem } from "./../../../actions/booking";
import FlatButton from "material-ui/FlatButton";
import { v4 as uuid } from "uuid";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Founderscampsuperior_img1 from "../../../assets/images/founders/superior/1.jpg";
import Founderscampsuperior_img2 from "../../../assets/images/founders/superior/2.jpg";
import Founderscampsuperior_img3 from "../../../assets/images/founders/superior/3.jpg";
import Founderscampsuperior_img4 from "../../../assets/images/founders/superior/4.jpg";
import Founderscampsuperior_img5 from "../../../assets/images/founders/superior/5.jpg";
import Founderscampsuperior_img6 from "../../../assets/images/founders/superior/6.jpg";
import Founderscampsuperior_img7 from "../../../assets/images/founders/superior/7.jpg";
import Founderscampsuperior_img8 from "../../../assets/images/founders/superior/8.jpg";
import Founderscampsuperior_img9 from "../../../assets/images/founders/superior/9.jpg";

import Founderscampsuperiorinterleading_img1 from "../../../assets/images/founders/superior-interleading/1.jpg";
import Founderscampsuperiorinterleading_img2 from "../../../assets/images/founders/superior-interleading/2.jpg";
import Founderscampsuperiorinterleading_img3 from "../../../assets/images/founders/superior-interleading/3.jpg";
import Founderscampsuperiorinterleading_img4 from "../../../assets/images/founders/superior-interleading/4.jpg";
import Founderscampsuperiorinterleading_img5 from "../../../assets/images/founders/superior-interleading/5.jpg";
import Founderscampsuperiorinterleading_img6 from "../../../assets/images/founders/superior-interleading/7.jpg";
import Founderscampsuperiorinterleading_img7 from "../../../assets/images/founders/superior-interleading/7.jpg";
import Founderscampsuperiorinterleading_img8 from "../../../assets/images/founders/superior-interleading/8.jpg";
import Founderscampsuperiorinterleading_img9 from "../../../assets/images/founders/superior-interleading/9.jpg";

import Founderscampsupfamily_img1 from "../../../assets/images/founders/superior-family/1.jpg";
import Founderscampsupfamily_img2 from "../../../assets/images/founders/superior-family/2.jpg";
import Founderscampsupfamily_img3 from "../../../assets/images/founders/superior-family/3.jpg";
import Founderscampsupfamily_img4 from "../../../assets/images/founders/superior-family/4.jpg";
import Founderscampsupfamily_img5 from "../../../assets/images/founders/superior-family/5.jpg";
import Founderscampsupfamily_img6 from "../../../assets/images/founders/superior-family/6.jpg";

import Founderscampfamily_img1 from "../../../assets/images/founders/family/1.jpg";
import Founderscampfamily_img2 from "../../../assets/images/founders/family/2.jpg";
import Founderscampfamily_img3 from "../../../assets/images/founders/family/3.jpg";
import Founderscampfamily_img4 from "../../../assets/images/founders/family/4.jpg";
import Founderscampfamily_img5 from "../../../assets/images/founders/family/5.jpg";
import Founderscampfamily_img6 from "../../../assets/images/founders/family/6.jpg";
import Founderscampfamily_img7 from "../../../assets/images/founders/family/7.jpg";
import Founderscampfamily_img8 from "../../../assets/images/founders/family/8.jpg";
import Founderscampfamily_img9 from "../../../assets/images/founders/family/9.jpg";

import Pioneercamp_img1 from "../../../assets/images/entrance-min.jpg";
import Pioneercamp_img2 from "../../../assets/images/living1-min.jpg";
import Pioneercamp_img3 from "../../../assets/images/living5-min.jpg";
import Pioneercamp_img4 from "../../../assets/images/living7-min.jpg";
import Pioneercamp_img5 from "../../../assets/images/londo_pioneer_boma-min.jpg";
import Pioneercamp_img6 from "../../../assets/images/passage-min.jpg";
import Pioneercamp_img7 from "../../../assets/images/pioneerbed1-min.jpg";
import Pioneercamp_img8 from "../../../assets/images/RGP_0746-min.jpg";
import Pioneercamp_img9 from "../../../assets/images/suite_living1-min.jpg";
import Pioneercamp_img10 from "../../../assets/images/suite_living2-min.jpg";
import Pioneercamp_img11 from "../../../assets/images/suite_terrace1-min.jpg";
import Pioneercamp_img12 from "../../../assets/images/terrace4-min.jpg";

import PrivateGraniteSuites_img1 from "../../../assets/images/2018.granite.suite1-min.jpg";
import PrivateGraniteSuites_img2 from "../../../assets/images/2018.granite.suite12-min.jpg";
import PrivateGraniteSuites_img3 from "../../../assets/images/2018.granite.suite14-min.jpg";
import PrivateGraniteSuites_img4 from "../../../assets/images/2018.granite.suite16-min.jpg";
import PrivateGraniteSuites_img5 from "../../../assets/images/2018.granite.suite2-min.jpg";
import PrivateGraniteSuites_img6 from "../../../assets/images/2018.granite.suite3-min.jpg";
import PrivateGraniteSuites_img7 from "../../../assets/images/2018.granite.suite4-min.jpg";
import PrivateGraniteSuites_img8 from "../../../assets/images/2018.granite.suite5-min.jpg";
import PrivateGraniteSuites_img9 from "../../../assets/images/2018.granite.suite7-min.jpg";
import PrivateGraniteSuites_img10 from "../../../assets/images/2018.granite.suite8-min.jpg";
import PrivateGraniteSuites_img11 from "../../../assets/images/2018.granite1-min.jpg";
import PrivateGraniteSuites_img12 from "../../../assets/images/2018.granite10-min.jpg";
import PrivateGraniteSuites_img13 from "../../../assets/images/2018.granite12-min.jpg";
import PrivateGraniteSuites_img14 from "../../../assets/images/2018.granite13-min.jpg";
import PrivateGraniteSuites_img15 from "../../../assets/images/2018.granite5-min.jpg";
import PrivateGraniteSuites_img16 from "../../../assets/images/2018.granite6-min.jpg";
import PrivateGraniteSuites_img17 from "../../../assets/images/2018.granite9-min.jpg";
import PrivateGraniteSuites_img18 from "../../../assets/images/Granite Suites Riverview Outside Bath-min.jpg";
import PrivateGraniteSuites_img19 from "../../../assets/images/pool_hero5-min.jpg";
import PrivateGraniteSuites_img20 from "../../../assets/images/Private Granite Suites - Outside Riverview bath-min.jpg";

import Treecamp_img1 from "../../../assets/images/feb17_food32.jpeg";
import Treecamp_img2 from "../../../assets/images/londo_treecamp11.jpeg";
import Treecamp_img3 from "../../../assets/images/londo_treecamp12.jpeg";
import Treecamp_img4 from "../../../assets/images/londo_treecamp13.jpeg";
import Treecamp_img5 from "../../../assets/images/londo_treecamp2.jpeg";
import Treecamp_img6 from "../../../assets/images/londo_treecamp8.jpeg";
import Treecamp_img7 from "../../../assets/images/londo_treecamp9.jpeg";
import Treecamp_img8 from "../../../assets/images/londo_treecamp_bathroom1.jpeg";
import Treecamp_img9 from "../../../assets/images/londo_treecamp_ext2.jpeg";
import Treecamp_img10 from "../../../assets/images/londo_treecamp_suite1.jpeg";
import Treecamp_img11 from "../../../assets/images/londo_treecamp_suite2.jpeg";
import Treecamp_img12 from "../../../assets/images/londo_treecamp_suite3.jpeg";
import Treecamp_img13 from "../../../assets/images/londo_treecamp_suite4.jpeg";
import Treecamp_img14 from "../../../assets/images/tree233.jpeg";

import VartyChaletcamp_img1 from "../../../assets/images/2018.varty.deck5.jpeg";
import VartyChaletcamp_img2 from "../../../assets/images/2018.varty.suite1.jpeg";
import VartyChaletcamp_img3 from "../../../assets/images/2018.varty.suite14.jpeg";
import VartyChaletcamp_img4 from "../../../assets/images/2018.varty.suite15.jpeg";
import VartyChaletcamp_img5 from "../../../assets/images/2018.varty.suite16.jpeg";
import VartyChaletcamp_img6 from "../../../assets/images/2018.varty.suite2.jpeg";
import VartyChaletcamp_img7 from "../../../assets/images/2018.varty.suite3.jpeg";
import VartyChaletcamp_img8 from "../../../assets/images/2018.varty.suite6a.jpeg";
import VartyChaletcamp_img9 from "../../../assets/images/2018.varty.suite7.jpeg";
import VartyChaletcamp_img10 from "../../../assets/images/2018.varty.suite9.jpeg";
import VartyChaletcamp_img11 from "../../../assets/images/2018.vartydeck1.jpeg";
import VartyChaletcamp_img12 from "../../../assets/images/2018.vartydeck3.jpeg";
import VartyChaletcamp_img13 from "../../../assets/images/londo_vartycamp_ext.jpeg";
import VartyChaletcamp_img14 from "../../../assets/images/may16_varty4.jpeg";

import VartySuperiorcamp_img1 from "../../../assets/images/2018.varty.deck5.jpeg";
import VartySuperiorcamp_img2 from "../../../assets/images/2018.vartydeck1.jpeg";
import VartySuperiorcamp_img3 from "../../../assets/images/2018.vartydeck3.jpeg";
import VartySuperiorcamp_img4 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-1.jpeg";
import VartySuperiorcamp_img5 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-10.jpeg";
import VartySuperiorcamp_img6 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-11.jpeg";
import VartySuperiorcamp_img7 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-12.jpeg";
import VartySuperiorcamp_img8 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-13.jpeg";
import VartySuperiorcamp_img9 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-2.jpeg";
import VartySuperiorcamp_img10 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-3.jpeg";
import VartySuperiorcamp_img11 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-4.jpeg";
import VartySuperiorcamp_img12 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-5.jpeg";
import VartySuperiorcamp_img13 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-8.jpeg";
import VartySuperiorcamp_img14 from "../../../assets/images/Londolozi_Varty 10_A Ritchie-9.jpeg";
import VartySuperiorcamp_img15 from "../../../assets/images/londo_vartycamp_ext.jpeg";
import VartySuperiorcamp_img16 from "../../../assets/images/may16_varty4.jpeg";

//To ADD a image specify the path in public. do production build, which will create a compressed version in public then switch path to public.
import General1 from "../../../assets/images/General Daytrippers-min.jpg";
import General2 from "../../../assets/images/General Pilot Rooms-min.jpg";
import General3 from "../../../assets/images/Landrovers Honeymoon-min.jpg";
import General4 from "../../../assets/images/Landrovers Private-min.jpg";
import General5 from "../../../assets/images/Lodge Internal Accounts-min.jpg";
import General6 from "../../../assets/images/Lodge Staff Accounts.jpg";

const format = "YYYY-MM-DD";

//const General = require( '../../../../src/images/General/general.jpg')
function mySwitchFunction(property, camp) {
  switch (property) {
    case "Founders Camp":
      if (camp.includes("Superior Chalets (Fam)")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsupfamily_img6} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsupfamily_img2} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsupfamily_img3} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsupfamily_img4} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsupfamily_img1} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsupfamily_img5} />
          </div>,
        ];
      } else if (camp.includes("Founders Superior Chalets")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img4} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img2} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img3} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img1} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img5} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img6} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img7} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img8} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperior_img9} />
          </div>,
        ];
      } else if (camp.includes("Superior Chalets Interleading")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img4} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img2} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img3} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img1} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img5} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img6} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img7} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img8} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampsuperiorinterleading_img9} />
          </div>,
        ];
      } else {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img4} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img2} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img3} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img1} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img5} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img6} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img7} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img8} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={Founderscampfamily_img9} />
          </div>,
        ];
      }

    case "Pioneer Camp":
      return [
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img7} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img1} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img2} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img3} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img4} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img5} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img6} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img8} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img9} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img10} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img11} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Pioneercamp_img12} />
        </div>,
      ];
    case "Private Granite Suites":
      return [
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img5} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img1} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img2} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img3} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img4} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img7} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img6} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img8} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img9} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img10} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img11} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img12} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img13} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img14} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img15} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img16} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img17} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img18} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img19} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={PrivateGraniteSuites_img20} />
        </div>,
      ];
    case "Tree Camp":
      return [
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img12} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img2} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img3} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img4} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img5} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img6} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img7} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img8} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img9} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img10} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img11} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img1} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img13} />
        </div>,
        <div className="item" style={{ width: "100%", height: "250px" }}>
          <img src={Treecamp_img14} />
        </div>,
      ];
    case "Varty Camp":
      if (camp.includes("Superior Chalets")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img4} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img1} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img3} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img2} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img5} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img6} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img7} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img8} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img9} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img10} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img11} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img12} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img13} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img14} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img15} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartySuperiorcamp_img16} />
          </div>,
        ];
      } else {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img2} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img1} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img3} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img4} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img5} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img6} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img7} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img8} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img9} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img10} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img11} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img12} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img13} />
          </div>,
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={VartyChaletcamp_img14} />
          </div>,
        ];
      }
    case "General":
      if (camp.includes("Day Trippers")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={General1} />
          </div>,
        ];
      } else if (camp.includes("Pilot")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={General2} />
          </div>,
        ];
      } else {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={General1} />
          </div>,
        ];
      }

    case "Landrovers":
      if (camp.includes("Honeymoon")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={General3} />
          </div>,
        ];
      } else if (camp.includes("Private")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={General4} />
          </div>,
        ];
      }

    case "Lodge: Miscellaneous":
      if (camp.includes("Internal")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={General5} />
          </div>,
        ];
      } else if (camp.includes("Staff")) {
        return [
          <div className="item" style={{ width: "100%", height: "250px" }}>
            <img src={General6} />
          </div>,
        ];
      }
  }
}

function campnamecleaned(property) {
  switch (property) {
    case "Founders Camp":
      return "founders-camp";
    case "Pioneer Camp":
      return "pioneer-camp";
    case "Private Granite Suites":
      return "private-granite-suites";
    case "Tree Camp":
      return "tree-camp";
    case "Varty Camp":
      return "varty-camp";
    case "General":
      return "general";
    case "Landrovers":
      return "landrovers";
    case "Lodge: Miscellaneous":
      return "lodge-miscellaneous";
  }
}

const Match = ({
  match,
  rooms,
  addStagingLineItems,
  showDates,
  searchParams,
}) => {
  const { propertyName, arrival, departure, accomGroups } = match;
  return (
    <Card
      className="result-camp"
      data-name={campnamecleaned(propertyName)}
      style={{ zIndex: 0 }}
    >
      <div className="match-card">
        <div style={{ width: "100%", height: "100%" }}>
          <OwlCarousel
            className="owl-theme"
            loop
            nav={true}
            dots={true}
            speed={500}
            dotsEach={true}
            items={1}
          >
            {mySwitchFunction(propertyName, accomGroups[0].accomName)}
          </OwlCarousel>
          {/* <CardTitle
                        title={propertyName}
                        subtitle={
                            <div>
                                <div>
                                    {accomGroups.length > 1
                                        ? "Mixed"
                                        : accomGroups[0].accomName}
                                </div>
                                {showDates && (
                                    <div>{`${moment(arrival, format).format(
                                        "Do MMM  YY"
                                    )} - ${moment(departure, format).format(
                                        "Do MMM YY"
                                    )}`}</div>
                                )}
                            </div>
                        }
                    /> */}
        </div>
        <div>
          <FlatButton
            fullWidth={true}
            style={{ height: "80px", textAlign: "left" }}
            onClick={() => addStagingLineItems(match, searchParams)}
            className={"view-button-test"}
          >
            {
              <div className="CampCarouselButton">
                <div className="CampCarouselButtonPropName">{propertyName}</div>
                <div className="CampCarouselButtonCampName">
                  {accomGroups.length > 1
                    ? "Mixed"
                    : accomGroups[0].accomName.includes("Camp Chalet")
                      ? "Camp Chalet"
                      : accomGroups[0].accomName}
                </div>
                {showDates && (
                  <div className="CampCarouselButtonDate">{`${moment(
                    arrival,
                    format
                  ).format("Do MMM  YY")} - ${moment(departure, format).format(
                    "Do MMM YY"
                  )}`}</div>
                )}
              </div>
            }
          </FlatButton>
        </div>
      </div>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addStagingLineItems: (match, searchParams) => {
      const { accomGroups, ...restOfMatch } = match;

      let allocatedChildren = 0;
      let allocatedAdults = 0;

      let maxChildren = 0;
      let maxAdults = 0;

      accomGroups.forEach((accomGroup) => {
        const {
          accomName,
          accomId,
          rateGroups,
          noOfRooms,
          max_capacity,
        } = accomGroup;
        for (let i = 0; i < noOfRooms; i++) {
          const lineId = uuid();
          rateGroups.map((rateGroup, i) => {
            if (rateGroups[i].id == "RS2") {
              maxAdults = Number(rateGroups[i].maxNumber);
            } else if (rateGroups[i].id == "RS3") {
              maxChildren = Number(rateGroups[i].maxNumber);
            }
          });

          if (searchParams.numberChildren == 0) {
            if (searchParams.numberAdults - allocatedAdults <= maxAdults) {
              rateGroups.map((rateGroup, i) => {
                if (rateGroups[i].id == "RS2") {
                  rateGroups[i].defaultNumber = String(
                    searchParams.numberAdults - allocatedAdults
                  );
                }
              });
              allocatedAdults += Number(
                searchParams.numberAdults - allocatedAdults
              );
            } else {
              rateGroups.map((rateGroup, i) => {
                if (rateGroups[i].id == "RS2") {
                  rateGroups[i].defaultNumber = String(maxAdults);
                }
              });
              allocatedAdults += Number(maxAdults);
            }
          } else {
            let roomallocA = 0;
            let roomallocC = 0;

            if (
              maxChildren >=
              searchParams.numberChildren - allocatedChildren
            ) {
              roomallocC = searchParams.numberChildren - allocatedChildren;
            } else {
              roomallocC = maxChildren;
            }

            if (roomallocA + roomallocC < max_capacity) {
              // 3 - 1 < 2 && 3-1+2<= 4

              if (
                searchParams.numberAdults - allocatedAdults - roomallocA <=
                maxAdults &&
                searchParams.numberAdults -
                allocatedAdults -
                roomallocA +
                roomallocC <=
                max_capacity
              ) {
                roomallocA +=
                  searchParams.numberAdults - allocatedAdults - roomallocA;
              } else {
                //4 - 2
                if (
                  max_capacity - roomallocC - roomallocA <=
                  searchParams.numberAdults - allocatedAdults
                ) {
                  roomallocA += max_capacity - roomallocC - roomallocA;
                }
              }
            }

            rateGroups.map((rateGroup, i) => {
              if (rateGroups[i].id == "RS2") {
                rateGroups[i].defaultNumber = String(roomallocA);
              }
              if (rateGroups[i].id == "RS3") {
                rateGroups[i].defaultNumber = String(roomallocC);
              }
            });

            allocatedAdults += roomallocA;
            allocatedChildren += roomallocC;
          }

          dispatch(
            startAddStagingLineItem({
              ...restOfMatch,
              max_capacity,
              accomName,
              accomId,
              rateGroups,
              lineId,
            })
          );
        }
      });
    },
  };
};

export default connect(undefined, mapDispatchToProps)(Match);
