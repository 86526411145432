import { combineReducers } from "redux";
import propertiesReducer from "./properties";
import bookingFiltersReducer from "./bookingFilters";
import lodgesReducer from "./lodges";
import bookingReducer from "./booking";
import userReducer from "./user";
import agentManagementReducer from "./agentManagement";
import bookingManagementReducer from "./bookingManagement";

const appReducer = combineReducers({
  properties: propertiesReducer,
  bookingFilters: bookingFiltersReducer,
  lodges: lodgesReducer,
  booking: bookingReducer,
  user: userReducer,
  agentManagement: agentManagementReducer,
  bookingManagement: bookingManagementReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case "USER_LOGOUT": {
      state = undefined;
      return appReducer(state, action);
    }

    default:
      return appReducer(state, action);
  }
};

export { appReducer as default, rootReducer };
