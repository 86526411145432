import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
// import { Card, CardText } from "material-ui/Card";
import { Card, CardTitle, CardText, CardHeader } from "material-ui/Card";
import { Element } from "react-scroll";
import TextField from "material-ui/TextField";
import Scroll from "react-scroll";
import Checkbox from "material-ui/Checkbox";
import RaisedButton from "material-ui/RaisedButton";
import { DropDown } from "./../../../shared-components/DropDown";
import { startMakeBooking } from "./../../../actions/booking";
import { getRole, getEmail } from "./../../../functions/getRole";
import NotesSection from "./NotesSection";
import BookingTable from "./BookingTable";
import Toggle from "material-ui/Toggle";
import details from "material-ui/svg-icons/image/details";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TableIcon from "@material-ui/icons/FormatListBulleted";
import AppsIcon from "@material-ui/icons/Apps";
import Switch from "../../../components/Switch";

let scroller = Scroll.scroller;
let originatorElement;
let switchElement;

class Itinerary extends React.Component {
  state = {
    bookingName: "",
    generalNotes: "",
    guestNotes: "",
    canBook: true,
    noBookingNameError: "",
    originator: "",
    repeatGuest: false,
    nationality: "",
    role: getRole(),
    email: getEmail() || "",
    isVisible: false,
    isVisibleGuest: false,
    agency: "",
    privacyAccepted: false,
  };

  updateTextField = (myState) => (e) => {
    const notes = e.target.value;
    this.setState({ [myState]: notes });
  };

  updateRepeat = () => {
    this.setState({ repeatGuest: !this.state.repeatGuest });
  };

  toggleMoreDetails = (v) => {
    let valueTog = v.target.value;
    if (valueTog === "left") {
      this.setState({ isVisible: !this.state.isVisible });
    } else {
      this.setState({ isVisibleGuest: !this.state.isVisibleGuest });
    }
  };

  scrollToHere = () => {
    scroller.scrollTo("itinerary", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -100,
    });
  };

  changeNationality = (v) => {
    this.setState({ nationality: v });
  };

  handleAgencySwitch = (event, view) => {
    this.setState({ agency: view });
  };

  handleBookingRequest = (e) => {
    e.preventDefault();
    if (!this.state.bookingName) {
      this.setState({
        noBookingNameError: "Please provide a booking name",
      });

      this.scrollToHere();
    } else {
      this.setState({ canBook: false });
      const { lineItems, lodge } = this.props;
      const {
        bookingName,
        generalNotes,
        guestNotes,
        originator,
        repeatGuest,
        nationality,
        agency,
      } = this.state;
      this.props
        .makeBooking({
          lineItems,
          lodge,
          bookingName,
          generalNotes,
          guestNotes,
          originator,
          repeatGuest,
          nationality,
          agency,
        })
        .then(() => {
          return this.props.history.push("/");
        })
        .catch((e) => e);
    }
  };
  render() {
    originatorElement = (
      <TextField
        floatingLabelText="Orginator"
        value={this.state.orginator}
        onChange={this.updateTextField("originator")}
        floatingLabelFixed
        placeholder="Full Name"
        className="itinerary-dropdowns-fonts"
      />
    );
    if (
      this.state.email.includes("@rhinoafrica.com") ||
      this.state.email.includes("@iconexpeditions.com")
    ) {
      switchElement = (
        <div className="switchAgency">
          <ToggleButtonGroup
            value={this.state.agency}
            exclusive
            onChange={this.handleAgencySwitch}
          >
            <ToggleButton value="Rhino">Rhino Africa</ToggleButton>
            <ToggleButton value="Icon">Icon Expeditions</ToggleButton>
          </ToggleButtonGroup>
        </div>
      );
    } else {
      switchElement = <div></div>;
    }

    return (
      <Element name="itinerary">
        <Card className="booking-card">
          <TextField
            floatingLabelText="Last and First name of Booking (Example: Smith, Jennifer x 2)"
            floatingLabelFixed
            placeholder=""
            value={this.state.bookingName}
            onChange={this.updateTextField("bookingName")}
            errorText={this.state.noBookingNameError}
            autoFocus={true}
            className="itinerary-dropdowns-fonts"
          />

          <div className="itinerary-wrap">
            <BookingTable />

            <div className="itinerary-form">
              <div
                className="itinerary-form-extra"
                style={{
                  display: "inline-block",
                  width: "100%",
                  float: "left",
                  paddingBottom: "20px",
                }}
              >
                <div
                  className="itinerary-fieldgroup"
                  style={{
                    width: "70%",
                    display: "inline-block",
                    textAlign: "left",
                    float: "left",
                  }}
                >
                  {originatorElement}
                </div>

                <div
                  className="itinerary-fieldgroup"
                  style={{
                    width: "30%",
                    display: "inline-block",
                    float: "right",
                  }}
                >
                  <DropDown
                    floatingLabelText={"Guest Nationality"}
                    onChange={(e, i, v) => this.changeNationality(v)}
                    items={[
                      "Qatar",
                      "Costa Rica",
                      "Bulgaria",
                      "Seychelles",
                      "Iran",
                      "Paraquay",
                      "Uruguay",
                      "Argentina",
                      "Ireland",
                      "Israel",
                      "Italy",
                      "Japan",
                      "Kenya",
                      "Lesotho",
                      "Madagascar",
                      "Malawi",
                      "Malaysia",
                      "Australia",
                      "Mauritius",
                      "Austria",
                      "Mozambique",
                      "Namibia",
                      "Netherlands",
                      "New Zealand",
                      "Bahamas",
                      "Norway",
                      "Bangladesh",
                      "Barbados",
                      "South Africa",
                      "Spain",
                      "Sri Lanka",
                      "Swaziland",
                      "Sweden",
                      "Switzerland",
                      "Tanzania",
                      "Belgium",
                      "Belize",
                      "Uganda",
                      "United Kingdom",
                      "United States",
                      "Zambia",
                      "Zimbabwe",
                      "Hong Kong",
                      "Russia",
                      "Bermuda",
                      "Cayman Island",
                      "Greece",
                      "Portugal",
                      "Turkey",
                      "Estonia",
                      "Lativa",
                      "Venezuela",
                      "Czech Republic",
                      "South Korea",
                      "Mixed",
                      "Saudi Arabia",
                      "Columbian",
                      "Thailand",
                      "Jordan",
                      "Singapore",
                      "Kuwait",
                      "Ghana",
                      "Lebanon",
                      "Scotland",
                      "Luxembourg",
                      "Phillipines",
                      "Panama",
                      "Poland",
                      "Ethopia",
                      "Zululand",
                      "Hungary",
                      "Equatorial Guinea",
                      "Morocco",
                      "Cypris",
                      "South America",
                      "West Indies",
                      "Maltese",
                      "Hawaii",
                      "Puerto Rico",
                      "Asia",
                      "Oman",
                      "Ukrane",
                      "Texas",
                      "Indonesia",
                      "Serbia",
                      "Botswana",
                      "Cameroon",
                      "Monaco",
                      "Brazil",
                      "Burundi",
                      "Canada",
                      "China",
                      "Congo",
                      "Cuba",
                      "Denmark",
                      "Angola",
                      "Egypt",
                      "Finland",
                      "France",
                      "Germany",
                      "Antigua and Barbuda",
                      "India",
                      "United Arab Emerites",
                      "Mexico",
                      "Lithuania",
                      "Nigeria",
                      "Chili",
                      "Peru",
                      "Pakistan",
                      "Scandinavia",
                      "Croatia",
                      "Slovakia",
                      "Korea (Republic Of)",
                    ].sort()}
                    selectedItem={this.state.nationality}
                    className="itinerary-dropdowns-fonts"
                    style={{
                      maxWidth: 300,
                      marginBottom: "20px",
                      textAlign: "left",
                      fontSize: 18,
                    }}
                  />
                </div>

                <div
                  className="itinerary-fieldgroup agencytoggle"
                  style={{
                    width: "100%",
                    display: "inline-block",
                    textAlign: "left",
                    float: "left",
                  }}
                >
                  {switchElement}
                </div>
              </div>

              <div className="itinerary-form-notes">
                <div className="itinerary-textarea">
                  <NotesSection
                    name="Reservation Notes"
                    text={this.state.generalNotes}
                    myState={"generalNotes"}
                    updateTextField={this.updateTextField}
                    className="itinerary-textarea itinerary-dropdowns-fonts "
                  />
                  <Card
                    className="itinerary-details"
                    style={{ padding: "10px" }}
                  >
                    <CardHeader
                      title="More Details"
                      actAsExpander={true}
                      showExpandableButton={true}
                    />
                    <CardText expandable={true}>
                      {
                        "Please include your enquiry reference number || Arrival and Departure details || Pre and Post Londolozi Trip details"
                      }
                    </CardText>
                  </Card>
                </div>
                <div className="itinerary-textarea">
                  <NotesSection
                    className="itinerary-textarea itinerary-dropdowns-fonts  "
                    name="Guest Information"
                    text={this.state.guestNotes}
                    myState={"guestNotes"}
                    updateTextField={this.updateTextField}
                  />
                  <Card
                    className="itinerary-details"
                    style={{ padding: "10px" }}
                  >
                    <CardHeader
                      title="More Details"
                      actAsExpander={true}
                      showExpandableButton={true}
                    />
                    <CardText expandable={true}>
                      {
                        "Guest names || Type of room booked || Double bed required (1 king size bed) OR Twin room (2 x extra length 3/4 beds) || First visit to Londolozi OR Repeat Guest || Any dietary requirements as well and special requests or interestes"
                      }
                    </CardText>
                  </Card>
                </div>
                <Checkbox
                  className=" itinerary-checkbox"
                  style={{ marginBottom: "32px", marginTop: "10px" }}
                  label="Repeat guest"
                  value={this.state.repeatGuest}
                  onCheck={() => this.updateRepeat()}
                />

                <div style={{ margin: "22px 0 40px" }}>
                  <Switch
                    name="privacy"
                    id="privacy"
                    checked={this.state.privacyAccepted}
                    onChange={() =>
                      this.setState({
                        privacyAccepted: !this.state.privacyAccepted,
                      })
                    }
                  >
                    <span className="text">
                      I have read and understood the{" "}
                      <a
                        href="https://www.londolozi.com/en/contact/privacy-policy"
                        className="link"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </Switch>
                </div>
              </div>
            </div>

            <RaisedButton
              className="submitbookingrequest"
              label="Submit Booking Request"
              fullWidth={true}
              primary={true}
              onClick={this.handleBookingRequest}
              disabled={!this.state.canBook || !this.state.privacyAccepted}
            />
          </div>
        </Card>
      </Element>
    );
  }
}

const mapStateToProps = ({ booking }) => {
  const { lineItems, lodge } = booking;
  return {
    lineItems,
    lodge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeBooking: ({
      lodge,
      lineItems,
      bookingName,
      generalNotes,
      guestNotes,
      originator,
      repeatGuest,
      nationality,
      agency,
    }) =>
      dispatch(
        startMakeBooking({
          lodge,
          lineItems,
          bookingName,
          generalNotes,
          guestNotes,
          originator,
          repeatGuest,
          nationality,
          agency,
        })
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Itinerary)
);
