import lodgeForce from "./../wrappers/LodgeForceAPI";

const setLodges = (lodges) => ({
  type: "SET_LODGES",
  lodges,
});

export const selectLodge = (lodge) => ({
  type: "SELECT_LODGE",
  lodge,
});

export const startSetLodges = () => {
  return (dispatch) => {
    return lodgeForce.get(`/agentaccess`).then((lodgeList) => {
      dispatch(setLodges(lodgeList));
    });
  };
};
