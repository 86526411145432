import fetch from "node-fetch";

class LodgeForceAPI {
  token = "";

  constructor() {}

  setToken(token) {
    this.token = token;
  }

  post(route, body) {
    return fetch(route, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((res) => {
            throw res;
          });
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  get(route) {
    return fetch(route, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((res) => {
            throw res;
          });
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new LodgeForceAPI();
