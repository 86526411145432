import React from "react";
import lodgeForce from "./../../wrappers/LodgeForceAPI";
import validateChangePasswords from "./../../functions/validateChangePasswords";
import ChangeForm from "./ChangeForm";

export default class ChangePassword extends React.Component {
  state = {
    redirect: false,
    errors: {},
    passwords: {
      pass1: "",
      pass2: "",
    },
    isPassVisible: false,
    header: "Change Password",
    message: "",
  };

  processForm = (event) => {
    event.preventDefault();

    const { message, success } = validateChangePasswords(this.state.passwords);
    this.setState({ message });
    if (success) {
      const { resetPasswordToken } = this.props.match.params;
      const password = this.state.passwords.pass1;
      this.setState({ header: "Changing password..." });
      return lodgeForce
        .post("/users/reset_password", {
          resetPasswordToken,
          password,
        })
        .then(() => {
          this.setState({
            header: "Password Change Successful!",
            passwords: { pass1: "", pass2: "" },
          });
          setTimeout(() => {
            this.props.history.push("/login");
          }, 1000);
        })
        .catch((e) => {
          if (e.name === "TokenExpiredError") {
            this.setState({
              header:
                "Token has expired. You will shortly be redirected to the reset password instructions...",
            });
            setTimeout(() => {
              this.props.history.push("/forgot_password");
            }, 4000);
          } else {
            this.setState({
              header:
                "An error has occured. Please try again or contact a Londolozi administrator.",
              passwords: { pass1: "", pass2: "" },
            });
          }
        });
    }
  };

  toggleVisiblity = () => {
    this.setState(({ isPassVisible }) => {
      return {
        isPassVisible: !isPassVisible,
      };
    });
  };

  changePass = (event) => {
    const pass = event.target.name;
    const passwords = this.state.passwords;
    passwords[pass] = event.target.value;
    this.setState(() => ({ passwords }));
  };

  render() {
    return (
      <ChangeForm
        header={this.state.header}
        onSubmit={this.processForm}
        onChange={this.changePass}
        message={this.state.message}
        passwords={this.state.passwords}
        isSendDisabled={this.state.isSendDisabled}
        isPassVisible={this.state.isPassVisible}
        toggle={this.toggleVisiblity}
      />
    );
  }
}
