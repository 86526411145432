import numeral from "numeral";

const formatRate = (value) => {
  const format = "0,0.00";
  const symbol = "ZAR";
  return `${symbol} ${numeral(value).format(format)}`;
};

const getTotal = (lineItems, type, format) => {
  if (lineItems.some((lineItem) => !lineItem.rate)) return undefined;
  if (lineItems.length === 1) {
    return format === "noFormat"
      ? lineItems[0].rate[type]
      : formatRate(lineItems[0].rate[type]);
  }
  const rate = lineItems
    .map((lineItem) => lineItem.rate[type])
    .reduce((a, b) => a + b);

  return format === "noFormat" ? rate : formatRate(rate);
};

export { formatRate as default, getTotal };
