import React from "react";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { CardText, CardTitle, Card } from "material-ui/Card";
import ReactDOM from "react-dom";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";

const DropDown = ({
  floatingLabelText,
  onChange,
  items,
  selectedItem,
  className,
  style,
  onKeyPress,
}) => {
  return (
    <div>
      <SelectField
        floatingLabelText={floatingLabelText}
        value={selectedItem}
        onChange={onChange}
        className={className && className}
        style={style && style}
      >
        {items.map((item, i) => (
          <MenuItem value={item} primaryText={item} key={i} />
        ))}
      </SelectField>
    </div>
  );
};

const DropDownMultiple = ({
  multiple,
  floatingLabelText,
  onChange,
  items,
  selectedItem,
  className,
  style,
}) => {
  return (
    <div>
      <SelectField
        multiple
        floatingLabelText={floatingLabelText}
        value={selectedItem}
        onChange={onChange}
        className={className && className}
        style={style && style}
      >
        {items.map((item, i) => (
          <MenuItem value={item} primaryText={item} key={i} />
        ))}
      </SelectField>
    </div>
  );
};

const DropDownGuests = ({
  className,
  open,
  onClose,
  onOpen,
  onClick,
  style,
  adultcount,
  children1count,
  children2count,
  guestCount,
  donebuttonclick,
}) => {
  return (
    <div>
      <Select
        className="dropdownsearch"
        disableUnderline={true}
        value="hidden"
        // onChange={handleChange}
        open={open}
        onClose={(e) => {
          if (e.target && e.target.nodeName !== "BUTTON") {
            onClose();
          }
        }}
        onOpen={onOpen}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
      >
        <MenuItem value="hidden" className="config-dropdown-hidden">
          <em>
            {guestCount} {guestCount > 1 ? "guests" : "guest"}
          </em>
        </MenuItem>

        <div className="room-config-search">
          <CardText className="room-config-select-dropdown" key="1">
            <div className="search-config-headers">Adults</div>
            <div className="search-config-incrementbox">
              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(
                      adultcount - 1 < 0 ? 0 : adultcount - 1,
                      children1count,
                      children2count
                    );
                  }}
                >
                  -
                </button>
              </span>
              <button className="incrbuttonmiddle">{adultcount}</button>

              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(adultcount + 1, children1count, children2count);
                  }}
                >
                  +
                </button>
              </span>
            </div>
          </CardText>

          <CardText
            className="room-config-select-dropdown"
            //style={{ width: "15rem" }}
          >
            <div className="search-config-headers">
              Children
              <p className="search-config-subtext">Ages 6-11</p>
            </div>
            <div className="search-config-incrementbox">
              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(
                      adultcount,
                      children1count - 1 < 0 ? 0 : children1count - 1,
                      children2count
                    );
                  }}
                >
                  -
                </button>
              </span>
              <button className="incrbuttonmiddle">{children1count}</button>

              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(adultcount, children1count + 1, children2count);
                  }}
                >
                  +
                </button>
              </span>
            </div>
          </CardText>

          <CardText
            className="room-config-select-dropdown"
            //style={{ width: "15rem" }}
          >
            <div className="search-config-headers">
              Children
              <p className="search-config-subtext">Ages 12-16</p>
            </div>
            <div className="search-config-incrementbox">
              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(
                      adultcount,
                      children1count,
                      children2count - 1 < 0 ? 0 : children2count - 1
                    );
                  }}
                >
                  -
                </button>
              </span>
              <button className="incrbuttonmiddle">{children2count}</button>

              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(adultcount, children1count, children2count + 1);
                  }}
                >
                  +
                </button>
              </span>
            </div>
          </CardText>

          <CardText className="search-config-box-done">
            <button
              className="search-config-box-donebutton"
              onClick={donebuttonclick}
            >
              Done
            </button>
          </CardText>
        </div>
      </Select>
    </div>
  );
};

let roomcount = 0;
const DropDownRooms = ({
  className,
  open,
  onClose,
  onOpen,
  onClick,
  style,
  roomCount,
  donebuttonclick,
}) => {
  return (
    <div>
      <Select
        className="dropdownsearch"
        disableUnderline={true}
        value="hidden"
        // onChange={handleChange}
        open={open}
        onClose={(e) => {
          if (e.target && e.target.nodeName !== "BUTTON") {
            onClose();
          }
        }}
        onOpen={onOpen}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
      >
        <MenuItem value="hidden" className="config-dropdown-hidden">
          <em>
            {roomCount} {roomCount > 1 ? "rooms" : "room"}
          </em>
        </MenuItem>
        <div className="room-config-search">
          <CardText
            className="room-config-select-dropdown"
            //style={{ width: "15rem" }}
          >
            <div className="search-config-headers">Rooms</div>
            <div className="search-config-incrementbox">
              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(roomCount - 1 < 1 ? 1 : roomCount - 1);
                  }}
                >
                  -
                </button>
              </span>
              <button className="incrbuttonmiddle">{roomCount}</button>

              <span>
                <button
                  className="incr-dec-button"
                  onClick={(v) => {
                    onClick(roomCount + 1 > 10 ? 10 : roomCount + 1);
                  }}
                >
                  +
                </button>
              </span>
            </div>
          </CardText>

          <CardText className="search-config-box-done">
            <button
              className="search-config-box-donebutton"
              onClick={donebuttonclick}
            >
              Done
            </button>
          </CardText>
        </div>
      </Select>
    </div>
  );
};
export { DropDown, DropDownMultiple, DropDownGuests, DropDownRooms };
