import React from "react";
import londoloziLive from "./../../wrappers/LondoloziLiveApi";
import { Card, CardTitle } from "material-ui/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Link, withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left";

class MediaGallery extends React.Component {
  state = {
    message: "Loading...",
    error: false,
  };

  componentDidMount() {
    return londoloziLive
      .get(`pages`, `2`)
      .then((res) => {
        this.setState({
          message: res.content.rendered,
        });
      })
      .catch(({ message }) => alert(message));
  }

  render() {
    return (
      <div>
        <div style={{ height: "110px" }}></div>
        <div id={"view-manage-bookings"}>
          <Card className="booking-card">
            <CardTitle
              title="Gallery & Media"
              subtitle="Select a category below or view more images below"
            />
            <IconButton aria-label="Delete" onClick={this.props.history.goBack}>
              <BackIcon /> Back
            </IconButton>

            <Grid container spacing={24}>
              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase
                    component={Link}
                    to="/trade/mediaGallery/founders"
                  >
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/founders.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/founders"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      Founders Camp
                    </Typography>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase component={Link} to="/trade/mediaGallery/pioneer">
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/pioneer.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/pioneer"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      Pioneer Camp
                    </Typography>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase component={Link} to="/trade/mediaGallery/granite">
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/granite.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/granite"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      Private Granite Suites
                    </Typography>
                  </Link>
                </Paper>
              </Grid>

              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase component={Link} to="/trade/mediaGallery/tree">
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/treecamp.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/tree"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      Tree Camp
                    </Typography>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase component={Link} to="/trade/mediaGallery/varty">
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/varty.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/varty"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      Varty Camp
                    </Typography>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase
                    component={Link}
                    to="/trade/mediaGallery/onSafari"
                  >
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/on-safari.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/onSafari"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      On Safari
                    </Typography>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase
                    component={Link}
                    to="/trade/mediaGallery/experience"
                  >
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/londolozi-experience.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/experience"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      Londolozi Experience
                    </Typography>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={"galleryPaper"}>
                  <ButtonBase
                    component={Link}
                    to="/trade/mediaGallery/wildlife"
                  >
                    <img
                      className={"galleryImg"}
                      alt="complex"
                      src="/images/tiles/wildlife.jpg"
                    />
                  </ButtonBase>
                  <br />
                  <br />
                  <Link to={"/trade/mediaGallery/wildlife"}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className={"fontOverride"}
                    >
                      Wildlife
                    </Typography>
                  </Link>
                </Paper>
              </Grid>
            </Grid>

            <CardContent
              id={"remoteText"}
              dangerouslySetInnerHTML={{ __html: this.state.message }}
            />
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(MediaGallery);
