import React from "react";

const WithRoleCheck = (Component) => {
  return ({ requiredRole, userRole, ...props }) => {
    return requiredRole === undefined || requiredRole === userRole ? (
      <Component {...props} />
    ) : null;
  };
};

export default WithRoleCheck;
