import React from "react";
import { connect } from "react-redux";
import { Card, CardTitle } from "material-ui/Card";

import {
  startSetAgentsWithAccess,
  selectAgentWithAccess,
} from "../../../actions/agentManagement";
import ReactTable from "react-table";
import "react-table/react-table.css";

class AgentTable extends React.Component {
  state = {
    agents: [],
  };

  defaultSearchFilter = (filter, row) => {
    if (!row[filter.id]) {
      return false;
    }

    const searchFilter = filter.value.trim().split(" ").join().toLowerCase();
    const rowValue = row[filter.id].trim().split(" ").join().toLowerCase();

    return rowValue.includes(searchFilter);
  };

  componentDidMount() {
    this.props
      .setAgentsWithAccess()
      .then((agents) => this.setState({ agents }))
      .catch((e) => console.log(e));
  }
  render() {
    return (
      <Card className="booking-card">
        <CardTitle
          title="Agent info"
          subtitle="Click on an agent to view more details or remove."
        />
        <ReactTable
          data={this.props.data}
          defaultFilterMethod={this.defaultSearchFilter}
          columns={[
            {
              Header: "Name",
              columns: [
                { Header: "First Name", accessor: "firstName" },
                {
                  Header: "Last Name",
                  id: "lastName",
                  accessor: (d) => d.lastName,
                },
              ],
            },
            {
              Header: "Info",
              columns: [
                {
                  Header: "Agency",
                  id: "agentName",
                  accessor: (d) => d.access.agentName,
                },
                { Header: "Email", accessor: "email" },
              ],
            },
          ]}
          defaultPageSize={10}
          filterable
          className="-striped -highlight"
          getTrProps={(state, rowInfo) => {
            return {
              onClick: () => {
                const { id } = rowInfo.original;
                this.props.selectAgentWithAccess(id);
              },
              style: { cursor: "pointer" },
            };
          }}
          onSortedChange={(c, s) => {
            document.activeElement.blur();
          }}
        />
        <br />
      </Card>
    );
  }
}

const mapStateToProps = ({ agentManagement }) => {
  const { agentsWithAccess } = agentManagement;
  const data = agentsWithAccess ? agentsWithAccess : [];
  return {
    data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAgentsWithAccess: () => dispatch(startSetAgentsWithAccess()),
    selectAgentWithAccess: (id) => dispatch(selectAgentWithAccess(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentTable);
