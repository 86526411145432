const defaultLodges = {
  lodges: "Londolozi",
  selectedLodge: "Londolozi",
};

export default (state = defaultLodges, action) => {
  switch (action.type) {
    case "SET_LODGES":
      return { ...state, lodges: action.lodges };

    case "SELECT_LODGE":
      return { ...state, selectedLodge: action.lodge };
    default:
      return state;
  }
};
