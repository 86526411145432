import React from "react";

export default ({
  totalRate,
  totalCommission,
  totalNett,
  commPercentage,
  totalLevi,
}) => (
  <table className="itinerary-table">
    <thead className="Itinerary__header">
      <tr>
        <th />
        <th>Total</th>
      </tr>
    </thead>
    <tbody className="Itinerary__body">
      <tr>
        <th>Payable by Client:</th>
        <th>{totalRate}</th>
      </tr>
      <tr>
        <th>Payable to Lodge:</th>
        <th>{totalNett}</th>
      </tr>
      <tr>
        <th>{`Commission (${commPercentage}):`}</th>
        <th>{totalCommission}</th>
      </tr>
      <tr>
        <th>{`Sabi Sands Levy:`}</th>
        <th>{totalLevi}</th>
      </tr>
    </tbody>
  </table>
);
