import React from "react";

const DateHeader = ({ months }) => {
  return (
    <tr className="header">
      <td className="room-type" rowSpan="3">
        Room Type
      </td>
      <td className="price" rowSpan="3">
        Price
      </td>
      {months.map((month, i) => (
        <td className="month" colSpan={month.colSpan} key={i}>
          {month.display}
        </td>
      ))}
    </tr>
  );
};

export default DateHeader;
