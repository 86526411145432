import update from "immutability-helper";
import _ from "lodash";
import getDatesStayed from "./../functions/getDatesStayed";

const defaultProperties = {
  properties: {},
  isSearching: false,
};
export default (state = defaultProperties, action) => {
  switch (action.type) {
    case "SET_PROPERTIES":
      return { properties: action.properties, isSearching: false };

    case "START_SEARCH":
      return {
        ...state,
        isSearching: true,
      };

    case "CHANGE_AVAILABILITY": {
      const {
        numberOfRooms,
        arrival,
        departure,
        accomName,
        propertyName,
        isIncreasingAvailability,
      } = action;
      const availability =
        state.properties[propertyName].accomTypes[accomName].availability;
      const datesStayed = getDatesStayed(arrival, departure);
      const selectedAvailability = _.pick(availability, datesStayed);
      Object.keys(selectedAvailability).forEach((date) => {
        if (isIncreasingAvailability) {
          selectedAvailability[date] += numberOfRooms;
        } else {
          selectedAvailability[date] -= numberOfRooms;
        }
      });
      return update(state, {
        properties: {
          [propertyName]: {
            accomTypes: {
              [accomName]: {
                availability: { $merge: selectedAvailability },
              },
            },
          },
        },
      });
    }

    case "SET_BLOCK_DATA": {
      const { propertyName, accomName, blockObject } = action;
      const updateType = state.properties[propertyName].accomTypes[accomName]
        .blockObject
        ? "$merge"
        : "$set";
      return update(state, {
        properties: {
          [propertyName]: {
            accomTypes: {
              [accomName]: {
                blockObject: {
                  [updateType]: blockObject,
                },
              },
            },
          },
        },
      });
    }

    case "CLEAR_PROPERTIES": {
      return defaultProperties;
    }

    default:
      return state;
  }
};
