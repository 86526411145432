import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Card, CardTitle } from "material-ui/Card";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  image: {},
  img: {
    margin: "auto",
    maxWidth: "100%",
  },
});

function TradeDashboard(props) {
  const { classes } = props;

  return (
    <div>
      <div style={{ height: "110px" }}></div>
      <div className={classes.root} id={"view-manage-bookings"}>
        <Card className="booking-card">
          <CardTitle title="Trade Information" />
        </Card>

        <Grid container spacing={24}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <ButtonBase
                className={classes.image}
                component={Link}
                to="/trade/campinfo"
              >
                <img
                  className={classes.img}
                  alt="complex"
                  src="/images/tiles/camp-information.jpg"
                />
              </ButtonBase>
              <br />
              <br />
              <Link to={"/trade/campinfo/"}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"fontOverride"}
                >
                  Camp Information
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <ButtonBase
                className={classes.image}
                component={Link}
                to="/trade/impact"
              >
                <img
                  className={classes.img}
                  alt="complex"
                  src="/images/tiles/impact-sustainability.jpg"
                />
              </ButtonBase>
              <br />
              <br />
              <Link to={"/trade/impact"}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"fontOverride"}
                >
                  Impact & Sustainability
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <ButtonBase
                className={classes.image}
                component={Link}
                to="/trade/latestUpdates"
              >
                <img
                  className={classes.img}
                  alt="complex"
                  src="/images/tiles/latest-updates.jpg"
                />
              </ButtonBase>
              <br />
              <br />
              <Link to={"/trade/latestUpdates"}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"fontOverride"}
                >
                  Latest Updates
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <ButtonBase
                className={classes.image}
                component={Link}
                to="/trade/londoloziExperience"
              >
                <img
                  className={classes.img}
                  alt="complex"
                  src="/images/tiles/experiences.jpg"
                />
              </ButtonBase>
              <br />
              <br />
              <Link to={"/trade/londoloziExperience"}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"fontOverride"}
                >
                  Londolozi Experience
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <ButtonBase
                className={classes.image}
                component={Link}
                to="/trade/mediaGallery"
              >
                <img
                  className={classes.img}
                  alt="complex"
                  src="/images/tiles/media-gallery.jpg"
                />
              </ButtonBase>
              <br />
              <br />
              <Link to={"/trade/mediaGallery"}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"fontOverride"}
                >
                  Gallery and Media
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <ButtonBase
                className={classes.image}
                component={Link}
                to="/trade/rates"
              >
                <img
                  className={classes.img}
                  alt="complex"
                  src="/images/tiles/rates-information.jpg"
                />
              </ButtonBase>
              <br />
              <br />
              <Link to={"/trade/rates"}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"fontOverride"}
                >
                  Rates, Information & Policies
                </Typography>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "30px" }}></div>
    </div>
  );
}

TradeDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TradeDashboard);
