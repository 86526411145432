import React from "react";
import lodgeForce from "./../wrappers/LodgeForceAPI";
import { Card } from "material-ui/Card";
import { Link } from "react-router-dom";
import { userLogout } from "./../actions/root";
import RaisedButton from "material-ui/RaisedButton";
import { withAuth0 } from "@auth0/auth0-react";

class AccountConfirmation extends React.Component {
  state = {
    message: "Loading...",
    sendEmailDisabled: false,
  };

  resendEmail = () => {
    return lodgeForce
      .post("/users/resend_confirmation_email", {
        hostname: window.location.hostname,
      })
      .then(() => {
        const message = "Another email has been sent.";
        this.setState({ message, sendEmailDisabled: true });
      })
      .catch(() => {
        const message =
          "An error occured when resending the email. Please contact a Londolozi administrator.";
        this.setState({ message, endEmailDisabled: true });
      });
  };

  componentDidMount() {
    const { confirmToken } = this.props;
    if (confirmToken !== undefined) {
      return lodgeForce
        .get(`/users/confirm/${confirmToken}`)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          const message =
            "An error has occured. Please contact a Londolozi administrator for further details.";
          this.setState({ message });
        });
    } else {
      const message =
        "Thank you for registering to the Londolozi Booking platform. We have received your registration and will work towards activating your account within the next 24hours. Should you not hear back from us owing to technical difficulties, please reach out to reservations@londolozi.co.za.";
      this.setState({ message });
    }
  }
  render() {
    return (
      <Card className="authentication-card">
        <div className="authentication-card__heading" />
        <p className="authentication-card__heading__text">
          {this.state.message}
        </p>
        <div className="button-line">
          <Link to="/login">
            <RaisedButton
              label="Go back"
              onClick={() => {
                this.props.auth0.logout({ returnTo: window.location.origin });
              }}
            />
          </Link>
        </div>
      </Card>
    );
  }
}

export default withAuth0(AccountConfirmation);
