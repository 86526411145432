import React from "react";
import RichTextEditor from "react-rte";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import ConfirmationModal from "./../../../shared-components/ConfirmationModal/index";
import { startSendEmail, resetState } from "./../../../actions/agentManagement";
import generateEmailTemplate from "./../../../functions/generateEmailTemplate";
class EmailInput extends React.Component {
  state = {
    value: RichTextEditor.createValueFromString(
      generateEmailTemplate({
        fullName: this.props.fullName,
        email: this.props.consultantEmail,
      }),
      "html"
    ),
    from: "Londolozi Reservations",
    to: this.props.consultantEmail,
    subject: "Londolozi Reservations - Account Activation",
    isModalOpen: false,
    isModalLoading: false,
    isEmailProcessed: false,
    isError: false,
  };

  onChange = (value) => {
    this.setState({ value });
  };

  updateTextField = (textField) => (e) => {
    e.preventDefault();

    const text = e.target.value;
    this.setState({ [textField]: text });
  };

  sendEmail = () => {
    this.setState({ isModalLoading: true, isModalOpen: true });

    return this.props
      .sendEmail({
        from: this.state.from,
        to: this.state.to,
        subject: this.state.subject,
        html: this.state.value.toString("html"),
      })
      .then(() => {
        this.setState({
          isModalLoading: false,
          isEmailProcessed: true,
        });
        setTimeout(() => {
          this.props.history.push("/");
          this.props.resetState();
        }, 3000);
      })
      .catch(() => {
        this.setState({
          isModalLoading: false,
          isError: true,
        });
        setTimeout(() => {
          this.props.history.push("/");
          this.props.resetState();
        }, 3000);
      });
  };

  render() {
    return (
      <div>
        <TextField
          className="email-field"
          floatingLabelText="From:"
          value={this.state.from}
          onChange={this.updateTextField("from")}
          autoFocus={true}
        />
        <TextField
          className="email-field"
          floatingLabelText="To:"
          value={this.state.to}
          onChange={this.updateTextField("to")}
        />
        <TextField
          className="email-field"
          floatingLabelText="Subject:"
          value={this.state.subject}
          onChange={this.updateTextField("subject")}
        />
        <RichTextEditor value={this.state.value} onChange={this.onChange} />
        <FlatButton label="Submit" fullWidth={true} onClick={this.sendEmail} />
        <ConfirmationModal
          isOpen={this.state.isModalOpen}
          isLoading={this.state.isModalLoading}
          loadingText="Email is being sent..."
          isProcessed={this.state.isEmailProcessed}
          processedText="Email has been confirmed!"
          isError={this.state.isError}
          errorText="It looks like there has been an error, please try again or contact Londolozi"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ agentManagement }) => {
  const { consultants } = agentManagement;
  const consultant = consultants.find((consultant) => consultant.selected);
  return {
    fullName: `${consultant.first_name} ${consultant.last_name}`,
    consultantEmail: consultant.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: ({ to, from, subject, html }) => {
      return dispatch(startSendEmail({ to, from, subject, html }));
    },
    resetState: () => dispatch(resetState()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailInput)
);
