import React from "react";
import londoloziLive from "../../../wrappers/LondoloziLiveApi";
import { Card, CardTitle } from "material-ui/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left";
import { withRouter } from "react-router-dom";

class WildlifeMediaGallery extends React.Component {
  state = {
    message: "Loading...",
    error: false,
  };
  componentDidMount() {
    return londoloziLive
      .get(`pages`, `200`)
      .then((res) => {
        this.setState({
          message: res.content.rendered,
        });
      })
      .catch(({ message }) => alert(message));
  }

  render() {
    return (
      <div>
        <div style={{ height: "110px" }}></div>
        <div id={"view-manage-bookings"}>
          <Card className="booking-card">
            <CardTitle title="Wildflife Media Gallery" />
            <IconButton aria-label="Delete" onClick={this.props.history.goBack}>
              <BackIcon /> Back
            </IconButton>

            <CardContent
              id={"remoteText"}
              dangerouslySetInnerHTML={{ __html: this.state.message }}
            />
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(WildlifeMediaGallery);
