import React from "react";
import { connect } from "react-redux";
import { Card, CardTitle, CardText, CardHeader } from "material-ui/Card";
import Toggle from "material-ui/Toggle";

const styles = {
  policy: {
    whiteSpace: "pre-wrap",
  },
};

class ModalPolicy extends React.Component {
  state = {
    isVisible: false,
  };

  toggleMoreDetails = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };
  render() {
    const { propertyPolicy } = this.props;

    return (
      <Card className="booking-modal-policy">
        <CardHeader
          title="Policies"
          subtitle="Please click to read all property policies before booking"
          actAsExpander={true}
          showExpandableButton={true}
          className="booking-modal-policy-header"
        />
        <CardText
          expandable={true}
          className="toggle-policy-content"
          style={styles.policy}
        >
          <ul>{propertyPolicy}</ul>
        </CardText>
      </Card>
      // <Card expanded={this.state.isVisible} className="booking-modal-policy">
      //     <CardTitle className="policyhead" title="Property Policy" />
      //     <Toggle
      //         className="toggle-policy"
      //         style={{ width: "10em" }}
      //         value="right"
      //         toggled={this.state.isVisible}
      //         onToggle={() => { this.toggleMoreDetails() }}
      //     />
      //     <CardText expandable={true} className="toggle-policy-content" style={styles.policy}>
      //         <ul>
      //             {propertyPolicy}
      //         </ul>
      //     </CardText>
      // </Card>
    );
  }
}

const mapStateToProps = ({ booking }) => {
  return {
    propertyPolicy: booking.propertyPolicy,
  };
};

export default connect(mapStateToProps)(ModalPolicy);
