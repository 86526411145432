import lodgeForce from "./../wrappers/LodgeForceAPI";

export const setProperties = (properties) => ({
  type: "SET_PROPERTIES",
  properties,
});

export const clearProperties = () => ({
  type: "CLEAR_PROPERTIES",
});

const startSearch = () => ({
  type: "START_SEARCH",
});

export const startSetProperties = (lodge) => {
  return (dispatch) => {
    dispatch(startSearch());
    return lodgeForce.get(`/agentaccess/${lodge}`).then((properties) => {
      dispatch(setProperties(properties));
    });
  };
};

export const setBlockData = ({ propertyName, accomName, blockObject }) => ({
  type: "SET_BLOCK_DATA",
  propertyName,
  accomName,
  blockObject,
});

export const startSetBlockData = ({
  propertyName,
  accomName,
  lodgeId,
  blockObject,
}) => {
  return (dispatch) => {
    dispatch(setBlockData({ propertyName, accomName, blockObject }));
    return lodgeForce.post("/lodges/updateBlockData", {
      lodgeId,
      propertyName,
      accomName,
      blockObject,
    });
  };
};
export const changeAvailability = ({
  numberOfRooms,
  arrival,
  departure,
  accomName,
  propertyName,
  isIncreasingAvailability = false,
}) => ({
  type: "CHANGE_AVAILABILITY",
  numberOfRooms,
  arrival,
  departure,
  accomName,
  propertyName,
  isIncreasingAvailability,
});
