import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Card, CardTitle } from "material-ui/Card";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  image: {},
  img: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "250px",
  },
});

function DashboardTiles(props) {
  const { classes } = props;

  return (
    <div className={classes.root} id={"view-manage-bookings"}>
      <Card className="booking-card">
        <CardTitle
          title="Welcome to the Londolozi Trade Platform"
          subtitle="Select an Item below to Proceed"
        />
      </Card>

      <Grid container spacing={24}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <ButtonBase
              className={classes.image}
              component={Link}
              to="/new-booking"
            >
              <img
                className={classes.img}
                alt="complex"
                src="/images/tiles/make-a-booking.jpg"
              />
            </ButtonBase>
            <br />
            <br />
            <Link to={"/new-booking"}>
              <Typography variant="h4" gutterBottom className={"fontOverride"}>
                Make a Booking
              </Typography>
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <ButtonBase
              className={classes.image}
              component={Link}
              to="/trade/dashboard"
            >
              <img
                className={classes.img}
                alt="complex"
                src="/images/tiles/info-media-gallery-downloads.jpg"
              />
            </ButtonBase>
            <br />
            <br />
            <Link to={"/trade/dashboard"}>
              <Typography variant="h4" gutterBottom className={"fontOverride"}>
                Info, Media Gallery & Downloads
              </Typography>
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <ButtonBase
              className={classes.image}
              component={Link}
              to="/booking-calendar"
            >
              <img
                className={classes.img}
                alt="complex"
                src="/images/tiles/booking-calendar.png"
              />
            </ButtonBase>
            <br />
            <br />
            <Link to={"/booking-calendar"}>
              <Typography variant="h4" gutterBottom className={"fontOverride"}>
                View booking calendar
              </Typography>
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

DashboardTiles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardTiles);
