const validator = require("validator");

var pwordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const validateSignupForm = (payload) => {
  const errors = {};
  let isFormValid = true;
  let message = "";

  if (
    !payload ||
    typeof payload.email !== "string" ||
    !validator.isEmail(payload.email)
  ) {
    isFormValid = false;
    errors.email = "Please provide a correct email address.";
  }

  if (
    !payload ||
    typeof payload.password !== "string" ||
    !pwordRegex.test(payload.password.trim())
  ) {
    isFormValid = false;
    errors.password =
      "Password must have at least 8 characters including a lower-case letter, an upper-case letter, a number and a special character";
  }

  if (
    !payload ||
    typeof payload.firstName !== "string" ||
    payload.firstName.trim().length === 0
  ) {
    isFormValid = false;
    errors.firstName = "Please provide your first name.";
  }

  if (
    !payload ||
    typeof payload.lastName !== "string" ||
    payload.lastName.trim().length === 0
  ) {
    isFormValid = false;
    errors.lastName = "Please provide your last name.";
  }

  if (
    !payload ||
    typeof payload.company !== "string" ||
    payload.company.trim().length === 0
  ) {
    isFormValid = false;
    errors.company = "Please provide your company.";
  }

  if (!isFormValid) {
    message = "Check the form for errors.";
  }

  return {
    success: isFormValid,
    message,
    errors,
  };
};

export default validateSignupForm;
