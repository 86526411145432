import React from "react";
import { connect } from "react-redux";
import { Card, CardTitle } from "material-ui/Card";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  getBookings,
  selectBooking,
} from "./../../../actions/bookingManagement";
import _ from "lodash";

const moment = require("moment");

class BookingTable extends React.Component {
  state = {
    bookings: [],
  };

  componentDidMount() {
    this.props.setBookings().catch((e) => console.log(e));
  }

  filtering = (e) => {
    console.log(e);
  };

  filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  }

  render() {
    return (
      <Card className="booking-card">
        <CardTitle
          title="Manage bookings"
          subtitle="Click on a booking to manage it."
        />
        <ReactTable
          data={this.props.bookings}
          columns={[
            {
              columns: [
                {
                  Header: "Lodge",
                  accessor: "lodgeId",
                },
                { Header: "Reservation number", accessor: "_id" },
                { Header: "Booking Name", accessor: "bookingName" },
                {
                  Header: "Status",
                  accessor: "status",
                },
                {
                  Header: "Created",
                  accessor: "bookingMade",
                  filterMethod: (filter, row, column) => {
                    return String(
                      moment.unix(row.bookingMade / 1000).format("DD/MM/YYYY")
                    ).startsWith(filter.value);
                  },
                  Cell: (c) => (
                    <span>
                      {c.original.bookingMade &&
                        moment
                          .unix(c.original.bookingMade / 1000)
                          .format("DD/MM/YYYY")}
                    </span>
                  ),
                },
              ],
            },
          ]}
          defaultPageSize={100}
          filterable
          defaultFilterMethod={(filter, row) =>
            this.filterCaseInsensitive(filter, row)
          }
          showPagination={true}
          showPageSizeOptions={false}
          minRows={0}
          className="-striped -highlight"
          getTrProps={(state, rowInfo) => {
            return {
              onClick: () => {
                this.props.selectBooking(rowInfo.original);
              },
              style: { cursor: "pointer" },
            };
          }}
          onSortedChange={(c, s) => {
            document.activeElement.blur();
          }}
        />
        <br />
      </Card>
    );
  }
}

const mapStateToProps = ({ bookingManagement }) => {
  let bookingsmodified = bookingManagement.bookings.map((booking) => {
    return { ...booking };
  });

  let bookingDetails =
    bookingManagement.bookings &&
    bookingManagement.bookings.find((booking) => booking.selected === false);

  return {
    bookings: _.sortBy(bookingsmodified, "bookingMade").reverse(),
    selected: bookingDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookings: () => dispatch(getBookings()),
    selectBooking: (id) => dispatch(selectBooking(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingTable);
