export const setSearchDates = ({ startDate, endDate }) => ({
  type: "SET_SEARCH_DATES",
  startDate,
  endDate,
});

export const resetSearchDates = () => ({
  type: "RESET_SEARCH_DATES",
});

export const setCalendarRangeDates = () => ({
  type: "SET_CALENDAR_RANGE_DATES",
});

export const setNumberOfRooms = (numberOfRooms) => ({
  type: "SET_NUMBER_OF_ROOMS",
  numberOfRooms,
});

export const setStateDates = ({ startDate, endDate }) => ({
  type: "SET_STATE_DATES",
  startDate,
  endDate,
});

export const switchView = (view) => ({
  type: "SWITCH_VIEW",
  view,
});
export const moveCalendar = (numberOfDays, direction) => ({
  type: "MOVE_CALENDAR",
  direction: direction.toLowerCase(),
});

export const setGuestNumbers = (guests) => ({
  type: "SET_GUEST_NUMBER",
  guests,
});

export const setWizardstate = (val) => ({
  type: "WIZARD_CHANGE",
  val,
});

export const setSearchState = (val) => ({
  type: "SEARCH_CHANGE",
  val,
});
