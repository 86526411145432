import React from "react";
import { connect } from "react-redux";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import Search from "material-ui/svg-icons/action/search";
import WithLoading from "./../../../hoc/WithLoading";

import { startConsultantSearch } from "./../../../actions/agentManagement";

class ConsultantSearchBar extends React.Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
  };
  updateTextField = (textField) => (e) => {
    e.preventDefault();

    const text = e.target.value;
    this.setState({ [textField]: text });
  };

  onEnter = ({ which }) => {
    const enterKeyCode = 13;
    if (which === enterKeyCode) {
      this.search(
        this.state.first_name,
        this.state.last_name,
        this.state.email
      );
    }
  };

  search = (first_name, last_name, email) => {
    this.props.search(first_name, last_name, email);
  };

  componentDidMount() {
    this.setState(() => ({
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      email: this.props.email,
    }));
  }
  render() {
    return (
      <div className="consultant-search-wrap">
        <TextField
          className="consultant-search-name"
          floatingLabelText="Consultant First Name"
          value={this.state.first_name}
          onChange={this.updateTextField("first_name")}
          autoFocus={true}
          onKeyPress={this.onEnter}
        />
        <TextField
          className="consultant-search-lastname"
          floatingLabelText="Consultant Last Name"
          value={this.state.last_name}
          onChange={this.updateTextField("last_name")}
          onKeyPress={this.onEnter}
        />
        <TextField
          className="consultant-search-email"
          floatingLabelText="Consultant Email"
          value={this.state.email}
          onChange={this.updateTextField("email")}
          onKeyPress={this.onEnter}
        />
        <IconButtonWithLoading
          isLoading={this.props.isLoading}
          iconStyle={{ transform: "scale(1.5)" }}
          onClick={() =>
            this.search(
              this.state.first_name,
              this.state.last_name,
              this.state.email
            )
          }
        >
          <Search />
        </IconButtonWithLoading>
      </div>
    );
  }
}
const IconButtonWithLoading = WithLoading(IconButton);

const mapStateToProps = ({ agentManagement }) => {
  return {
    first_name: agentManagement.first_name,
    last_name: agentManagement.last_name,
    email: agentManagement.email,
    isLoading: agentManagement.consultantsLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (first_name, last_name, email) =>
      dispatch(startConsultantSearch(first_name, last_name, email)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultantSearchBar);
