import React from "react";
import { connect } from "react-redux";
import groupByAccomType from "./../../../functions/groupByAccomType";
import AccomTable from "./AccomTable";
import TotalTable from "./TotalTable";
import { getTotal } from "./../../../functions/formatRate";
import formatRate from "./../../../functions/formatRate";

const styles = {
  totalTable: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    marginTop: "2rem",
  },
};

const BookingTable = ({
  lineItemGroups,
  totalRate,
  totalCommission,
  totalNett,
  commPercentage,
  totalLevi,
}) => (
  <div className="itinerary-tables-wrap">
    <div className="itinerary-tables">
      {lineItemGroups.map((lineItemGroup, i) => (
        <AccomTable
          key={i}
          className="itinerary-table"
          lineItemGroup={lineItemGroup}
        />
      ))}
    </div>
    <div className="itinerary-table-totals" style={styles.totalTable}>
      <div />
      <TotalTable
        totalRate={
          totalRate != undefined
            ? formatRate(
                Number(totalRate.replace(/,/g, "").replace(/ZAR/g, " ")) +
                  Number(totalLevi)
              )
            : 0
        }
        totalCommission={totalCommission}
        totalNett={
          totalNett != undefined
            ? formatRate(
                Number(totalNett.replace(/,/g, "").replace(/ZAR/g, " ")) +
                  Number(totalLevi)
              )
            : 0
        }
        commPercentage={isNaN(commPercentage) == true ? "0%" : commPercentage}
        totalLevi={formatRate(totalLevi)}
      />
    </div>
  </div>
);

const mapStateToProps = ({ booking }) => {
  const confirmedLineItems = booking.lineItems.filter(
    (lineItem) => !!lineItem.confirmed
  );
  return {
    lineItemGroups: groupByAccomType(confirmedLineItems),
    totalRate: getTotal(confirmedLineItems, "gross"),
    totalCommission: getTotal(confirmedLineItems, "comm"),
    totalNett: getTotal(confirmedLineItems, "nett"),
    commPercentage: `${
      (parseFloat(getTotal(confirmedLineItems, "comm", "noFormat")) /
        parseFloat(getTotal(confirmedLineItems, "gross", "noFormat"))) *
      100
    }%`,
    totalLevi: confirmedLineItems
      .map((lineItem) => lineItem.levy)
      .reduce((a, b) => a + b),
  };
};
export default connect(mapStateToProps)(BookingTable);
